import "./App.css";
import Web3 from 'web3';
import "./css/mining.css";
import moment from "moment";
import "./css/harvester.css";
import { ethers } from "ethers";
import { Howl, Howler } from "howler";
import ReactPlayer from 'react-player';
import { toWei } from "thirdweb/utils";
import { FaLock } from "react-icons/fa";
import { HexColorPicker } from 'react-colorful';
import {useLocation, Link} from "react-router-dom";
// import * as s from "./styles/globalStyles";
import { contractData, networkDataArray } from "./tools/networkData";
import { BsShieldLock } from "react-icons/bs"; 
import { PiInfinityLight } from "react-icons/pi"; 
import { PiSealWarningDuotone } from "react-icons/pi";
import { useMediaQuery } from 'react-responsive';
import TelegramWebApp from "@twa-dev/sdk";
import { bgmusic, soundeffects } from './tools/effects';
import { ethers5Adapter } from "thirdweb/adapters/ethers5";
import React, { useEffect, useState, useRef, createRef } from "react";
import { createWallet, walletConnect, inAppWallet } from "thirdweb/wallets";
import { formatNumber, removeThousands, truncateAddress } from './tools/utils';
import { createThirdwebClient, getContract, sendTransaction, readContract, prepareContractCall, prepareEvent} from "thirdweb";
import { GiZeusSword, Gi3dHammer, GiEyeTarget, GiConsoleController, GiWarPick, GiTrophyCup, GiLaurelsTrophy, GiStarMedal, GiWallet} from "react-icons/gi"; 
import { ThirdwebProvider, ConnectButton, darkTheme, useConnect, useActiveAccount, useReadContract, useActiveWallet,
  useActiveWalletChain, useSwitchActiveWalletChain, useSendTransaction, useWalletBalance, useContractEvents } from "thirdweb/react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchNetworkData } from './redux/actions/networkActions';
import { chains, MAX, _counter, selectBlockchain, selectNetworkIndex } from './redux/reducers/networkReducer'; 

// Media Screen Resolution *************************************************************************************************
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? children : null;
};

// Global Variables ***********************************************************************************************************************
let gasoptions, network, approved, wallet, account, web3, battledog, location, isPauseState, dataArray = [], timeLock, multiplier, isLocation, isCache,
isACID, isProcessing, isProcess, isLogin, isAT, isAT2, isOBAD, isCharge, isWrap, isSetInterval, isSameHouse, activatebonus, levelbonus, winsbonus, 
saadm, isAttackerId, isDefenderId, isRunning, isLocking, isStored, isSetting, isApproveNative, isApproveGame, fightsbonus, historybonus, 
isActivate, isAssault, isClaimAssault, isClaimDebilitate, isDebilitate, isScoreboard, isLevelUp, isMint, isPayout, replenisher, replenishTax, replenished, 
isRegenerate, isResetTimer, isCustomize, isWeaponize, isVolIcon, isAAS, isProcessed, msg, isCalling, isAOPFetch, youdata, rewardPerStamp, eraLength;


// Global Constants ***************************************************************************************************************

const client = createThirdwebClient({
  clientId: '56fbbaeaec406bdb5114a7d46f75cf6c',
});

const wallets = [
  walletConnect(),
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("com.binance"),
  inAppWallet({
    auth: {
      // mode: "redirect",
      options: [
        "email",
        "google",
        "x",
        "telegram",
        "farcaster",
        "facebook",
        "discord",
        "apple",
        "phone",
      ],
    },
  }),
];

const backgroundMusic = [
  bgmusic.Soundtrack1, bgmusic.Soundtrack2, bgmusic.Soundtrack3, bgmusic.Soundtrack4, bgmusic.Soundtrack5, 
  bgmusic.Soundtrack6, bgmusic.Soundtrack7, bgmusic.Soundtrack8, bgmusic.Soundtrack9, bgmusic.Soundtrack10, bgmusic.Soundtrack11, 
  bgmusic.Soundtrack12, bgmusic.Soundtrack13, bgmusic.Soundtrack14, bgmusic.Soundtrack15, bgmusic.Soundtrack16, bgmusic.Soundtrack17, 
  bgmusic.Soundtrack18, bgmusic.Soundtrack19, bgmusic.Soundtrack20, bgmusic.Soundtrack21, bgmusic.Soundtrack2, bgmusic.Soundtrack1
]

function chainSelector () {
    const cachedCounter = localStorage.getItem('networkCounter');
    if (cachedCounter !== null && !isNaN(parseInt(cachedCounter))) {
      return parseInt(cachedCounter);
    }    
    return _counter;
}
  
//App ***********************************************************************************************************************

function App() { 
  const dispatch = useDispatch();
  const blockchain = useSelector(selectBlockchain);
  const [isTelegram, setIsTelegram] = useState(false);  
  const [tgUser, setTGUser] = useState(null);
  const chainId = useActiveWalletChain();
  account = useActiveAccount();
  const [backgroundImage, setBackgroundImage] = useState(0);   
  const [counter, setCounter] = useState(chainSelector());  
  // Add a useEffect to update localStorage whenever counter changes
  useEffect(() => {
    localStorage.setItem('networkCounter', counter.toString());
  }, [counter]);

  // const {connect, isConnecting, error } = useConnect();
  // let blockchain = networkDataArray[counter];
  const [submitText, setSubmitText] = useState(null);
  const [allOwnerOfPlayers, setAllOwnerOfPlayers] = useState([]);
  const [startMenuBkg, setStartMenuBkg] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [approval, setApproval] = useState();
  const [inputValue, setInputValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showKlanReceipt, setShowKlanReceipt] = useState(false);
  const [showKlan, setShowKlan] = useState(false);
  const [attackerId, setAttackerId] = useState('');
  const [defenderId, setDefenderId] = useState('');
  const { mutate: sendTransaction, data: transactionResult, error: txError } = useSendTransaction();
  const [accountBal, setAccountBal] = useState();
  const [provider, setProvider] = useState();
  const [signer, setSigner] = useState();
  const [trigger, setTrigger] = useState(false);
  const [flagHouse, setFlagHouse] = useState(null);
  const [rewarderAddress, setRewarderAddress] = useState();
  const [ownedBattledogs, setOwnedBattledogs] = useState();
  const [activeBattledogs, setActiveBattledogs] = useState();
  const [listener, setListener] = useState(true);
  const [showSwap, setShowSwap] = useState(false);
  const [tutorial, setTutorial] = useState(true);
//  const [condition, setCondition] = useState(false);
  const [textTutor, setTextTutor] = useState('ON');
  const [textRef, setTextRef] = useState('COPY');
  const [referral, setReferral] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [focusInput, setFocusInput] = useState(false);
  const [focusInput2, setFocusInput2] = useState(false);
  const [activeHouses, setActiveHouses] = useState([]);
  const [houseScores, setHouseScores] = useState([]);
  const [allActiveHouses, setAllActiveHouses] = useState([]);
  const [color, setColor] = useState("#aabbcc");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [cachedAddress, setCachedAddress] = useState(null);
  const inputRef = useRef(null);
  const pathname = window.location.pathname.toLowerCase();

  wallet = useActiveWallet();
  location = useLocation();

  const [allPlayers, setAllPlayers] = useState();
  const [nativeBalance, setNativeBalance] = useState();
  const [gameBalance, setGameBalance] = useState();
  const [userAllowance, setUserAllowance] = useState(); 
  const [balOwned, setBalOwned] = useState();
  const [activatingAmount, setActivatingAmount] = useState();
  const [requiredAmount, setRequiredAmount] = useState();
  const [healthLimit, setHealthLimit] = useState();
  const [charge, setCharge] = useState();

// convert a thirdweb account to ethers signer
const getSigner = async () => {    
  try {   
    //signers & providers
  const ethersSigner = await ethers5Adapter.signer.toEthers({
    client,
    chain: chains[counter],
    account: account,
  });
  
  setSigner(ethersSigner);
  
  const ethersProvider = ethers5Adapter.provider.toEthers({
    client,
    chain: chains[counter],
  });

  // setProvider(ethersProvider);   

  // web3 connect
  web3 = new Web3(window.ethereum);
  battledog = new web3.eth.Contract(
    contractData.battledog.abi,
    blockchain.battledogaddress
  );

  // conditionals
  // if (wallet.id === "inApp") {
  //   setCondition(true);
  // } 
  // if (wallet) {
  //   setCondition(true);
  // } 

const rpcUrl = blockchain.rpc;
let provide = new ethers.providers.JsonRpcProvider(rpcUrl);
//  console.log("provider: ", provide);
setProvider(provide);


  } catch (err) {
    console.log(err);
  }
}    

//Constants       
let reducer = blockchain.reducer, decimals = blockchain.decimals, 
contractBattledog = new ethers.Contract(blockchain.battledogaddress, contractData.battledog.abi, signer),
contractN = new ethers.Contract(blockchain.nativeaddress, blockchain.NATIVE.abi, provider),
contractG = new ethers.Contract(blockchain.gameaddress, contractData.game.abi, provider);

const handleFocus = () => setFocusInput(true);
const handleFocus2 = () => setFocusInput2(true);

  //Counter States
  const plusNetwork = () => {
    if (counter + 1 < MAX) { 
    setCounter(counter + 1);  
    playWhoosh();
    }
  };

  const dropNetwork = () => {
    if (counter - 1 >= 0) {
    setCounter(counter - 1);
    playWhoosh();
    }
  };

  const queryGameState = async() => {
    try {
      const result = await readContract({
        contract: contract,
        method: "function paused() view returns (bool)",
        params: []
      });
  
      isPauseState = result;
    } catch (error) {
      console.error("Error querying states:", error);
      return;
    }

  }

  function isTelegramWebApp() {
    return typeof window.Telegram !== 'undefined' && window.Telegram.WebApp.init;
  }
  
//Connector & Functions **************************************************************************************************************
function NativeBalance() {
  const { data, isLoading, error } = useReadContract({
    contract: contractNative,
    method: "function balanceOf(address account) view returns (uint256)", 
    params: [account.address]
  });

  if (isLoading) return <><span className="layoutBox" >Loading...</span></>;
  if (error) return <><span className="layoutBox" >Error</span></>;

  if (!isAT) {
    setNativeBalance(Number(data?.toString()) / decimals);
    setTrigger(true);
    isAT = true;
    getActivePlayers();
    allOwnerOfPlayersTx();    
    setSoundtrack(backgroundMusic[counter]);
    gasOptions();       
    queryGameState();
  };

  const slicedup = (blockchain.tokenA).slice(1);
  return (
    <>
    <Desktop>
      {nativeBalance && (
      <div className="layoutBox" >{formatNumber((nativeBalance).toFixed(blockchain.tofixed))} <span className='twhite'> {blockchain.wrap ? (<><span style={{color: "darkgray", opacity: "0.7", fontFamily:"Verdana", fontSize:"Medium"}}>w</span>{slicedup}</>) : (<>{blockchain.tokenA}</>)}</span></div>
      )}
    </Desktop>
    <Mobile>
      {nativeBalance && (
      <span className="layoutBoxMobile" >{formatNumber((nativeBalance).toFixed(blockchain.tofixed))} <span className='twhite'> {blockchain.wrap ? (<><span style={{color: "darkgray", opacity: "0.7", fontFamily:"Verdana", fontSize:"Small"}}>w</span>{slicedup}</>) : (<>{blockchain.tokenA}</>)}</span></span>
      )}
    </Mobile>
    </>
  );
}

function GameBalance() {
  const { data, isLoading, error } = useReadContract({
    contract: contractGame,
    method: "function balanceOf(address account) view returns (uint256)", 
    params: [account.address]
  });

  if (isLoading) return <><span className="layoutBox" >Loading...</span></>;
  if (error) return <><span className="layoutBox" >Error</span></>;

  if (data !== "undefined") {
    setGameBalance(Number(ethers.utils.formatEther(data?.toString())));
  }

  return (
    <>
    <Desktop>
      {gameBalance && (
    <div className="layoutBox" >{formatNumber((gameBalance).toFixed(blockchain.tofixed))}<span className='twhite'> GAME</span></div>
      )}
    </Desktop>
    <Mobile>
    {gameBalance && (
    <div className="layoutBoxMobile" >{formatNumber((gameBalance).toFixed(blockchain.tofixed))}<span className='twhite'> GAME</span></div>      
    )}
    </Mobile>
    </>
  );
}

function MintParameter() {
  let tx, isMintparameter;
  const { data, isLoading, isError } = useWalletBalance({
    chain: chains[counter],
    address: account.address,
    client,
  });

  if (isError) {
    setErrorMessage("Internet Error. Refresh please.");
    setErrorMessageVisible(true);
    setLoading(false);
  }

  if (data && !isMintparameter) {
    isMintparameter = true;
  tx = ethers.utils.formatEther(data.value.toString());
  setAccountBal(Number(tx)); 
  }
};

function Connector () {
  return (
    <ConnectButton
      client={client}
      chain={chains[counter]}
      wallets={wallets}
      theme={darkTheme({
        colors: {
          primaryText: `${blockchain.theme}`,
          secondaryText: "#c5c4cf",
          connectedButtonBg: "#252525",
          connectedButtonBgHover: "#161616",
          separatorLine: "#262830",
          primaryButtonBg: `${blockchain.theme}`,
        },
      })}
        supportedTokens={{
          [blockchain.chainId]: [{
            address: blockchain.gameaddress,
            name: "Game",
            symbol: "GAME",
            icon: 'https://battledogs.fun/assets/images/welcome.png',
          },
          {
            address: blockchain.nativeaddress,
            name: blockchain.tokenA,
            symbol: blockchain.tokenA,
            icon: `https://battledogs.fun/assets/images/collectable/icons/networks/network${counter}.png`,
          }]
        }}
        connectButton={{ label: "Login" }}
        connectModal={{
          size: "wide",
          title: "Socials or Wallet",
          titleIcon:
            "https://battledogs.fun/assets/images/welcome.png",
          welcomeScreen: {
            title: "Battledogs Arena",
            subtitle:
              "A p2p crypto battle card NFT game",
            img: {
              src: `https://battledogs.fun/assets/images/collectable/${counter}-bg.png`,
              width: 250,
              height: 250,
            },
          },
        }}
      />     
  );
}

// Contracts **************************************************************************************************************

// get Battledogs contract
const contract = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.battledogaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.battledog.abi,
});

// get Game contract
const contractGame = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.gameaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.game.abi,
});

// get Proof of Play contract
const contractPop = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.popaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.pop.abi,
});

// get Harvester contract
const contractHarvester = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: rewarderAddress,
  // OPTIONAL: the contract's abi
  abi: contractData.harvester.abi,
});

// get Native contracts
const contractNative = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.nativeaddress,
  // OPTIONAL: the contract's abi
  abi: blockchain.NATIVE.abi,
});

// get LP contracts
const contractLP = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.lpaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.lp_abi,
});

// Write Calls *********************************************************************************************

const sendHash = async (transactionHash) => {
    network = (blockchain.ecosystem).toLowerCase();
    console.log("TransactionHash: ", transactionHash); 
    
    try {
      const response = await fetch(`https://pdflupserver.battledogs.fun/HASH`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({request: transactionHash, network }),
      });
      
      if (response.ok) {
        console.log('Up To Date!');
      } else {
        console.log("Error posting hash data!");
      }
  
    } catch (e) {
      console.error(e.message);      
      console.log("Error submitting hash data!");
    }    
}

  const approve = (_digit) => {    
    if (isWaiting) return;
    setIsWaiting(true);
    if (decimals == 10**18)  {
      approved = ethers.utils.parseEther(_digit);
    } else {
      approved = _digit * decimals;
    };
      const transaction = prepareContractCall({ 
        contract: contractNative, 
        method: "approve", 
        params: [blockchain.battledogaddress, approved] 
      });
      sendTransaction(transaction);
  };
  
  const approveGame = (_digit) => {    
    if (isWaiting) return;
    setIsWaiting(true);
    if (decimals == 10**18)  {
      approved = ethers.utils.parseEther(_digit);
    } else {
      approved = _digit * decimals;
    };
    const transaction = prepareContractCall({ 
      contract: contractGame, 
      method: "approve", 
      params: [blockchain.battledogaddress, approved] 
    });
    sendTransaction(transaction);
  };


const revoke = () => {  
  setIsWaiting(true);
  const transaction = prepareContractCall({ 
    contract: contractNative, 
    method: "approve", 
    params: [blockchain.battledogaddress, 0] 
  });
  sendTransaction(transaction);
}

const revokeGame = () => { 
  setIsWaiting(true);
  const transaction = prepareContractCall({ 
    contract: contractGame, 
    method: "approve", 
    params: [blockchain.battledogaddress, 0] 
  });
  sendTransaction(transaction);
}

const wrap = (_amount) => {
  if (isWaiting) return;
  setIsWaiting(true);
  const amount_ = _amount.toString();
  const amount = Number(amount_);
  const transaction = prepareContractCall({ 
    contract: contractNative, 
    method: "function deposit() payable", 
    params: [amount],
    value: toWei(amount_),
  });
  sendTransaction(transaction);
};

const unwrap = (_amount) => {
  if (isWaiting) return;
  setIsWaiting(true);
  const amount_ = ethers.utils.parseEther(_amount.toString());
  const amount = Number(amount_);

    const transaction = prepareContractCall({ 
      contract: contractNative, 
      method: "function withdraw(uint256 wad)", 
      params: [amount] 
    });
    sendTransaction(transaction);
};

const Wrapper = () => { 
  useEffect(() => {
    if (isWaiting && transactionResult) {
      wrapAfterCall();
      setIsWaiting(false);
    } else if (isWaiting && txError) {
      if (isWrap) return;
      isWrap = true;
      setErrorMessage("Request Cancelled.");
      setErrorMessageVisible(true);
      setIsWaiting(false);
      isWrap = false;
      }
  },[isWaiting]); 
  return (
    <>
      <Desktop>
                <div>
                  <div className="" title={`Convert ${blockchain.coin} to ${blockchain.tokenA}`}>
                    <img src={require("./assets/images/icons/switch.png")} className={`permissionicons loadbearer${isWaiting}`}  onClick={() => {setShowSwap(true); handleShowForm()}} />
                  </div>
                </div>
                  {showForm && showSwap && (
                    <form onSubmit={(e) => handleSubmitter(e, wrap)} className={`loadbearer${isWaiting}`}>
                      <div>
                      <div onClick={handleFocus}>
                        <input autoFocus={focusInput} name="input1"
                          className="inputform"
                          style={{ color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
                          type="text"
                          value={approval}
                          onChange={handleApprovalChange}
                          placeholder={`Convert ${blockchain.coin} rewards to ${blockchain.tokenA}`}
                          alt="Unwrap Amount"
                        />
                      </div>
                      </div>
                      <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {setFocusInput(false)}}>Convert</button>
                      <button className={"button0"} type="button" onClick={() => {handleHideForm(); setFocusInput(false)}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                    </form>
                  )}
      </Desktop>
      <Mobile>
        <div>
          <div className="spinner">
            <img src={require("./assets/images/icons/switch.png")} style={{height: "40px"}} title={`Convert ${blockchain.coin} rewards to ${blockchain.tokenA}`} 
            onClick={() => {setShowSwap(true); handleShowForm()}} className={`loadbearer${isWaiting}`} /> 
            {showForm && showSwap && (
              <form  onSubmit={(e) => handleSubmitter(e, wrap)} className={`loadbearer${isWaiting}`} >
                <div>
                <div onClick={handleFocus}>
                  <input autoFocus={focusInput} name="input1"
                    className="inputformMb"
                    style={{ color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
                    type="number"
                    value={approval}
                    onChange={handleApprovalChange}
                    placeholder={`Convert ${blockchain.coin} rewards to ${blockchain.tokenA}`}
                    alt="Unwrap Amount"
                    />
                  </div>
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {handleHideForm(); setFocusInput(false)}}>Convert</button>
                  <button className={"button0"} type="button" onClick={() => {handleHideForm(); setFocusInput(false)}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
              </form>
            )}
          </div>    
          </div>
      </Mobile>
    </>
  )
};

const Unwrapper = () => { 
  useEffect(() => {
    if (isWaiting && transactionResult) { 
        wrapAfterCall();
        setIsWaiting(false);
      } else if (isWaiting && txError) {
        if (isWrap) return;
        isWrap = true;
        setErrorMessage("Request Cancelled.");
        setErrorMessageVisible(true);
        setIsWaiting(false);
        isWrap = false;
      }
  },[isWaiting]);  
  return (
    <>
      <Desktop>
          <div className="menu4" style={{color: `${blockchain.theme}`}}>      
              <div >
                <div onClick={handleShowForm}>
                  <div className="" title={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`}>
                    <img src={require("./assets/images/icons/switch.png")} className={`permissionicons loadbearer${isWaiting}`} />
                  </div>
                </div>
                  {showForm && (
                    <form  onSubmit={(e) => handleSubmitter(e, unwrap)} className={`loadbearer${isWaiting}`}>
                      <div>
                      <div onClick={handleFocus}>
                        <input autoFocus={focusInput} name="input1"
                          className="inputform"
                          style={{ color: "white", border: "3px solid goldenrod" }}
                          type="text"                          
                          value={approval}
                          onChange={handleApprovalChange}
                          placeholder={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`}
                          alt="Unwrap Amount"
                        />
                        </div>
                      </div>
                      <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBLur={() => {setFocusInput(false)}}>Convert</button>
                      <button className={"button0"} type="button" onClick={() => {handleHideForm(); setFocusInput(false)}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                    </form>
                  )}
              </div>
          </div>
      </Desktop>
      <Mobile>
        <div className='mobileapprove' align="center">
          <div className="spinner">
            <img src={require("./assets/images/icons/switch.png")} style={{height: "40px"}} title={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`} 
            onClick={handleShowForm} className={`loadbearer${isWaiting}`} /> 
            {showForm && (
              <form  onSubmit={(e) => handleSubmitter(e, unwrap)} className={`loadbearer${isWaiting}`} >
                <div>
                <div onClick={handleFocus}>
                  <input autoFocus={focusInput} name="input1"
                    className="inputformMb"
                    style={{ color: "white", border: "3px solid goldenrod" }}
                    type="number"                          
                    value={approval}
                    onChange={handleApprovalChange}
                    placeholder={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`}
                    alt="Unwrap Amount"
                    />
                  </div>
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {setFocusInput(false)}}>Convert</button>
                  <button className={"button0"} type="button" onClick={() => {handleHideForm(); setFocusInput(false)}}  style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
              </form>
            )}
          </div>    
          </div>
      </Mobile>
    </>
  )
};

// Read Only Calls & Effects *******************************************************************************************************
const allDataCall = async () => {
  if (isCalling) return;
  isCalling = true;
  let tx;
  try {
    const call1 = await contractBattledog.totalSupply();    
    tx = Number(call1);
    setAllPlayers(tx);

    const call2 = await contractN.balanceOf(account.address);
    tx = Number(call2) / decimals;
    setNativeBalance(tx);  
    setBalOwned(tx.toFixed(blockchain.tofixed));

    const call3 = await contractG.balanceOf(account.address);
    tx = ethers.utils.formatEther(call3);
    setGameBalance(Number(tx));
    
    const call4 = await contractN.allowance(account.address, blockchain.battledogaddress);
    tx = Number(call4) / decimals;
    setUserAllowance(tx);
    
    if (!activatingAmount) {
      const call5 = await contractBattledog.activatingAmount();
      tx = Number(call5) / decimals;
      setActivatingAmount(tx);
           
      const call6 = await contractBattledog.requiredAmount();
      tx = Number(call6) / decimals;
      setRequiredAmount(tx);
      setHealthLimit(tx * 50);
        
      const call7 = await contractBattledog.charge();
      tx = ethers.utils.formatEther(call7);
      setCharge(Number(tx));     
    }

    await unclaimed();
    getActivePlayers();

    if (leadingPlayers.length > 0) {
     youdata = leadingPlayers.find(ownerid => ownerid.address === account.address);
     setYours(youdata);
    }

    isCalling = false;
  } catch (error) {
    console.error("Error fetching Data elements: ", error);
  //  throw error;
    return;
  }
};

const allOwnerOfPlayersTx = async () => {
  if (isAOPFetch) return;
  isAOPFetch = true;
  setAllOwnerOfPlayers([]);
  try {
    const result = await readContract({
      contract: contract,
      method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
      params: [account.address],
    });

    setAllOwnerOfPlayers(result); 
    isAOPFetch = false;
  } catch (error) {
    console.error("Error fetching Battledog NFTs:", error);
    setErrorMessage("Error fetching Battledog NFTs. Refresh.");
    setErrorMessageVisible(true);
    // throw error;
    return;
  }
};

// component states, handlers & Effects ***************************************************************************************
  const [startMenu, setStartMenu] = useState(true);
  const [arsenal, setArsenal] = useState();
  const [start, setStart] = useState();
  const [arena, setArena] = useState();
  const [winnings, setWinnings] = useState();
  const [tools, setTools] = useState();
  const [scoreboard, setScoreboard] = useState(); 
  const [houseboard, setHouseboard] = useState();   
  const [leaderboard, setLeaderboard] = useState();  
  const [activityTracker, setActivityTracker] = useState();
  const [activate, setActivate] = useState();  
  const [activateHelp, setActivateHelp] = useState();
  const [assault, setAssault] = useState();
  const [assaultHelp, setAssaultHelp] = useState();
  const [claimAssault, setClaimAssault] = useState();
  const [claimAssaultHelp, setClaimAssaultHelp] = useState();
  const [claimDebilitate, setClaimDebilitate] = useState();
  const [claimDebilitateHelp, setClaimDebilitateHelp] = useState();
  const [debilitate, setDebilitate] = useState();  
  const [debilitateHelp, setDebilitateHelp] = useState();  
  const [levelUp, setLevelUp] = useState();
  const [levelUpHelp, setLevelUpHelp] = useState();
  const [harvester, setHarvester] = useState();
  const [mining, setMining] = useState();
  const [mint, setMint] = useState();
  const [mintHelp, setMintHelp] = useState();
  const [payout, setPayout] = useState();
  const [payoutHelp, setPayoutHelp] = useState();
  const [regenerate, setRegenerate] = useState();
  const [regenerateHelp, setRegenerateHelp] = useState();
  const [resetTimer, setResetTimer] = useState();
  const [resetTimerHelp, setResetTimerHelp] = useState();
  const [customize, setCustomize] = useState();
  const [customizeHelp, setCustomizeHelp] = useState();
  const [weaponize, setWeaponize] = useState();
  const [weaponizeHelp, setWeaponizeHelp] = useState();
  
  const resetStates = () => {
    setStartMenu(); 
    setArsenal();
    setStart();
    setArena();
    setWinnings();
    setTools();
    setScoreboard();  
    setHouseboard(); 
    setLeaderboard();
    setActive(false);
    setFocusInput(false);
    setActivate();   
    setActivateHelp()
    setAssault();
    setAssaultHelp();;  
    setClaimAssault();
    setClaimAssaultHelp();
    setClaimDebilitate();
    setClaimDebilitateHelp();
    setDebilitate();  
    setDebilitateHelp();
    setLevelUp();
    setLevelUpHelp();
    setHarvester();
    setMint();
    setMintHelp();
    setMining();
    setPayout();
    setPayoutHelp();
    setRegenerate();
    setRegenerateHelp();
    setResetTimer();
    setResetTimerHelp();
    setCustomize();
    setCustomizeHelp();
    setWeaponize();
    setWeaponizeHelp();
    setActivityTracker();
    setLoading();
    setIsWaiting();
    setIsFarming();
    setIsClaiming();
    setSubmitText();
    setSubmitText2();
    setUnlocker(false);
    setUnlocker2(false);
    setSelectedPlayer(null);
    setHarvesterLogs();
    setMinerLogs();
    hideView();
    dataArray = [];
  }

  const resetVar = () => {
    wallet = false; account = false; web3 = false; battledog = false; isCalling = false; timeLock = null; multiplier = null; activatebonus = null;
    isACID = false; isProcessing = false; isProcess = false; isLogin = false; isAT = false; isAT2 = false; isOBAD = false; isCharge = false;
    isWrap = false; isSetInterval = false; saadm = false; isAttackerId = false; isDefenderId = false; isRunning = false;  isAOPFetch = false;
    isLocking = false; isStored = false; isSetting = false; isApproveNative = false; isApproveGame = false; isActivate = false; isAssault = false; 
    isClaimAssault = false; isClaimDebilitate = false; isDebilitate = false; isScoreboard = false; isLevelUp = false; isMint = false; isPayout = false; 
    isRegenerate = false; isResetTimer = false; isCustomize = false; isWeaponize = false; isVolIcon = false; isAAS = false; isProcessed = false; 
    location = null; msg = null; youdata = null; isSameHouse = false; isPauseState = false; isAOPFetch = false; levelbonus = null; winsbonus = null;
    fightsbonus = null; historybonus = null; replenisher = null; replenishTax = null; replenished = null; rewardPerStamp = null; eraLength = null;
  }

  const resetData = () => {
    setErrorMessage();
    setYours();
    setCharge();
    setClaims("");
    setReferral();
    setBalOwned();
    setAllLogs([]);
    setAllPlayers();
    setMintLogs([]);
    setHealthLimit();
    setGameBalance();
    setLevelLogs([]);
    setTrigger(false);
    setPayoutsLog([]); 
    setAssaultWins([]);
    setAssaultLogs([]); 
    setNativeBalance();
    setUserAllowance();
    setRequiredAmount();
    setOwnedBattledogs();
    setRewarderAddress();
    setActivePlayers([]);
    setLeadingPlayers([]);
    setDebilitateLogs([]); 
    setDebilitateWins([]);
    setActivatingAmount();
    setActiveBattledogs();
    setAllOwnerOfPlayers([]);
    setActivePlayersList([]);
    setActiveHouses([]);
    setAllActiveHouses([]);
    setTotalStaked("");
    setCurrentStake("");
    setRewardClaim("");
    setTotalClaim("");
    setRewardOwed("");
    setLPBalance("");
    setDesktopBenchmark(0);
    setMobileBenchmark(0);
    setFlagHouse(null);
    resetVar();
  }

  const handleStartMenu = () => {
    resetStates();
    setStartMenu(true);
    setBackgroundImage(1);
    playPop();
  }

  const handleStart = () => {
    resetStates();
    playPop();
    setStart(true);
  }

  const handleArsenal = async() => { 
    resetStates();  
    setBackgroundImage(2);
    await allOwnerOfPlayersTx();
    refreshAllowanceData(); 
    playPop();
    setArsenal(true);
  }
  
  const handleArena = () => {
    setErrorMessage('You are at the Arena');
    setErrorMessageVisible(true);
    resetStates();
    playPop();
    if (tutorial) {
      setArena(true);
    } else {
      handleAssault();
    }
  }
  
  const handleWinnings = () => {
    setErrorMessage('Manage Your Wins here');
    setErrorMessageVisible(true);
    resetStates();
      playPop();
    if (tutorial) {
      setWinnings(true);
    } else {
      handlePayout();
    }
  }
  
  const handleTools = () => {
    setErrorMessage('Customize, Farm, Mine or Reset here');
    setErrorMessageVisible(true);
    resetStates();
    playPop();
    if (tutorial) {
      setTools(true);
    } else {
      handleHarvester();
    }
  }
  
  const handleScoreboard = async() => {
    resetStates();
    await allOwnerOfPlayersTx();
    await quickActivePlayersCheck();
    setBackgroundImage(19);
    setScoreboard(true);
    playPop();
  }
  
  const handleHouseboard = async() => {
    resetStates();
    setApproval();
    setShowImage(false);
    setBackgroundImage(21);
    setHouseboard(true);
    playPop();
  }

  const handleLeaderboard = async() => {
    await fetchScores();
    resetStates();
    setStartMenuBkg(0)
    setBackgroundImage(20);
    setLeaderboard(true);
    playPop();
  }
  
  const handleReferral = async () => {
    const network = blockchain.ecosystem.toLowerCase(); 
    try {
      const rootDomain = window.location.origin;
      const referralLink = `${rootDomain}/${network}?invited.by=${account.address}`;

      // Use the Clipboard API to copy the link to the clipboard
      await navigator.clipboard.writeText(referralLink);
      
      setTextRef('COPIED');
      const timer = setTimeout(() => {
        setTextRef('COPY');
      }, 7000);
      return () => clearTimeout(timer);
      
    } catch (err) {
      console.error('Failed to copy referral link: ', err);
    }
  }

  const handleTutorial = () => {
    if (textTutor === 'ON') {
      setTextTutor('OFF')
    } else {
      setTextTutor('ON')
    }
    setTutorial(!tutorial);
  }
  
  const handleMint = () => {
    resetStates();
    playPop();
    setMint(true);
    setBackgroundImage(3);
  }
    
  const handleLevelUpHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setLevelUpHelp(true);
    } else {
      setLevelUp(true);
      setBackgroundImage(12);
    }
  }
    
  const handleLevelUp = () => {
    resetStates(false);
    playPop();
    setLevelUp(true);
    setBackgroundImage(12);
  }
    
  const handleAssaultHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setAssaultHelp(true);
    } else {
      setAssault(true);
      setBackgroundImage(5);
    }
  }
    
  const handleAssault = () => {
    resetStates();
    playPop();
    setAssault(true);
    setBackgroundImage(5);
    playTrailinggrowl();
  }

  const handleDebilitateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setDebilitateHelp(true);
    } else {
    setDebilitate(true);
    setBackgroundImage(6);
    }
  }
    
  const handleDebilitate = () => {
    resetStates();
    playPop();
    setDebilitate(true);
    setBackgroundImage(6);
    playLurkinggrowl();
  }
    
  const handleActivateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setActivateHelp(true);
    } else {
    setActivate(true);
    setBackgroundImage(4);
    }
  }
    
  const handleActivate = () => {
    resetStates();
    playPop();
    setActivate(true);
    setBackgroundImage(4);
  }
    
  const handlePayoutHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setPayoutHelp(true);
    } else {
    setPayout(true);
    setBackgroundImage(7);
    }
  }
    
  const handlePayout = () => {
    resetStates();
    playPop();
    setPayout(true);
    setBackgroundImage(7);
  }
  
  const handleClaimAssaultHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setClaimAssaultHelp(true);
    } else {
      setClaimAssault(true);
      setBackgroundImage(10);
    }
  }
  
  const handleClaimAssault = () => {
    resetStates();
    playPop();
    setClaimAssault(true);
    setBackgroundImage(10);
  }

  const handleClaimDebilitateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setClaimDebilitateHelp(true);
    } else {
      setClaimDebilitate(true);
      setBackgroundImage(11);
    }
  }

  const handleClaimDebilitate = () => {
    resetStates();
    playPop();
    setClaimDebilitate(true);
    setBackgroundImage(11);
  }

  const handleHarvester = () => {
    resetStates();
    playPop();
    setHarvester(true);
    setBackgroundImage(1);
    setRewarderAddress(blockchain.harvesteraddress);
  }

  const handleMining = async () => {  
    allOwnerOfPlayersTx();
    resetStates();
    playPop();
    setMining(true);
    setBackgroundImage(15);
  }

  const handleRegenerateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setRegenerateHelp(true);
    } else {
      setRegenerate(true);
      setBackgroundImage(9);
    }
  }

  const handleRegenerate = () => {
    resetStates();
    playPop();
    setRegenerate(true);
    setBackgroundImage(9);
  }

  const handleResetTimerHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setResetTimerHelp(true);
    } else {
    setResetTimer(true);
    setBackgroundImage(16);
    }
  }

  const handleResetTimer = () => {
    resetStates();
    playPop();
    setResetTimer(true);
    setBackgroundImage(16);
  }

  const handleCustomizeHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setCustomizeHelp(true);
    } else {
    setCustomize(true);
    setBackgroundImage(18);
    }
  }

  const handleCustomize = () => {
    resetStates();
    playPop();
    setCustomize(true);
    setBackgroundImage(18);
  }

  const handleWeaponizeHelp = () => { 
    resetStates();
    playPop();
    if (tutorial) {
      setWeaponizeHelp(true);
    } else {
    setWeaponize(true);
    setBackgroundImage(8); 
    }
  }

  const handleWeaponize = () => { 
    resetStates();
    playPop();
    setWeaponize(true);
    setBackgroundImage(8);
  }

  const handleActivityTracker = () => {
    resetStates();
    playPop();
    setActivityTracker(true);
    setBackgroundImage(17);
  }
  
  const handleListenerClick = (e, log, func) => {
    if (scoreboard) {
        handleScoreSubmitter(e, getStats, log);
    } else {
        setDefenderId(log);
        func();
    }
  }

// Arsenal Component, handles & states **********************************************************************************************
const [selectedPlayer, setSelectedPlayer] = useState(null);
const [selectedPlayerStats, setSelectedPlayerStats] = useState();
const [reservePlayerData, setReservePlayerData] = useState();
const [playerData, setPlayerData] = useState([]);
const [currentIndex, setCurrentIndex] = useState(0);

function handleShowForm() {
  setShowForm(true);
}

function handleHideForm() {
  setShowForm(false);
}

function handleSubmit(e) {
  e.preventDefault();    
  approve();
  setApproval("");
  setShowForm(false);
}

const handleSelectedPlayerClick = (tokenId) => {
  if (selectedPlayer != tokenId) {
  setSelectedPlayer(tokenId);
  playBrick();
  }
};

const getPlayerData = async (tokenId) => {
  if (!tokenId) return;
  const response = await fetch(`https://${blockchain.website}/battledogs/${tokenId}.json`, {cache: "no-cache",});
  const dataplayer = await response.json();

  // Check if dataplayer has the attributes property
  if (!dataplayer.hasOwnProperty("attributes")) {
    console.error("The dataplayer object does not have the 'attributes' property.");
    return;
  }

  // Filter the attributes array to get the trait_type and value properties
  const filteredAttributes = dataplayer.attributes.filter((attribute) => {
    return attribute.hasOwnProperty("trait_type") && attribute.hasOwnProperty("value");
  });

  // Create a new array to store the filtered attributes
  const playerAttributes = filteredAttributes.map((attribute) => {
    return {
      trait_type: attribute.trait_type,
      value: attribute.value
    };
  });

  // Slice the playerAttributes array to get the first 9 elements
  const slicedPlayerAttributes = playerAttributes.slice(0, 9);
  // Find the selected player in allOwnerOfPlayers based on player.id
  const selectedPlayerData = allOwnerOfPlayers.find((player) => player.id === selectedPlayer);

  // Check if the selected player was found
  if (!selectedPlayerData) {
    console.error("Selected player not found in allOwnerOfPlayers");
    return;
  }

  const payoutNumber = Number(selectedPlayerData.payout); // Convert payout to Number

  // Create an object for selected player's attributes
  const selectedPlayerAttributes = [
    { trait_type: "Current Level", value: selectedPlayerData.level.toString() },
    { trait_type: "Your Aggression", value: selectedPlayerData.attack + " POINTS"},
    { trait_type: "Current Stamina", value: selectedPlayerData.defence + " POINTS"},
    { trait_type: "Payable Fights", value: selectedPlayerData.fights.toString() },
    { trait_type: "Registered Wins", value: selectedPlayerData.wins.toString() },
    { trait_type: "Pending Payout", value: (payoutNumber / reducer) + ` ${blockchain.tokenA}` },
    { trait_type: "Activation", value: "TIER " + selectedPlayerData.activate + " MINING"},
    { trait_type: "Your History", value: selectedPlayerData.history + " FIGHTS"}
  ];


    // Create a new array from selectedPlayerData
      const reservePlayerDataArray = [
      Number(selectedPlayerData.level),
      Number(selectedPlayerData.attack),
      Number(selectedPlayerData.defence),
      Number(selectedPlayerData.fights),
      Number(selectedPlayerData.wins),
      payoutNumber,
      Number(selectedPlayerData.activate),
      Number(selectedPlayerData.history)];   
    setReservePlayerData(reservePlayerDataArray);

  // Combine slicedPlayerAttributes and selectedPlayerAttributes
  const combinedAttributes = [...slicedPlayerAttributes, ...selectedPlayerAttributes];
  // Set the combined player data in the state
  setPlayerData(combinedAttributes);
};

const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = {};
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params;
};

useEffect(() => {
  const queryParams = getQueryParams();
  const connectTelegram = async () => {
    if (queryParams.tgAuth === "1") {
      // Forward the query string to the backend
      const response = await fetch("https://pdflupserver.battledogs.fun/TGBOT/auth", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(queryParams),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setTGUser(data.user); // Store user details on successful authentication
            console.log("Authentication successful:", data.user);
          } else {
            console.error("Authentication failed:", data.message);
          }
        })
        .catch((err) => console.error("Error connecting to backend:", err));
    }
  }

  connectTelegram()
}, []);

useEffect(() => {
  const telegramDetected = isTelegramWebApp();
  if (telegramDetected) {
    window.Telegram.WebApp.init();
    setIsTelegram(true); 
  }
}, []);

useEffect(() => {
  dispatch(fetchNetworkData(counter));
}, [dispatch, counter]);

useEffect(() => { 
  if (account && chainId) {
    if (isACID) return;
     isACID = true;
    for (let i = 0; i < chains.length; i++) {
      if (chains[i].id === chainId.id) {
        setCounter(i);
        setSubmitText(networkDataArray[i].ecosystem);
        break;   
      }
    }
    isACID = false;
  } else if (pathname) {   
    if (isACID) return;
     isACID = true; 
    const ecosystemArray = networkDataArray.map(network => network.ecosystem.toLowerCase());
    for (let i = 0; i < ecosystemArray.length; i++) {
      if (pathname.includes(ecosystemArray[i])) {
        setCounter(i);
        console.log(ecosystemArray[i]);
        setSubmitText(ecosystemArray[i].toString());
        break;  // Stop after first match
      }
    }
    isACID = false;
  }
}, [account]);

useEffect(() => {
  if (isCache) return;
  if (account && trigger && (account.address !== cachedAddress)) {
    isCache = true; 
    resetData();  
    handleStartMenu(); 
    resetVar();
    setCachedAddress(account.address);
    setErrorMessage('Account refreshed');
    setErrorMessageVisible(true);
    isCache = false;
  }
},[account]);

useEffect(() => {
  if (!account) {
      if(isLogin) return;
      isLogin = true;
    handleStartMenu();
    resetData();
    setSigner();
    setProvider();
    playLogoff();
    setSoundtrack(bgmusic.Windhowl);
    setBackgroundImage(0);
      isLogin = false
  };
}, [account]);

useEffect(() => {
  if (account && wallet) {
      if(isLogin) return;
      isLogin = true;
    setBackgroundImage(1); 
    setVolumeLevel(0.3);
    playAudio();
    playLogin();  
      isLogin = false;
  };
}, [account]);

useEffect(() => {  
  if (account && provider && trigger) {
    if (isAT2) return;
      isAT2 = true;
      setCachedAddress(account.address);
      allListeners();
  }
},[signer]);

useEffect(() => {
  if (account && blockchain.referral && location) {
    if (isLocation) return;
    isLocation = true;
    // Extract the query parameters from the URL
    const queryParams = new URLSearchParams(location.search); 
    
    if (queryParams.has('invited.by')) {
      const referralData = queryParams.get('invited.by'); 
      if (referralData.toLowerCase() !== account.address.toLowerCase()) {
        setReferral(referralData);    
      }  
    }
    
    isLocation = false;
  } 
}, [account]);

useEffect(() => {
  if (provider && allOwnerOfPlayers) {
    if (isOBAD) return;
    isOBAD = true;
    const getActiveDogs = async () => {
    const activebattledogs = await quickActiveBattledogsCall();
    setActiveBattledogs(activebattledogs);    
    await allDataCall();
    }
    getActiveDogs();
    isOBAD = false
  }
},[allOwnerOfPlayers, account]);

useEffect(() => { 
  if (account) {
    isCharge = true
  refreshAllowanceData();
  getActiveHouses();
  fetchScores();  
    isCharge = false;
  }
},[userAllowance, charge]);

useEffect(() => { 
  if (!account) return;
    if (allOwnerOfPlayers && selectedPlayer) {
      if (isSetInterval) return;
        isSetInterval = true;
      getPlayerData(selectedPlayer);
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % playerData.length);
      }, 10000);
      isSetInterval = false;
  
      return () => clearInterval(interval);
  }
  }, [selectedPlayer, playerData.length]);

// Mint Component and States ****************************************************************************************

const [name, setName] = useState("");
  
let newMint = `url(https://battledogs.fun/engine/${allPlayers}.png)`;
 
async function Mint(_account) { 
  // const estimatedGasPrice = await web3.eth.getGasPrice();
  // console.log('Estimated gas price (wei):', estimatedGasPrice);
  
  // const gasPrice = web3.utils.fromWei(estimatedGasPrice, 'gwei');
  // console.log('Gas price (gwei):', gasPrice);
  let mintfee;

  const call1 = await readContract({ 
    contract: contract, 
    method: "function mintFee() view returns (uint256)", 
    params: [] 
  });

  mintfee = Number(ethers.utils.formatEther(call1?.toString()));

  // check if user can mint a Battledog
  if (accountBal < mintfee) {
    setErrorMessage("Insufficient Funds to Mint.");
    setErrorMessageVisible(true);
    return;
  }

    const transaction = prepareContractCall({
      contract: contract, 
      method: "function mint(string _name) payable", 
      params: [name],
      value: call1,
    });

    setLoading(true);   
    sendTransaction(transaction);

};

const handleMintClick = (e) => {
  e.preventDefault();
  if (loading) return;
  playButton();

  // Check that name is not an empty string
  if (name === "") {
    setErrorMessage("Name cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // All input is valid, mint a new Battledog
  Mint(account.address);
};


const imageDuration = 6000;
const divDuration = imageDuration / 2;

useEffect(() => {
  if (arsenal || assault || debilitate || mint) {
    if (saadm) return;
    saadm = true;
  const interval = setInterval(() => {
    setShowImage((prevShowImage) => !prevShowImage);
  }, showImage ? imageDuration : divDuration);

  saadm = false;
  // Clean up the interval when the component is unmounted
  return () => clearInterval(interval);
  }
}, [showImage, arsenal, assault, debilitate, mint]);


useEffect(() => {
  if (mint) { 
    if (isProcess) return;
    isProcess = true
    let intervalId;
    const updateNewMint = async () => {
        try {
          const call1 = await contractBattledog.totalSupply();
          const tx = Number(call1);
          setAllPlayers(tx);
        } catch (err) {
          console.log(err);
        }
      
    };
      // Set up the interval to run every 6 seconds
      intervalId = setInterval(updateNewMint, 6000);

    isProcess = false; 
    // Clean up interval on component unmount or when `mint` changes to false
    return () => clearInterval(intervalId);
  }
}, [mint]);

// Activate Component & States ******************************************************************************************
const [showTargetA, setTargetA] = useState('');
const [showTargetB, setTargetB] = useState('');
const [activePlayers, setActivePlayers] = useState([]);
const [activePlayersList, setActivePlayersList] = useState([]);
const [leadingPlayers, setLeadingPlayers] = useState([]);
const [yours, setYours] = useState();

const gasOptions = async () => {
  getSigner();
  if (!blockchain.gasoptions) return;  // TBD
    const estimatedGasPrice = await web3.eth.getGasPrice();
    console.log('Estimated gas price (wei):', estimatedGasPrice);
    
    const gasPrice = web3.utils.fromWei(estimatedGasPrice, 'gwei');
    console.log('Gas price (gwei):', gasPrice);
    
    gasoptions = {
      from: account.address, 
      gasPrice: estimatedGasPrice 
    }   
};

let TargetA = `https://battledogs.fun/engine/${showTargetA}.png`;
let TargetB = `https://battledogs.fun/engine/${showTargetB}.png`;
let targetA = `url(${TargetA})`;
let targetB = `url(${TargetB})`;

// Regular expression to validate that input is a positive integer
const integerRegex = /^[1-9]\d*$/;

async function Activatenft(_account, _tokenId) {
  setLoading(true);
    let tx = Number(_tokenId);
    try {
    const transaction = prepareContractCall({ 
      contract: contract, 
      method: "function activateNFT(uint256 _tokenId) payable", 
      params: [tx],
    });
  
    sendTransaction(transaction);
    } catch (err) {     
      console.log(err);
    }
};

const quickActivePlayersCheck = async() => {
  let newPlayerArray = [...activeBattledogs];
  for (let i = 0; i < newPlayerArray.length; i++) {
    const tokenId = newPlayerArray[i]; 
      if (!activePlayers.includes(tokenId)) {
          await pushActivePlayer(tokenId);
      }
    }
}

async function quickActiveBattledogsCall() {  
  let ownedbattledogs; let activebattledogs;    
  ownedbattledogs = allOwnerOfPlayers.filter(player => Number(player.activate.toString()) > 0);
  setOwnedBattledogs(ownedbattledogs);
  activebattledogs = ownedbattledogs.map(x => +x.id.toString());
  return activebattledogs;
}

const pushActivePlayer = async (_tokenId) => {
  // e.preventDefault();  
  network = (blockchain.ecosystem).toLowerCase();
  const request = Number(_tokenId);  
  const requestPayload = {
    request,
    network,
  };

  try {
    const response = await fetch(`https://pdflupserver.battledogs.fun/ID`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestPayload),
    });

    if (response.ok) {
      console.log('Completed!');
    } else {
      console.log("Push Error")
    }

    await getActivePlayers();

  } catch (e) {
    console.error(e.message)
  }
};

const getActivePlayers = async () => {
  network = (blockchain.ecosystem).toLowerCase();
  try {
    const response = await fetch(`https://pdflupserver.battledogs.fun/ID/${network}`, {
      method: "GET",
    });

    if (!response.ok) throw new Error("Network response was not ok");

    const allplayers = await response.json();
    const storedActivePlayers = allplayers.map(player => player.ID);
    
    setActivePlayers(storedActivePlayers);
    setActivePlayersList(storedActivePlayers);
    
  } catch (error) {
    console.error('Error fetching active players:', error);
  }
};

const fetchScores = async () => {
  network = (blockchain.ecosystem).toLowerCase();
  try {
    const response = await fetch(`https://pdflupserver.battledogs.fun/LEADERBOARD/${network}`, {
      method: "GET",
    });
    if (!response.ok) {
      // throw new Error('Failed to fetch leading data');
      setErrorMessage('Failed to fetch leading data. Refresh');        
      setErrorMessageVisible(true);      
    }

    const leaders = await response.json();
    if (!leaders) return;

    const storedLeaders = leaders
      .map((leader) => ({
        avatar: leader.AVATAR,
        address: leader.ADDRESS,
        score: leader.SCORE,
        ownership: leader.OWNERSHIP,
      }))
      .sort((a, b) => b.score - a.score);   
    
    setLeadingPlayers(storedLeaders);

  } catch (error) {
    console.error('Error fetching scores:', error);
  }
};

const getActiveHouses = async () => {
  network = (blockchain.ecosystem).toLowerCase();

  try {
    const response1 = await fetch(`https://pdflupserver.battledogs.fun/ALLACTIVEHOUSES/${network}`, {
      method: "GET",
    });
    if (!response1.ok) {
      // throw new Error('Failed to fetch all active houses');
      setErrorMessage('Failed to fetch all activeHouses. Refresh');        
      setErrorMessageVisible(true);      
    } 

    const allactivehouses = await response1.json();
    setAllActiveHouses(allactivehouses);    

    const response2 = await fetch(`https://pdflupserver.battledogs.fun/HOUSE/${network}`, {
      method: "GET",
    });
    if (!response2.ok) {
      // throw new Error('Failed to fetch active houses');
      setErrorMessage('Failed to fetch active houses. Refresh');        
      setErrorMessageVisible(true);      
    }

    const flags = await response2.json();
    const currenthouses = [...flags];
    const existingAddress = currenthouses.find(house => house.OWNERADDRESS === account.address); 
     if (existingAddress && flagHouse === null) {
       setFlagHouse(existingAddress.SIGIL);
     } 
    setActiveHouses(flags);       

    
    const response3 = await fetch(`https://pdflupserver.battledogs.fun/HOUSEBOARD/${network}`, {
      method: "GET",
    });

    if (!response3.ok) {      
      setErrorMessage('Failed to fetch houseboard data. Refresh');        
      setErrorMessageVisible(true);  
    }

    const housescores = await response3.json();
    setHouseScores(housescores);

    return [allactivehouses, flags, housescores];

  } catch (error) {
    console.error('Error fetching house data:', error);
  }
};

const createKlan = async() => {
  hideView();
  network = (blockchain.ecosystem).toLowerCase();
  const result = await getActiveHouses();
  const houseId = result[0].length;

  const request = {Id: houseId, Name: name.toString(), Color: color, Icon: inputValue};
  const requestPayload = {
    request,
    network
  }
  
    try {
      const response = await fetch(`https://pdflupserver.battledogs.fun/ALLACTIVEHOUSES`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      });

      const parsedResponse = await response.json();
  
      if (response.ok) {
        console.log("List Up To Date for:", requestPayload, parsedResponse);
      } else {
        console.error("Error Occurred for:", request.Name, parsedResponse);
      }
    } catch (e) {
      console.error("Error for:", request.Name, e.message);
    };
    
    await pickaKlan(houseId);
    handleKlanReceipt();  
}


const deleteKlan = async(_klanName) => {
  network = (blockchain.ecosystem).toLowerCase();
   console.log(network);

    const requestPayload = {
      request: _klanName,
      network,
    };
  
    try {
      const response = await fetch(`https://pdflupserver.battledogs.fun/ALLACTIVEHOUSES`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      });
      const parsedResponse = await response.json();
  
      if (response.ok) {
        console.log("Deleted ", _klanName);
      } else {
        console.error("Error Occurred for:", _klanName, parsedResponse);
      }
    } catch (e) {
      console.error("Error for:", _klanName, e.message);
    };

}

const pickaKlan = async (_value) => {
  if (activeBattledogs.length === 0 ) return;
  let activebattledogs = [...activeBattledogs];
  activebattledogs.push(Number(attackerId));
  const network = (blockchain.ecosystem).toLowerCase();
  const flagId = Number(_value.toString());
  let request = [];
  
  // Create the request payload as JSON
  const requestPayload = {
    request,
    network,
  };

  if (blockchain.klanmode) {     
    for (let i = 0; i < activebattledogs.length; i++) {
      const tokenId = activebattledogs[i];
      request.push({
        tokenId: tokenId,
        ownerAddress: account.address,
        sigil: flagId
      });
    }

    try {
      const response = await fetch(`https://pdflupserver.battledogs.fun/HOUSE`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload)
      });

      // Parse the JSON response only if the response is ok
      if (response.ok) {
        const rawResponse = await response.json();
      } else {
        console.error("Response Error:", response.statusText);
      }

    } catch (error) {
      console.error("Error posting active houses:", error);
    }

    setFlagHouse(flagId);

  } else {
  
    const updatedHouses = [...activeHouses];
    const existingAddress = updatedHouses.find(house => house.OWNERADDRESS === account.address);
  
    try {
      if (existingAddress) {
        for (let i = 0; i < activebattledogs.length; i++) {
          const tokenId = activebattledogs[i];
          request.push({
            tokenId: tokenId,
            ownerAddress: account.address,
            sigil: flagHouse
          });
        }
      } else {
        for (let i = 0; i < activebattledogs.length; i++) {
          const tokenId = activebattledogs[i];
          request.push({
            tokenId: tokenId,
            ownerAddress: account.address,
            sigil: flagId
          });
        }
      }

      const response = await fetch(`https://pdflupserver.battledogs.fun/HOUSE`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload)
      });
  
      // Parse the JSON response only if the response is ok
      if (response.ok) {
        const rawResponse = await response.json();
      } else {
        console.error("Response Error:", response.statusText);
      }
  
    } catch (error) {
      console.error("Error posting active houses:", error);
    }
  }

  await getActiveHouses();
};

const handleRefUpload = async () => {
  if (!referral) return;
  // e.preventDefault();   
  network = (blockchain.ecosystem).toLowerCase();
  
  // Save array to a JSON file
  const file = JSON.stringify(referral);
  
  const blob = new Blob([file], { type: 'application/json' }); // Convert string to Blob
  const newfilename = 'activeRefferals.json';
  const formData = new FormData();
  
  // Append the Blob to FormData
  formData.append("avatar", blob, newfilename);
  // Append network type string
  formData.append("network", network);

    try {
        const response = await fetch(`https://pdflupserver.battledogs.fun/avatar`, {
          method: "POST",
          body: formData
        });
        
        if (response.ok) {
          console.log('Ref recorded!');
        } else {
          console.log("Error Occured")
        }

      } catch (e) {
        console.error(e.message)
      }
    };

    const handleState = async (_tokenId) => { 
      let tx;
      tx = Number(_tokenId);  
      network = (blockchain.ecosystem).toLowerCase();
      
      try {          
        const responsecall = await fetch(`https://${blockchain.website}/battledogs/${_tokenId}.json`, {cache: "no-cache",});
        if (!responsecall.ok) {
          // throw new Error('Failed to fetch token data');
          setErrorMessage('Failed to fetch token data. Retry');        
          setErrorMessageVisible(true);
        };
        let tokenData = await responsecall.json();

        const playersData = await readContract({ 
          contract, 
          method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
          params: [tx] 
        })
    
        const playerData = {
          Name: playersData[0].toString(),
          Id: Number(playersData[1].toString()),
          Level: Number(playersData[2].toString()),
          Aggression: Number(playersData[3].toString()),
          Stamina: Number(playersData[4].toString()),
          Fights: Number(playersData[5].toString()),
          Wins: Number(playersData[6].toString()),
          Payout: playersData[7].toString(),
          Activation: Number(playersData[8].toString()),
          History: Number(playersData[9].toString()),
        };
    
        const payoutValue = parseInt(playerData.Payout) / reducer;
        playerData.Payout = payoutValue + ` ${blockchain.tokenA}`;

        const traitTypes = ['Level', 'Aggression', 'Stamina', 'Fights', 'Wins', 'Payout', 'Activation', 'History'];

        for (const [traitType, value] of Object.entries(playerData)) {
          if (traitTypes.includes(traitType)) {
            const existingTrait = tokenData.attributes.find(attr => attr.trait_type === traitType);
    
            if (existingTrait) {
              existingTrait.value = value;
            } else {
              tokenData.attributes.push({ trait_type: traitType, value: value });
            }
          }
        }

         // Save data to a JSON file
          const file = JSON.stringify(tokenData);
          const blob = new Blob([file], { type: 'application/json' }); // Convert string to Blob
          const newFileName = `${_tokenId}.json`;
          const formData = new FormData();     
          // Append the Blob to FormData
          formData.append("avatar", blob, newFileName);
          // Append network type string
          formData.append("network", network);
    
        const response = await fetch('https://pdflupserver.battledogs.fun/avatar', {
          method: 'POST',
          body: formData
        });
    
        const data = await response.json();
      } catch (error) {
        console.error('Error updating player data:', error);
      }
    };      

    const handleActivateUpdateClick = async (e) => {
      if (loading) return;
      e.preventDefault();
      if (flagHouse !== null) {
      setApproval(flagHouse.toString());
      }
      // gasOptions();
      
      const call1 = await contractBattledog.totalSupply();    
      let tx = Number(call1);
      setAllPlayers(tx);
    
      const totalSupply = tx - 1; 
      
      // Check the ID is not an empty string
      if (attackerId === "") {
        setErrorMessage("ID cannot be empty.");
        setErrorMessageVisible(true);
        return;
      }
    
      // Check that attackerId is a valid integer
      if (!integerRegex.test(attackerId)) {
        setErrorMessage("Only a battledog ID is accepted.");
        setErrorMessageVisible(true);
        return;
      }
    
      // Check that attackerId is greater than 0 and less than or equal to totalSupply
      if (attackerId <= 0 || attackerId > totalSupply) {
        setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
        setErrorMessageVisible(true);
        return;
      }
      
      const result = await readContract({
        contract: contract,
        method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
        params: [account.address],
      });
    
      // Check if the player owns the battledog
      const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
      if (ownedbattledogs.length === 0) {
        setErrorMessage("You do not own this Battledog.");
        setErrorMessageVisible(true);
        return;
      }
        
      // Check if the user has enough to activate
      const activeDog = Number((ownedbattledogs[0].activate).toString());
      if (activeDog === 0 && (nativeBalance < activatingAmount)) {
        setErrorMessage(`Insufficient ${blockchain.tokenA} for activation.`);
        setErrorMessageVisible(true);
        return;
      } 

        // Check if the user has enough Game to activate
      if (activeDog > 0 && (gameBalance < charge)) {
        setErrorMessage(`Insufficient ${blockchain.tokenB} to activate up.`);
        setErrorMessageVisible(true);
        return;
      }     

      // Check if the user has enough allowance to activate
      if (activeDog === 0 && (userAllowance < activatingAmount)) {
        setLoading(false);
        setErrorMessage(`Insufficient allowance. Approve more ${blockchain.tokenA}!`);
        setErrorMessageVisible(true);
        return;
      }     

      // Check if the user has enough GAME allowance to activate
    const call2 = await readContract({ 
      contract: contractGame, 
      method: "function allowance(address owner, address spender) view returns (uint256)", 
      params: [account.address, blockchain.battledogaddress],
    });

    let gameAllowance = ethers.utils.formatEther(call2?.toString());

      if (activeDog > 0 && (gameAllowance < charge)) {
        setLoading(false);
        setErrorMessage(`Insufficient allowance. Approve more ${blockchain.tokenB}!`);
        setErrorMessageVisible(true);
        return;
      }
    
      // Everything is valid, activate the nft
      Activatenft(account.address, attackerId);  
    };

  const handleAttackerChange = (e) => {
    setAttackerId(e.target.value);
    playConcrete();
  };

  const handleDefenderChange = (e) => {
    setDefenderId(e.target.value);
    playConcrete();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    playConcrete();
  };

  const handleApprovalChange = (e) => {
    setApproval(e.target.value);
    playConcrete();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    playConcrete();
  };

useEffect(() => {
  if (attackerId !=='') {
    if (isAttackerId) return;
      isAttackerId = true;
    setTargetA(attackerId);
      isAttackerId = false;
  }
}, [attackerId]);

useEffect(() => {  
  if (defenderId !=='') {
    if (isDefenderId) return;
      isDefenderId = true;
    setTargetB(defenderId);
     isDefenderId = false;     
  } else {
    if (isDefenderId) return;
      isDefenderId = true;
    setTargetB(0);       
      isDefenderId = false;  
  };    
}, [defenderId]);

// Assault Components & States ***********************************************************************************************

async function Assault(_account, _attackerId, _defenderId) {
  setLoading(true);   
    let tx1; let tx2;
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function Assault(uint256 attackerId, uint256 defenderId) payable", 
        params: [_attackerId, _defenderId] 
      });
      sendTransaction(transaction);
  
      } catch (err) {
        setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this Battledog again.");
        setErrorMessageVisible(true);
        setLoading(false);
      }
  };

  // Sleep function that resolves after a specified number of milliseconds
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleAssaultUpdateClick = async (_value) => {
    if (loading) return;
    let tx; let _attackerId;
    // gasOptions(); 
    
    if (scoreboard) {    
      _attackerId = _value;
    setAttackerId(_attackerId);
    } else if (assault) {
      _attackerId = attackerId;
    }

     // Check that ID is not an empty string
     if (_attackerId === "" || defenderId === "") {
      setErrorMessage("IDs cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check IDs are valid integers
    if (!integerRegex.test(_attackerId) || !integerRegex.test(defenderId)) {
      setErrorMessage("Only battledog IDs are accepted.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check that battledog does not self debilitate
    if (_attackerId == defenderId) {
      setErrorMessage("You cannot inflict self-harm");
      setErrorMessageVisible(true);
      return;
    }
  
    const call1 = await contractBattledog.totalSupply();    
    tx = Number(call1);
    setAllPlayers(tx); 
    const totalSupply = tx - 1;
  
    tx = Number(defenderId);
    // Check that IDs are greater than 0 and less than or equal to totalSupply
    if (Number(_attackerId) <= 0 || tx <= 0  || Number(_attackerId) > totalSupply || tx > totalSupply) {
      setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }

    const result = await readContract({
      contract: contract,
      method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
      params: [account.address],
    });
    
    // Check if the player owns the battledog
    const ownedbattledogs = result.filter(player => player.id.toString() === _attackerId);
    if (ownedbattledogs.length === 0) {
      setErrorMessage("You do not own this Battledog.");
      setErrorMessageVisible(true);
      return;
    }
    
    const enemyBattledog = await readContract({ 
      contract, 
      method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
      params: [tx] 
    })

    // Check if the battledog has been activated
    const ownedbattledog = result.find(x => x.id.toString() === _attackerId);
    if (ownedbattledog.activate.toString() === '0') {
      setLoading(false);
      setErrorMessage("You need to activate your battledog first!");
      setErrorMessageVisible(true);
      return;
    }
    // Check if enemy battledog has been activated
    if (enemyBattledog[8].toString() === 0) {
      setLoading(false);
      setErrorMessage("Enemy Battledog is not in the Arena!");
      setErrorMessageVisible(true);
      return;
    }
  
  // Check if the battledog is handicapped
  if (ownedbattledog.attack.toString() === 0) {
    setLoading(false);
    setErrorMessage("Battledog is handicapped. You need to weaponize first!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user has enough balance
  if (nativeBalance < requiredAmount) {
    setLoading(false);
    setErrorMessage(`Insufficient ${blockchain.tokenA} to play. Top up first!`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user has enough allowance
  if (userAllowance < requiredAmount) {
    setLoading(false);
    setErrorMessage(`Insufficient allowance. Approve more ${blockchain.tokenA}!`);
    setErrorMessageVisible(true);
    return;
  }
  
  // Check if enemy battledog has been handicapped 
  if (enemyBattledog[3].toString() === 0 ) {
    setLoading(false);
    setErrorMessage("Enemy Battledog is already handicapped!");
    setErrorMessageVisible(true);
    return;
  }

  
  
    // Everything is valid, assault the battledog
    Assault(account.address, _attackerId, defenderId);
};   

// Debilitate Component & States *****************************************************************************

async function Debilitate(_account, _attackerId, _defenderId) {
  setLoading(true); 
    let tx1; let tx2;
    try {
    const transaction = prepareContractCall({ 
      contract, 
      method: "function Debilitate(uint256 attackerId, uint256 defenderId) payable", 
      params: [_attackerId, _defenderId] 
    });
  
    sendTransaction(transaction);
  
      } catch (err) {      
        setLoading(false);
        setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this battledog again.");
        setErrorMessageVisible(true);
      }  
};      

const handleDebilitateUpdateClick = async (_value) => {
  if (loading) return;
  let tx; let _attackerId;
  // gasOptions();
    
  if (scoreboard) {
    _attackerId = _value;
    setAttackerId(_attackerId);
  } else if (debilitate) {
    _attackerId = attackerId;
  }


   // Check that ID is not an empty string
   if (_attackerId === "" || defenderId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check IDs are valid integers
  if (!integerRegex.test(_attackerId) || !integerRegex.test(defenderId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }
 

  // Check that battledog does not self debilitate
  if (_attackerId == defenderId) {
    setErrorMessage("You cannot inflict self-harm");
    setErrorMessageVisible(true);
    return;
  }
      
  const call1 = await contractBattledog.totalSupply();    
  tx = Number(call1);
  setAllPlayers(tx);
  const totalSupply = tx - 1;
  
  tx = Number(defenderId); 
  // Check that IDs are greater than 0 and less than or equal to totalSupply
  if (Number(_attackerId) <= 0 || tx <= 0  || Number(_attackerId) > totalSupply || tx > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }  
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === _attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  } 
  
  const enemyBattledog = await readContract({ 
    contract, 
    method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
    params: [tx] 
  })

  // Check if the Battledog has been activated
  const ownedbattledog = result.find(x => x.id.toString() === _attackerId);
  if (ownedbattledog.activate.toString() === '0') {
    setLoading(false);
    setErrorMessage("You need to activate your Battledog first!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if enemy battledog has been activated
  if (enemyBattledog[8].toString() === '0') {
    setLoading(false);
    setErrorMessage("Enemy Battledog is not in the Arena!");
    setErrorMessageVisible(true);
    return;
  }

// Check if the Battledog is incapacitated
if (ownedbattledog.defence.toString() === 0) {
  setLoading(false);
  setErrorMessage("Battledog is handicapped. You need to regenerate first!");
  setErrorMessageVisible(true);
  return;
}

// Check if enemy Battledog has been incapacitated 
if (enemyBattledog[4].toString() === 0 ) {
  setLoading(false);
  setErrorMessage("Enemy Battledog is already handicapped!");
  setErrorMessageVisible(true);
  return;
}

// Check if the user has enough balance
if (nativeBalance < requiredAmount) {
  setLoading(false);
  setErrorMessage(`Insufficient ${blockchain.tokenA} to play. Top up first!`);
  setErrorMessageVisible(true);
  return;
}

// Check if the user has enough allowance
if (userAllowance < requiredAmount) {
  setLoading(false);
  setErrorMessage(`Insufficient allowance. Approve more ${blockchain.tokenA}!`);
  setErrorMessageVisible(true);
  return;
}

  // Everything is valid, debilitate the Battledog
  Debilitate(account.address, _attackerId, defenderId);
};     

// Weaponize component & states ************************************************************************************

async function Weaponize(_account, _tokenId) {
  setLoading(true);  
    let tx;  
      const transaction = prepareContractCall({ 
        contract, 
        method: "function weaponize(uint256 _tokenId) payable", 
        params: [attackerId] 
      });
      sendTransaction(transaction);
};

const handleWeaponizeUpdateClick = async(e) => {
  e.preventDefault();
  if (loading) return;
  // gasOptions();
  
  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1);
  setAllPlayers(totalSupply);

  // Check the ID is not an empty string
  if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user owns the Battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the Battledog has been activated
  const ownedbattledog = allOwnerOfPlayers.find(x => x.id.toString() === attackerId);
  if (ownedbattledog.activate.toString() === "0") {
    setLoading(false);
    setErrorMessage("You need to activate your Battledog first!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user has enough balance
  if (nativeBalance < requiredAmount) {
    setLoading(false);
    setErrorMessage(`Insufficient ${blockchain.tokenA} to play. Top up first!`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user has enough allowance
  if (userAllowance < requiredAmount) {
    setLoading(false);
    setErrorMessage(`Insufficient allowance. Approve more ${blockchain.tokenA}!`);
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, weaponize the Battledog
  Weaponize(account.address, attackerId);
};    

// Regenerate Component & States ***************************************************************************************

async function Regenerate(_account, _tokenId) {
  setLoading(true); 
    let tx; 
      const transaction = prepareContractCall({ 
        contract, 
        method: "function regenerate(uint256 _tokenId) payable", 
        params: [attackerId] 
      });

      sendTransaction(transaction);
};

const handleRegenerateUpdateClick = async(e) => {
  e.preventDefault();
  if (loading) return;
  // gasOptions();

  const call0 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call0);
  setAllPlayers(totalSupply);

  // Check the ID is not an empty string
  if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the Battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the Battledog has been activated
  const ownedbattledog = result.find(x => x.id.toString() === attackerId);
  if (ownedbattledog.activate.toString() === "0") {
    setLoading(false);
    setErrorMessage("You need to activate your Battledog first!");
    setErrorMessageVisible(true);
    return;
  }
  
  // Check if the user has enough balance
  if (nativeBalance < requiredAmount) {
    setLoading(false);
    setErrorMessage(`Insufficient ${blockchain.tokenA} to play. Top up first!`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user has enough allowance
  if (userAllowance < requiredAmount) {
    setLoading(false);
    setErrorMessage(`Insufficient allowance. Approve more ${blockchain.tokenA}!`);
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, regenerate the Battledog
  
  Regenerate(account.address, attackerId);
};        

// ClaimAssault component & States ****************************************************************************************

async function Claimassault(_account, _attackerId) {
  setLoading(true); 
    let tx;  
      const transaction = prepareContractCall({ 
        contract, 
        method: "function claimAssault(uint256 defenderId)", 
        params: [attackerId] 
      });

      sendTransaction(transaction);
};

const handleClaimAssaultUpdateClick = async(e) => {
  if (loading) return;
  e.preventDefault();
  // gasOptions();

  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1) - 1;
  setAllPlayers(totalSupply);

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if battledog has pending rewards 
  const dBattledog = result.find(x => x.id.toString() === attackerId);
  if (Number(dBattledog.attack.toString()) < 200) {
    setLoading(false);
    setErrorMessage("This Battledog has no pending rewards!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  Claimassault(account.address, attackerId);
};      

// claimDebilitate handles & functions **********************************************************************************

async function Claimdebilitate(_account, _attackerId) {
  setLoading(true);    
    let tx;
      const transaction = prepareContractCall({ 
        contract, 
        method: "function claimDebilitate(uint256 defenderId)", 
        params: [attackerId] 
      });

      sendTransaction(transaction);
};

const handleClaimDebilitateUpdateClick = async(e) => {
  e.preventDefault();
  if (loading) return;
  // gasOptions();

  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1) - 1;
  setAllPlayers(totalSupply);

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has pending rewards 
  const dBattledog = result.find(x => x.id.toString() === attackerId);
  if (Number(dBattledog.defence.toString()) < 200) {
    setLoading(false);
    setErrorMessage("This Battledog has no pending rewards!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  Claimdebilitate(account.address, attackerId);
};    

// Levelup components & functions *************************************************************************************

async function Levelup(_account, _attackerId) {
  setLoading(true);    
    let tx;
      const transaction = prepareContractCall({ 
        contract, 
        method: "function levelUp(uint256 attackerId)", 
        params: [attackerId] 
      });
      sendTransaction(transaction);
};

const handleLevelUpUpdateClick = async (e) => {
  if (loading) return;
  let tx1; let tx2;
  e.preventDefault();
  // gasOptions();

  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1) - 1;
  setAllPlayers(totalSupply);

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has unclaimed wins 
  const dBattledog = result.find(x => x.id.toString() === attackerId);
  if (Number(dBattledog.wins.toString()) < 5) {
    setLoading(false);
    setErrorMessage("This battledog has insufficient wins!");
    setErrorMessageVisible(true);
    return;
  }
    
  // Check if Game is approved
  const call2 = await readContract({ 
    contract: contractGame, 
    method: "function allowance(address owner, address spender) view returns (uint256)", 
    params: [account.address, blockchain.battledogaddress],
  });

  tx1 = ethers.utils.formatEther(call2?.toString());
  tx2 = charge * Number(dBattledog.activate.toString());

  if (Number(tx1) < charge) {
    setLoading(false);
    setErrorMessage(`You need to approve ${tx2} ${blockchain.tokenB} to levelup this battledog!`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if Available Game is sufficient  

  if (gameBalance < tx2) {
    setLoading(false);
    setErrorMessage(`You require ${tx2} ${blockchain.tokenB} to levelup this battledog!`);
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the battledog
  Levelup(account.address, attackerId);
};   
 

// Payout handles & States *******************************************************************************************

async function Payouts(_account, _attackerId) {
  setLoading(true);   
    let tx;  
      const transaction = prepareContractCall({ 
        contract, 
        method: "function Payouts(uint256 _playerId) payable", 
        params: [attackerId] 
      });
  
      sendTransaction(transaction);
};

const handlePayoutUpdateClick = async(e) => {
  e.preventDefault();
  if (loading) return;
  // gasOptions();

  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1) - 1;
  setAllPlayers(totalSupply);

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the Battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has required wins 
  const dBattledog = result.find(x => x.id.toString() === attackerId);
  if (Number(dBattledog.wins.toString()) < 5) {
    setLoading(false);
    setErrorMessage("Battledog doesn't meet the minimum wins required!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has required level 
  if (dBattledog.level < 1) {
    setLoading(false);
    setErrorMessage("Battledog doesn't meet the minimum level required!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has required payout 
  if (dBattledog.payout === "0") {
    setLoading(false);
    setErrorMessage("Oops, Battledog has no payout!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  Payouts(account.address, attackerId);
};  

// Customize handles & components*************************************************************************************
const [type, setType] = useState(false);

async function Customize(_account, _attackerId, _name) {
  setLoading(true); 
    let tx;
    tx = Number(attackerId);
      const transaction = prepareContractCall({ 
        contract, 
        method: "function updateName(uint256 _tokenId, string _newName)", 
        params: [tx, name] 
      });

      sendTransaction(transaction);
};

const handleCustomizeUpdateClick = async(e) => {
  e.preventDefault();
  if (loading) return;
  // gasOptions();

  // Check the ID is not an empty string
  if (attackerId === "" || name === '') {
    setErrorMessage("Field cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1);
  setAllPlayers(totalSupply);
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);
    
  // Check if the player owns the battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // All input is valid, update the name
  Customize(account.address, attackerId, name);
};

const toggleType = () => {
  setType(!type);
}

const fileInput = createRef();

const handleImageUpload = async (e) => {
  e.preventDefault(); 
  
  network = (blockchain.ecosystem).toLowerCase();

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1);
  setAllPlayers(totalSupply);
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);
    
  // Check if the player owns the battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  const file = fileInput.current.files[0];
  const fileExtension = file.name.split('.').pop();

  const allowedExtensions = ['jpg', 'png', 'gif'];
  const maxSize = 1 * 1024 * 1024; // 1 megabyte

  if (!allowedExtensions.includes(fileExtension)) {
    setErrorMessage('Invalid file type.');
    setErrorMessageVisible(true);
    return;
  }

  if (file.size > maxSize) {
    setErrorMessage('File exceeds 1MB.');
    setErrorMessageVisible(true);
    return;
  }

  const newFileName = `${attackerId}.${fileExtension}`;
  const formData = new FormData();
  formData.set("avatar", file, newFileName);

  // Append network type string
  formData.append("network", network);

  (async () => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = async function () {
      const width = this.width;
      const height = this.height;
      if (width !== height) {
        setErrorMessage("Wrong Dimensions.");
        setErrorMessageVisible(true);
        return;
      }

      if (width !== 512 && width !== 768 && width !== 1024) {
        setErrorMessage("Must be 512x512, 768x768, or 1024x1024 pixels allowed.");
        setErrorMessageVisible(true);
        return;
      }

      try {
        const response = await fetch(`https://pdflupserver.battledogs.fun/avatar`, { // server uploader goes here
          method: "POST",
          body: formData
        });

        if (response.ok) {
          setErrorMessage('Image uploaded!');
          setErrorMessageVisible(true);
        } else {
          console.log("Error Occured")
        }
      } catch (e) {
        console.error(e.message)
      }
    };
  })();
};


// Harvester component & states*******************************************************************************************
const [stakeType, setStakeType] = useState(false);
const [minerLogs, setMinerLogs] = useState(null);
const [harvesterLogs, setHarvesterLogs] = useState(null);
const [isApproved, setIsApproved] = useState(false)
const [currentAllowance, setCurrentAllowance] = useState('');
const [isWaiting, setIsWaiting] = useState(false);
const [isClaiming, setIsClaiming] = useState(false);
const [isWithdrawing, setIsWithdrawing] = useState(false);
const [isRevoking, setIsRevoking] = useState(false);
const [isFarming, setIsFarming] = useState(false);
const [totalStaked, setTotalStaked] = useState("");
const [currentStake, setCurrentStake] = useState("");
const [poolShares, setPoolShares] = useState("");
const [rewardClaim, setRewardClaim] = useState("");
const [totalClaim, setTotalClaim] = useState("");
const [rewardOwed, setRewardOwed] = useState("");
const [onset, setOnset] = useState();
const [apy, setApy] = useState("");
const [lpbalance, setLPBalance] = useState("");

const handleHover = () => {
  setIsHovered(true);
  playConcrete();
};

const handleOnHover = () => {
  setIsHovered(true);
  playRumble();
};

const StakeColumn = ({ className, children }) => {
  
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(!active);
      playWhoosh();
    }
  };

  return (
    <div
      className={`stake-column ${className} ${active ? "active" : ""}`}
      onClick={handleColumnClick}
      ref={columnRef}
    >
      {active && children}
    </div>
  );
};

const MobileColumn = ({ className, children }) => {
  
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(!active);
      playWhoosh();
    }
  };

  return (
    <div
      className={`mobile-column ${className} ${active ? "active" : ""}`}
      onClick={handleColumnClick}
      ref={columnRef}
    >
      {active && children}
    </div>
  );
};  

const handleFunction1 = async (_value) => {
    if (!provider) return;
    let tx; let value; let contracts;
    tx = Number(_value);
    value = _value.toString();
    setApproval(value);
  // gasOptions();    
  try {
    setSubmitText('Validating Farm Request...');
    if (!stakeType) {
      contracts = contractGame;
    } else {
      contracts = contractLP;
    }

    // Check if approval
    const call1 = await readContract({ 
      contract: contracts, 
      method: "function allowance(address owner, address spender) view returns (uint256)", 
      params: [account.address, rewarderAddress],
    });

    const userallowance = Number(ethers.utils.formatEther(call1?.toString()));

    if (tx > userallowance) {
      approveGameHarvester(value);
    } else {
      addGAME(value);
    }      
  } catch (err) {
    console.error(err);
  }
};

const handleFunction2 = async () => {
  setLoading(true);
  // gasOptions();
  const tx = await withdrawGAME(); 
  // await tx;
  fetchFarmData();  
  setLoading(false);
};

const handleFunction3 = async () => {
  setLoading(true);
  // gasOptions();
  const tx = await claimRewards();
  // await tx;     
  fetchFarmData();  
  console.log('Claim Succeeded.');
  setLoading(false);
};

const handleHarvestSubmit = async (e, func) => {
  e.preventDefault();

  const Value = e.target.input1.value;
  if (!Value) {
    return;
  }

  try { 
  setLoading(true);     
  await func(Value); 
  fetchFarmData();  
  await resetAllowance(); 
  setLoading(false); 

  } catch (err) {
    console.error(err);
  setLoading(false);
  }
};

const handleHarvestSubmitter = async (e, func) => {
  e.preventDefault();
  setLoading(true);

  const digit = e.target.input2.value;
  if (!digit) {
    return;
  }

  try {  
  await func(digit); 
  setShowForm(false); 
  await resetAllowance();
  } catch (err) {
    console.error(err);
  }
  setLoading(false);
};
  
  // Claim all Rewards

  const claimRewards = async () => {
    if (isClaiming) return;
    try {
      setIsClaiming(true);   
      submitText(`Claiming harvest...`); 
        
        const transaction = prepareContractCall({ 
          contract: contractHarvester, 
          method: "function claim()", 
          params: [] 
        });
        sendTransaction(transaction);
        // await tx.wait();
        console.log("Claim success");
        playWithdraw();
    } catch (err) {

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsClaiming(false);
    }    
  };

const resetAllowance = async () => {
  setCurrentAllowance(0);
  let tx; let contractr;
    if (!stakeType) {
      contractr = contractGame;
    } else {
      contractr = contractLP;
    }
    
    const call1 = await readContract({ 
      contract: contractr, 
      method: "function allowance(address owner, address spender) view returns (uint256)", 
      params: [account.address, rewarderAddress],
    });

    tx = ethers.utils.formatEther(call1?.toString());
    
    setCurrentAllowance(tx);
}

// Approve access to GAME tokens
const approveGameHarvester = async (value) => {  
  if (isWaiting) return;
  try {    
    setSubmitText('Initializing Approval...');
    setIsWaiting(true);
    let contractERC;
    if (!stakeType) {
      contractERC = contractGame;
    } else {
      contractERC = contractLP;
    }

    const approved = ethers.utils.parseEther(value);
    const transaction = prepareContractCall({ 
      contract: contractERC, 
      method: "approve", 
      params: [rewarderAddress, approved] 
    });
    sendTransaction(transaction);
    
  } catch (err) {

    if (err.message && err.message.includes("user rejected")) {
      console.log("Transaction Rejected by User");
    }
    setIsWaiting(false);
    return false;
  }
};

// Revoke access to GAME tokens
const revokeGameHarvester = async () => {
  if (isRevoking) return;
  try {
    setSubmitText("Revoking Allowance...");
    setLoading(true);
    setIsRevoking(true);
    let contractERC;
    if (!stakeType) {
      contractERC = contractGame;
    } else {
      contractERC = contractLP;
    }
    
    const transaction = prepareContractCall({ 
      contract: contractERC, 
      method: "approve", 
      params: [rewarderAddress, 0] 
    });

    // await tx.wait();
    console.log("Revoke Succeeded");
    await resetAllowance();    
    setIsRevoking(false);
    setLoading(false);
  } catch (err) {

    if (err.message && err.message.includes("user rejected")) {
      console.log("Transaction Rejected by User");
    setIsRevoking(false);
    setLoading(false);
    }
  }
};

// Add GAME to farming.
const addGAME = async (value) => {
  if (isFarming) return;
    setIsFarming(true); 
      setSubmitText('Initializing Farm...');
      const amount = ethers.utils.parseEther(value); 
      const _amount = Number(amount);      

      const transaction = prepareContractCall({ 
        contract: contractHarvester, 
        method: "function addGAME(uint256 _amount)", 
        params: [_amount] 
      });
      sendTransaction(transaction); 
};

// Withdraw all GAME.
const withdrawGAME = async () => {
  if (!provider || isWithdrawing) return;
  try {
    setIsWithdrawing(true);    
    submitText("Withdraw processing...");
    const transaction = prepareContractCall({ 
      contract: contractHarvester, 
      method: "function withdrawGAME()", 
      params: [] 
    });
    sendTransaction(transaction);

      console.log("Withdraw Succeeded");
      playWithdraw();
  } catch (err) { 
    if (err.message && err.message.includes("user rejected")) {
      console.log("Transaction Rejected by User");
    }
    setIsWithdrawing(false);    
  }   
};

//Fetch Farm Data
const fetchFarmData = async () => {
  if (!provider) return;
  let Decimals;
  
  const divisor = ethers.utils.parseEther((100).toString());

  const block = await provider.getBlock('latest');
  const blockTimeStamp = block.timestamp;
  console.log("blockTimeStamp: ", blockTimeStamp);

  if (!stakeType) {
    Decimals = decimals; 
  } else {
    Decimals = 10**18;
  } 

    if (!eraLength) {
      replenisher = await readContract({ 
        contract: contractHarvester, 
        method: "function replenisher() view returns (bool)", 
        params: []  
      });
  
      replenishTax = await readContract({ 
        contract: contractHarvester, 
        method: "function replenishTax() view returns (uint256)", 
        params: [] 
      });
  
      replenished = Number(replenishTax?.toString()) / 100; 
  
      let rps = await readContract({ 
        contract: contractHarvester, 
        method: "function rewardPerStamp() view returns (uint256)", 
        params: [] 
      });    

      rewardPerStamp = Number(rps?.toString());
  
      let erl = await readContract({ 
        contract: contractHarvester, 
        method: "function eralength() view returns (uint256)", 
        params: [] 
      });

      eraLength = Number(erl?.toString());  
    }

  const ts = await readContract({ 
    contract: contractHarvester, 
    method: "function TotalGAMESent() view returns (uint256)", 
    params: [] 
  });
  const tstaked = ethers.utils.formatEther(ts?.toString());
  setTotalStaked(Number(tstaked).toFixed(2));

  const tc = await readContract({ 
    contract: contractHarvester, 
    method: "function totalClaimedRewards() view returns (uint256)", 
    params: [] 
  });
  const tClaim = Number(tc?.toString()) / Decimals;
  setTotalClaim(tClaim.toFixed(blockchain.tofixed));

  const rc = await readContract({ 
    contract: contractHarvester,
    method: "function Claimants(address) view returns (uint256)", 
    params: [account.address]
  });
  const rclaimed = Number(rc) / Decimals;
  setRewardClaim(rclaimed.toFixed(blockchain.tofixed));

  const claimRewardsResult = await readContract({ 
    contract: contractHarvester,
    method: "function claimRewards(address) view returns (uint256 eraAtBlock, uint256 GAMESent, uint256 rewardsOwed)", 
    params: [account.address]
  });

  const [eraAtBlock, GAMESent, rewardsOwed] = claimRewardsResult;
  const dSent = ethers.utils.formatEther(GAMESent?.toString());  

  setCurrentStake(dSent);

  const year = blockTimeStamp + 31556951;
  const RoWed = Number(rewardsOwed?.toString());

  const GAMEPoolShare = (parseFloat(dSent) * 100) / parseFloat(tstaked);
  setPoolShares(GAMEPoolShare);

  let revenue;
  let yrevenue;
  
  const startPeriod = Number(eraAtBlock?.toString());

  let endPeriod;

  let cst = await readContract({ 
    contract: contractHarvester,
    method: "function startTime() view returns (uint256)", 
    params: [] 
  });
  const contractStartTime = Number(cst?.toString());
  console.log("contractStartTime: ", contractStartTime);

  let lvd = await readContract({ 
    contract: contractHarvester,
    method: "function liveDays() view returns (uint256)", 
    params: [] 
  });
  const livedays = Number(lvd?.toString());

  console.log("startPeriod: ", startPeriod);
  let stakeTime = (blockTimeStamp - contractStartTime) / eraLength;
  endPeriod = Math.floor(stakeTime);

  if (startPeriod !== endPeriod) { 
    let reve = RoWed;
    let eraReward;
    for (let i = startPeriod; i < endPeriod; i++) {        
    let eR = await readContract({ 
      contract: contractHarvester,
      method: "function eraRewards(uint256) view returns (uint256)", 
      params: [i]   
    });
    const eraData = Number(eR?.toString());
    console.log("eraData: ", eraData);
    if (eraData > 0) {
    eraReward = eraData;
    } else {
      // determine if 0 is from deduced result or from unintialized query
      if (i <= livedays) {
        eraReward = 0;
      } else {      
        eraReward = rewardPerStamp; 
      }
    }
    
    const rewardsAccrued = eraReward * Number(dSent);
    reve += rewardsAccrued;
    }
    revenue = ((reve ) / Number(divisor) );
    
    const estimatedRewards = Math.abs(revenue) / Decimals;

    if (!stakeType && replenisher) {
      setRewardOwed(estimatedRewards * replenished);
    } else {       
      setRewardOwed(estimatedRewards);
      setOnset(true);
    }

    // const yreve = (Number(rps) * year * Number(dSent)) + Number(RoWed);;
    // const yreven = Math.abs(yreve);
    // yrevenue = yreven / divisor;
  } else {
    setRewardOwed(0);
  }
  const GAMEApy = (yrevenue / 365) * 100;
  setApy(Number(GAMEApy));
};  

const handleStakeTypeChange = (isLPStake) => {
  if (!blockchain.lpharvesteraddress) return;
  setStakeType(isLPStake);
  setRewarderAddress(isLPStake ? blockchain.lpharvesteraddress : blockchain.harvesteraddress);
};

async function harvestRun () {  
  await resetAllowance(); 
  await fetchFarmData(); 
}

useEffect(() => {  
  if (!provider || isRunning) return;
  if (harvester) {
      isRunning = true; 
    harvestRun();
      isRunning = false;
   }
}, [stakeType, harvester]);  

// Mining Component & states ***************************************************************************************
const [unlocker, setUnlocker] = useState(false);
const [unlocker2, setUnlocker2] = useState(false);
const [submitText2, setSubmitText2] = useState("");
const [timelock, setTimeLock] = useState();
const [locks, setLocks] = useState([]);
const [showButton, setShowButton] = useState(true);

const statusLock = async () => {
  const total = activeBattledogs.length;
  let lockmaster = [];
  for (let i = 0; i < total; i++ ) {
    const inquestId = activeBattledogs[i];
    let ms = await readContract({ 
      contract: contractPop, 
      method: "function MinerClaims(uint256) view returns (uint256)", 
      params: [inquestId] 
    });
    const minerState = Number(ms?.toString());
    lockmaster.push(minerState);
  }    
    setLocks(lockmaster);

  if (!timeLock) {
  let tc = await readContract({ 
    contract: contractPop, 
    method: "function timeLock() view returns (uint256)", 
    params: [] 
  });
  const timelock = Number(tc?.toString());
  setTimeLock(timelock);
  } else setTimeLock(timeLock);
}

useEffect(() => {
  if (isLocking || !activeBattledogs) return;
    isLocking = true;
  statusLock();
    isLocking = false;
}, [mining]);

const handleButtonClick = () => {
  setShowButton(false);
};

const claim = async (inputValue) => {
  if (isClaiming) return;
  let tx;
  try {
    setIsClaiming(true);
    setSubmitText("Initiating Claim...");
    setUnlocker(true);

    const transaction = prepareContractCall({ 
      contract: contractPop, 
      method: "function mineGAME(uint256[] _nfts)", 
      params: [inputValue] 
    });
    sendTransaction(transaction);

  } catch (err) {
    console.error(err);
    setUnlocker(false);
    setIsClaiming(false);
  }
};

const multiclaim = async (arrayValue) => {
  if (isClaiming) return;
  let tx;
  try {
    setIsClaiming(true);
      setSubmitText2("Initiating Claim...");
      setUnlocker2(true);
      
    const transaction = prepareContractCall({ 
      contract: contractPop, 
      method: "function mineGAME(uint256[] _nfts)", 
      params: [arrayValue] 
    });
    sendTransaction(transaction);
    
  } catch (err) {
    console.error(err);
    setUnlocker2(false);
    setLoading(false);
  }
};

const [active, setActive] = useState(false);

const XboColumn = ({backgroundImage, children}) => {

const columnRef = useRef(null);

const handleColumnClick = (e) => {
  if (e.target === columnRef.current) {
    setActive(!active);
    playWhoosh();
  }
};

  return (
    <div onClick={handleColumnClick}
      className={`xbo-column ${active ? "active" : ""} col-1`}
      ref={columnRef}
      style={{backgroundColor: "black", backgroundImage: backgroundImage ? `url(${backgroundImage})` : `none` }}
    >
      {active && children}
    </div>
  );
};  

const MobileXboColumn = ({ className, backgroundImage, children }) => {
  
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(true);
      playWhoosh();
    }
  };

  return (
    <div className={`mobile-column ${className} ${active ? "active" : ""}`}
    onClick={handleColumnClick}
    ref={columnRef}
    style={{border: `0.25vh solid ${blockchain.theme}`,backgroundColor: "black", backgroundImage: backgroundImage ? `url(${backgroundImage})` : `none` }}
  >
    {active && children}
    </div>
  );
};  

const handleMiningFunction1 = () => {
  if (!selectedPlayer) {
    setActive(false);
    return
  };
  setSubmitText("Verifying...");
  // gasOptions();
  claim([parseInt(selectedPlayer.toString())]); 
};

const handleMiningFunction2 = () => { 
  if (!activeBattledogs) {
      setActive(false);
      return
    };
  setSubmitText("Verifying...");
  // gasOptions();
  multiclaim(activeBattledogs);
};  

const handleMiningSubmit = (e, func) => {
  e.preventDefault();
  func();
};

const handleMiningClick = (tokenId) => {
  if (selectedPlayer != tokenId) {
  setSelectedPlayer(tokenId);
  playWhoosh();
  }
};

//ResetTimer component & states *****************************************************************************************

async function ResetTimer(_account, _attackerId) {
  setLoading(true); 
    let tx;    
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function resetFunctionCalls(uint256 _playerId)", 
        params: [defenderId] 
      });

      sendTransaction(transaction);
      
      } catch (err) {
      setLoading(false);        
      setErrorMessage("Failed? Contract paused or can't reset timer twice in 24hrs!");       
      setErrorMessageVisible(true);
      } 
};

const handleResetTimerUpdateClick = async(e) => {
  e.preventDefault();
  if (loading) return;
  // gasOptions();

  const call1 = await contractBattledog.totalSupply();    
  const totalSupply = Number(call1) - 1;
  setAllPlayers(totalSupply);

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  } 
      
  const result = await readContract({
    contract: contract,
    method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
    params: [account.address],
  });
  setAllOwnerOfPlayers(result);

  // Check if the player owns the Battledog
  const ownedbattledogs = result.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  ResetTimer(account.address, attackerId);
}; 

// Lock component & states*********************************************************************************************

const [allowanceImage, setAllowanceImage] = useState(null);
const [divisionalTitle, setDivisionalTitle] = useState("");
const [query, setQuery] = useState(false);
const [isLoading, setIsLoading] = useState(false);

const handleQuery = () => {
  setQuery(!query);
  playFastwhoosh();
}

  function refreshAllowanceData () {
      if (userAllowance === 0) {
        setAllowanceImage("nonapproved");
        setDivisionalTitle(`All Txs will fail: ${userAllowance} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`);
      } else if (nativeBalance === 0) {            
        setAllowanceImage("nonapproved");
        setDivisionalTitle(`All Txs will fail: ${userAllowance} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`);
      } else if ((userAllowance > healthLimit) && (nativeBalance !== 0)) {
        setAllowanceImage("approved");
        setDivisionalTitle(`Healthy: ${userAllowance} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`);
      } else if ((userAllowance > requiredAmount) && (userAllowance < healthLimit) && (nativeBalance !== 0)) {
        setAllowanceImage("disapproved");
        setDivisionalTitle(`Re-Approve: Below healthy limit. ${userAllowance} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`);
      } else if ((userAllowance < requiredAmount) && (nativeBalance !==0) ) {
        setAllowanceImage("unapproved");
        setDivisionalTitle(`Too Low: Less than required! ${msg} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`);
      }
  };
  
// Scoreboard component & states *********************************************************************************************
const [scoreAssaultMap, setScoreAssaultMap] = useState(false);
const [scoreDebilitateMap, setScoreDebilitateMap] = useState(false);
const [desktopBenchmark, setDesktopBenchmark] = useState(0);
const [mobileBenchmark, setMobileBenchmark] = useState(0);

const handleScoreSubmit = async (e, func, _player) => {
  e.preventDefault();
  setAttackerId('');
  try {  
    await func(_player); 
  } catch (err) {
    console.error(err);
  }
};  

const handleScoreSubmitter = async (e, func, player) => {
  e.preventDefault();
  setLoading(true);
  try {  
    await func(player); 
  } catch (err) {
    console.error(err);
  }
  setLoading(false);
}; 

const getStats = async (playerId) => {
  let leaderboard = [];
  setShowImage(false);   
  try {
  const player = await readContract({ 
    contract, 
    method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
    params: [playerId] 
  });
  leaderboard.push(player);
  setSelectedPlayerStats(leaderboard);
  } catch (err) {    
    console.error(err);
  }
};

const handleView = () => {
  setShowImage(!showImage);
} 

const handleReceipt = () => {
  if (activeBattledogs.length === 0) return;
  setShowReceipt(!showReceipt);
} 

const handleKlanReceipt = () => {
  if (activeBattledogs.length === 0) return;
  setShowKlanReceipt(!showKlanReceipt);
}  

// const handleDestroyKlanReceipt = () => {
//   setShowDestroyKlanReceipt(!showDestroyKlanReceipt);
// } 

const handleCreateKlan = () => {
  setShowKlan(!showKlan);
} 

const hideView = () => {
  setSelectedPlayerStats();
  setScoreAssaultMap(false);
  setScoreDebilitateMap(false);
  if (houseboard) {
  setShowKlan(false);
  setShowKlanReceipt(false);
  }
}

const handleScoreAssault = () => {
  setScoreDebilitateMap(false);
  setScoreAssaultMap(true);
}

const handleScoreDebilitate = () => {
  setScoreAssaultMap(false);
  setScoreDebilitateMap(true);
}

const renderAssault = () => {
  return ownedBattledogs && (
      <>
      {ownedBattledogs.map((player, index) => {
        return (
          <div key={index} className={`scoreText twhite scoreassault${loading}`} disabled={loading ? 1 : 0}>
            with<br /> 
            <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"}
             onClick={(e) => handleScoreSubmit(e, handleAssaultUpdateClick, player.id.toString())}
            style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/><br /> 
            <span>BID {player.id.toString()}</span>
          </div>
        );
      })}    
      </>
    )} 

const renderDebilitate = () => {
  return ownedBattledogs && (
      <>
      {ownedBattledogs.map((player, index) => {
        return (
          <div key={index} className={`scoreText twhite scoredebilitate${loading}`} disabled={loading ? 1 : 0}>
            with<br />
            <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"}
             onClick={(e) => handleScoreSubmit(e, handleDebilitateUpdateClick, player.id.toString())}
            style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/><br />
            <span>BID {player.id.toString()}</span>
          </div>
        );
      })}    
      </>
    )} 

const LittleColumn = ({ className, children }) => {
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      // selectedPlayer(true);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (columnRef.current && !columnRef.current.contains(e.target)) {
        setSelectedPlayerStats();
        // setShowImage(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [loading]);

  return (
    <div
      className={`little-column ${selectedPlayer ? "selectedPlayer" : null}`}
      onClick={handleColumnClick}
      ref={columnRef}
    >
      {selectedPlayer && children}
    </div>
  );
}; 

const desktopMax = 18;
const mobileMax = 25;
let totalDesktopBenchmarks = Math.max(1, Math.ceil(activePlayersList.length / desktopMax));
let totalMobileBenchmarks = Math.max(1, Math.ceil(activePlayersList.length / mobileMax));

const renderactivePlayersList = () => { 
  return activePlayersList.map((playerTokenId, index) => {
    // For Desktop
    const desktopStartIndex = desktopBenchmark * desktopMax;
    const isDesktopVisible = index >= desktopStartIndex && index < Math.min(desktopStartIndex + desktopMax, activePlayersList.length);
    
    // For Mobile
    const mobileStartIndex = mobileBenchmark * mobileMax;
    const isMobileVisible = index >= mobileStartIndex && index < Math.min(mobileStartIndex + mobileMax, activePlayersList.length);
    
    if (!isDesktopVisible && !isMobileVisible) return null;
    
    // Default fallback style for players with no house
    let playerStyle = { color: 'darkslategray', border: '1px solid darkslategray' };
    let houseName = null;
    
    // Check if player has a house
    const houseForPlayer = activeHouses.find(house => house.ID === playerTokenId);

    if (houseForPlayer) {
      const sigilIndex = houseForPlayer.SIGIL;

      // Ensure sigilIndex is valid and fetch the corresponding color and name
      if (allActiveHouses[sigilIndex]) {
        const houseColor = allActiveHouses[sigilIndex].COLOR;
        houseName = allActiveHouses[sigilIndex].NAME;
        playerStyle = {
          color: houseColor,
          border: `1px solid ${houseColor}`
        };
      }
    }

    return (
      <div key={playerTokenId}>
        <Desktop>
          {isDesktopVisible && (
            <div style={playerStyle} className="leaderboard jlb" onClick={(e) => handleScoreSubmitter(e, getStats, playerTokenId)}> 
              {!houseName ? <>Battledog</> : <>{houseName}</>}
              <br /> 
              <span className="lbMid">{playerTokenId}</span>
            </div> 
          )}
        </Desktop>
        <Mobile>
          {isMobileVisible && (
            <div style={playerStyle} className="leaderboardMb lbSmallMb" onClick={(e) => handleScoreSubmitter(e, getStats, playerTokenId)}>
              {!houseName ? <>BID</> : <>{houseName}</>}
              <br />
              <span className="lbLargeMb">{playerTokenId}</span>
            </div>
          )}
        </Mobile>
      </div>   
    );
  }).filter(Boolean); // Remove null entries
};

// ActivityTracker component & states **********************************************************************************

const [tokenIds, setTokenIds] = useState([]); 
const [assaulters, setAssaulters] = useState([]);
const [debilitators, setDebilitators] = useState([]);
const [names, setNames] = useState([]);
const [selectedEntry, setSelectedEntry] = useState(null);


const getAssaulters = async () => {
  setLoading(true);
    let newAssaulters = [];
    try {
      for (const tokenId of tokenIds) {        
        const result = await contractBattledog.getAssaulters(tokenId);
        newAssaulters.push(result.toString());
      }
      setAssaulters(newAssaulters);
      setLoading(false);
    } catch (error) {
    console.error("Error fetching assaulters:", error);
    setLoading(true);
  };
}

const getDebilitators = async () => {
  setLoading(true);
    let newDebilitators = [];
    try {
      for (const tokenId of tokenIds) {

        const result = await contractBattledog.getDebilitators(tokenId);
        
        newDebilitators.push(result.toString()); 
      }
      setDebilitators(newDebilitators);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assaulters:", error);
      setLoading(true);
    };
};

const handleActivityTrackerSubmit = (_digit) => {
  try {
    const trimmedInput = _digit.trim(); 
    const inputTokenIds = trimmedInput.split(/[\s,]+/);
    const uniqueTokenIds = [...tokenIds];
    for (const tokenId of inputTokenIds) {
        if (!uniqueTokenIds.includes(tokenId)) {
            uniqueTokenIds.push(tokenId);
        }
      }
      setTokenIds(uniqueTokenIds);
  } catch (e) {
    console.error(e.message)
  }
};

const handleRemove = (_digit) => {
  try {
    const trimmedInput = _digit.trim(); 
    const inputTokenIds = trimmedInput.split(/[\s,]+/);
    setTokenIds(tokenIds.filter(id => !inputTokenIds.includes(id)));
  } catch (e) {
    console.error(e.message);
  }
};  

const viewAll = (e) => {
  e.preventDefault();
  handleTrackerView();
} 

const handleWipe = (e) => {
  e.preventDefault();
  try {
    const empty = [];
    setTokenIds(empty);
  } catch (e) {
    console.error(e.message);
  }
};

const handleTrackerView = () => (
  <div>
    <Desktop>
    {tokenIds.map((id, index) => (
      <div key={index}> 
        <div className="trackView" title={`Tracking Battledog ${id}`}
          style={{              
            backgroundImage: `url(https://battledogs.fun/engine/${id}.png)`,
            backgroundSize: "cover",
            color: `${blockchain.theme}`,
            border: `1px solid ${blockchain.theme}`
          }}> 
          {id} 
        </div>
      </div>
    ))}
    </Desktop>
    <Mobile>        
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      {tokenIds.map((id, index) => (
        <div key={index}> 
          <div className="trackView" title={`Tracking Battledog ${id}`}
            style={{              
              backgroundImage: `url(https://battledogs.fun/engine/${id}.png)`,
              backgroundSize: "cover",
              color: `${blockchain.theme}`,
              border: `1px solid ${blockchain.theme}`
            }}> 
            {id}
          </div>
        </div>
      ))}
      <span style={{color: "white", fontSize: "30px"}}><img src={require("./assets/images/eye.gif")} style={{height: '8vh'}} /></span>
    </div>
    </Mobile>
  </div>
); 

const getPlayerNames = async (_attacker, _defender) => {  
  try {
    const attacker = await contractBattledog.players(_attacker);
    const defender = await contractBattledog.players(_defender);

    return {
      attackerName: attacker.name,
      defenderName: defender.name,
    };
   
  } catch (error) {
    console.error("Error fetching player owners:", error);
    // throw error;
    return;
  }
};

const handleAssaulter = async (_attacker, _defender, entry) => {
  const playerNames = await getPlayerNames(_attacker, _defender);
  const namesArray = Object.values(playerNames);
  setNames(namesArray);
  setSelectedEntry(entry);
};

const handleDebilitator = async (_attacker, _defender, entry) => {
  const playerNames = await getPlayerNames(_attacker, _defender);
  const namesArray = Object.values(playerNames);
  setNames(namesArray);
  setSelectedEntry(entry);  
};

const assaultData = [];
assaulters.forEach(entry => {
  const values = entry.split(",");
  for (let i = 0; i < values.length; i += 4) {
    const timestamp = values[i+3];
    const date = new Date(timestamp * 1000);
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const assault = {
      Value: [values[i], values[i+1], values[i+2], formattedDate]
    };
    assaultData.push(assault);
  }
});

const debilitateData = [];
debilitators.forEach(entry => {
  const values = entry.split(",");
  for (let i = 0; i < values.length; i += 4) {
    const timestamp = values[i+3];
    const date = new Date(timestamp * 1000);
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const debilitate = {
      Value: [values[i], values[i+1], values[i+2], formattedDate]
    };
    debilitateData.push(debilitate);
  }
});

const mappedAssaulters = assaultData.reverse().map((assault, index) => {
  const attackerIds = assault.Value[0];
  const defenderIds = assault.Value[1];
  const stolenPoint = assault.Value[2];
  const formattedDate = moment(assault.Value[3]).format("MMMM Do YYYY");

  if (stolenPoint > 0) {

    return (
      <>
        <Desktop>
          <div key={index} className="a-format mkMicro" onClick={() => handleAssaulter(attackerIds, defenderIds, index)}>
          <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><GiZeusSword/></span> Battledog <span style={{color: `${blockchain.theme}`}}>{attackerIds}</span> attacked Battledog <span style={{color: `${blockchain.theme}`}}>{defenderIds}</span> for <span style={{color: `${blockchain.theme}`}}>{stolenPoint}</span> points <br /> 
            <span style={{color: `${blockchain.theme}`, fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span>
            {selectedEntry === index && names.length > 0 && (
              <div className="aBoard mkMicro">
                <div className="fcs">
                  <img src={`https://battledogs.fun/engine/${attackerIds}.png`} className="notifyIcon brblack" /> 
                  <img src={require("./assets/images/vs.gif")} className="vs" /> 
                  <img src={`https://battledogs.fun/engine/${defenderIds}.png`} className="notifyIcon brblack" />
                </div>
                <div style={{alignItems: "center"}} >
                  {names[0]} <span className="gtext">assaults</span> {names[1]}
                </div>
              </div>
            )}
          </div>
        </Desktop>
        <Mobile>
          <div key={index} className="a-format mkNano" onClick={() => handleAssaulter(attackerIds, defenderIds, index)}>
          <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><GiZeusSword/></span> Battledog <span style={{color: `${blockchain.theme}`}}>{attackerIds}</span> attacked Battledog <span style={{color: `${blockchain.theme}`}}>{defenderIds}</span> for <span style={{color: `${blockchain.theme}`}}>{stolenPoint}</span> points <br />
            <span style={{color: `${blockchain.theme}`, fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span> 
            {selectedEntry === index && names.length > 0 && (
              <div className="a-mobile mkNano">
                <div className="fcs">
                  <img src={`https://battledogs.fun/engine/${attackerIds}.png`} width="50%" /> 
                  <img src={require("./assets/images/vs.gif")} className="vsmobile" /> 
                  <img src={`https://battledogs.fun/engine/${defenderIds}.png`} width="50%" />
                </div>
                <div style={{alignItems: "center"}} >
                  {names[0]} <span className="">assaults</span> {names[1]}
                </div>
              </div>
            )}
          </div>
        </Mobile>
      </>     
      );
  }
});

const mappedDebilitators = debilitateData.reverse().map((debilitate, index) => {
  const attackerIds = debilitate.Value[0];
  const defenderIds = debilitate.Value[1];
  const stolenPoint = debilitate.Value[2];
  const formattedDate = moment(debilitate.Value[3]).format("MMMM Do YYYY");

  if (stolenPoint > 0) {

    return (
      <>
        <Desktop>
          <div key={index} className="d-format mkMicro" onClick={() => handleDebilitator(attackerIds, defenderIds, index)}>
            <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><Gi3dHammer /></span> Battledog <span style={{color: "cyan"}}>{attackerIds}</span> debilitated Battledog <span style={{color: "cyan"}}>{defenderIds}</span> for <span style={{color: "cyan"}}> {stolenPoint} </span> points <br />
            <span style={{color: "cyan", fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span> 
            {selectedEntry === index && names.length > 0 && (
            <div className="dBoard mkMicro" style={{color: `${blockchain.theme}`, backgroundImage: `linear-gradient(black, ${blockchain.theme}, black)`}}>
              <div className="fcs">
                <img src={(`https://battledogs.fun/engine/${attackerIds}.png`)} className="notifyIcon brblack" /> 
                <img src={require("./assets/images/vs.gif")} className="vs" /> 
                <img src={(`https://battledogs.fun/engine/${defenderIds}.png`)} className="notifyIcon brblack" />
              </div>
              <div style={{alignItems: "center"}} >
              {names[0]} <span className="gtext">debilitates</span> {names[1]}
              </div>
              
            </div>
          )}
          </div>
        </Desktop>
        <Mobile>
          <div key={index} className="d-format mkNano" onClick={() => handleDebilitator(attackerIds, defenderIds, index)}>
          <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><Gi3dHammer /></span> Battledog <span style={{color: "cyan"}}>{attackerIds}</span> debilitated Battledog <span style={{color: "cyan"}}>{defenderIds}</span> for <span style={{color: "cyan"}}> {stolenPoint} </span> points <br /> 
            <span style={{color: "cyan", fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span> 
            {selectedEntry === index && names.length > 0 && (
            <div className="d-mobile mkNano"  style={{color: `${blockchain.theme}`, backgroundImage: `linear-gradient(black, ${blockchain.theme}, black)`}}>
              <div className="fcs">
                <img src={(`https://battledogs.fun/engine/${attackerIds}.png`)} width="50%" /> 
                <img src={require("./assets/images/vs.gif")} className="vsmobile" /> 
                <img src={(`https://battledogs.fun/engine/${defenderIds}.png`)} width="50%" />
              </div>
              <div style={{alignItems: "center"}} >
              {names[0]} <span className="gtext">debilitates</span> {names[1]}
              </div>
              
            </div>
          )}
          </div>

        </Mobile>
      </>      
    );
  }
});

useEffect(() => {
  if (isStored) return;
  const storedTokenIds = localStorage.getItem("tokenIds");
  if (storedTokenIds) {
      isStored = true;
    setTokenIds(JSON.parse(storedTokenIds));
      isStored = false;
  }
}, []);

useEffect(() => {
  if(isSetting) return;
    isSetting = true;
  localStorage.setItem("tokenIds", JSON.stringify(tokenIds));
    isSetting = false;
}, [tokenIds]);


// Component functions*******************************************************************************************************
  
const welcomeGif = () => {
  return (
    <div>
      <Desktop>        
        <div className="centerAlign" align='center'>
              <img src={require(`./assets/images/connected.gif`)} className="connectedGif" style={{height: "10vh", width: "80vw"}}/> 
        </div>
      </Desktop>
      <Mobile>                          
        <div className="centerAlign" align='center'>
            <img src={require(`./assets/images/connected.gif`)} className="connectedGif" style={{height: "10vw", width: "80vw"}}/>
        </div>
      </Mobile>
    </div>   
  )
};

const ApproveNative = () => {  
  useEffect(() => {
    if (isWaiting && transactionResult) {
      approveNativeAfterCall();
      } 
    else if (isWaiting && txError) {
      if (isApproveNative) return;
      isApproveNative = true;
      setErrorMessage("Request Cancelled.");
      setErrorMessageVisible(true);
      setIsWaiting(false);
      isApproveNative = false;
    }
  },[isWaiting]);
  return (
    <>
      <Desktop>      
      <div className="menu4" style={{color: `${blockchain.theme}`}}>
      <div >
      <div>
      <div title={`Approve Access to ${blockchain.tokenA} tokens`}>
        <img src={require("./assets/images/icons/approval.png")} className={`permissionicons loadbearer${isWaiting}`}  
        onClick={() => {setShowSwap(false); handleShowForm()}} /></div>
      </div>
              {showForm && !showSwap && (
                <form  onSubmit={(e) => handleSubmitter(e, approve)} className={`loadbearer${isWaiting}`}>
                <div>
                  <div onClick={handleFocus}>
                    <input autoFocus={focusInput} name="input1"
                      className="inputform"
                      style={{ color: "white", border: "3px solid goldenrod" }}
                      type="text"                          
                      value={approval}
                      onChange={handleApprovalChange}
                      placeholder={`...best over ${blockchain.bestover} ${blockchain.tokenA} tokens`}
                      alt="Approval Amount"
                    />
                  </div>
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {setFocusInput(false)}}>Submit</button>
                  <button className={"button0"} type="button" onClick={() => {setFocusInput(false); handleHideForm()}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                </form>
              )}
        </div>           
      {blockchain.wrap && (
        <>
          <Wrapper />
        </>
      )}
      <div className=" box3" title={`Revoke Access to your ${blockchain.tokenA} tokens`} >
      <img src={require("./assets/images/icons/revoke.png")} className={`permissionicons loadbearer${isWaiting}`} onClick={revoke} /></div><br /> 
      </div>
      </Desktop>
      <Mobile>
        <div className="spinner" onMouseEnter={handleMouseEnter} align='center' style={{marginTop: "6vh"}}>
        <img src={require("./assets/images/icons/approval.png")} style={{height: "40px"}} 
          onClick={() => {setShowSwap(false); handleShowForm()}} title={`Approve Access to ${blockchain.tokenA} tokens`} className={`loadbearer${isWaiting}`}/>
        <img src={require("./assets/images/icons/revoke.png")}  style={{height: "40px"}} title={`Revoke Access to your ${blockchain.tokenA} tokens`} 
          onClick={revoke} className={`loadbearer${isWaiting}`}/>            
        {blockchain.wrap && (
            <>
              <Wrapper />
            </>
        )}    
              {showForm && !showSwap && (
                <form  onSubmit={(e) => handleSubmitter(e, approve)} className={`loadbearer${isWaiting}`}>
                  <div>
                    <div onClick={handleFocus}>
                      <input autoFocus={focusInput}  name="input1"
                        className={"inputformMb"}
                        style={{ color: "white", border: "3px solid goldenrod" }}
                        type="number"                          
                        value={approval}
                        onChange={handleApprovalChange}
                        placeholder={`...best over ${blockchain.bestover} ${blockchain.tokenA} tokens`}
                        alt="Approval Amount"
                      />
                    </div>
                  </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {setFocusInput(false)}}>Submit</button>
                  <button className={"button0"} type="button" onClick={() => {setFocusInput(false); handleHideForm()}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                </form>
              )}
            </div>
      </Mobile>
    </>
  )
};

const handleSubmitter = async (e, func) => {
  e.preventDefault();
  setLoading(true);
  const digit = e.target.input1.value;
  if (!digit) {
    return;
  }

  try {  
  await func(digit); 
  setShowForm(false); 
  } catch (err) {
    console.error(err);
  }
  setLoading(false);
  setShowForm(false); 
};

const ApproveGame = () => {
  useEffect(() => {
    if (isWaiting && transactionResult) { 
    if (isApproveGame) return;
          isApproveGame = true;
        setErrorMessage("Approved GAME!");
        setErrorMessageVisible(true);
          isApproveGame = false;
          
        setIsWaiting(false);
      }
  },[isWaiting]);
  return (
    <>
      <Desktop>
          <div className="menu4" style={{color: `${blockchain.theme}`}}>      
              <div >
                <div onClick={handleShowForm}>
                  <div className="" title="Approve Access to GAME Tokens">
                    <img src={require("./assets/images/icons/approval.png")} className={`permissionicons loadbearer${isWaiting}`}  disabled={isWaiting ? 1 : 0}/>
                  </div>
                </div>
                  {showForm && (
                    <form  onSubmit={(e) => handleSubmitter(e, approveGame)} className={`loadbearer${isWaiting}`}>
                      <div>
                        <div onClick={handleFocus}>
                          <input autoFocus={focusInput} name="input1"
                            className="inputform"
                            style={{ color: "white", border: "3px solid goldenrod" }}
                            type="text"                          
                            value={approval}
                            onChange={handleApprovalChange}
                            placeholder="Approve GAME to Level Up"
                            alt="Approval Amount"
                          />
                        </div>
                      </div>
                      <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {setFocusInput(false)}}>Submit</button>
                      <button className={"button0"} type="button" onClick={() => {handleHideForm(); setFocusInput(false)}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                    </form>
                  )}
              </div>
                <br />
              <div className="box3" title="Revoke Access to GAME tokens" >
              <img src={require("./assets/images/icons/revoke.png")} className={`permissionicons loadbearer${isWaiting}`} onClick={revokeGame} disabled={isWaiting ? 1 : 0}/><br /> 
              </div>
          </div>
      </Desktop>
      <Mobile>
        <div className='mobileapprove' align="center">
          <div className="spinner">
            <img src={require("./assets/images/icons/approval.png")} style={{height: "40px"}} title="Approve Access to your GAME tokens" onClick={handleShowForm} className={`loadbearer${isWaiting}`} disabled={isWaiting ? 1 : 0}/> 
            <img src={require("./assets/images/icons/revoke.png")} style={{height: "40px"}} title="Revoke Access to your GAME tokens" onClick={revokeGame} className={`loadbearer${isWaiting}`} disabled={isWaiting ? 1 : 0}/>
            {showForm && (
              <form  onSubmit={(e) => handleSubmitter(e, approveGame)} className={`loadbearer${isWaiting}`} >
                <div>
                  <div onClick={handleFocus}>
                    <input autoFocus={focusInput} name="input1"
                      className="inputformMb"
                      style={{ color: "white", border: "3px solid goldenrod" }}
                      type="number"                          
                      value={approval}
                      onChange={handleApprovalChange}
                      placeholder="Approve GAME to Level Up"
                      alt="Approval Amount"
                      />
                  </div>
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}} onBlur={() => {setFocusInput(false)}}>Submit</button>
                  <button className={"button0"} type="button" onClick={() => {handleHideForm(); setFocusInput(false)}} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
              </form>
            )}
          </div>
          </div>
      </Mobile>
    </>
  )
};

const StartMenuComponent = () => {
  return (
    <>
      <Desktop> 
        <div className="alignCenter">
            <div className={`startMenu smbkg${startMenuBkg}`} align="center">
              <button className="startMenuBox box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0); setErrorMessage("View Your Battledogs"); setErrorMessageVisible(true)}} onClick={handleArsenal}><span >Arsenal</span></button>
              <button className="startMenuBox box2bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7); setErrorMessage("Get Started"); setErrorMessageVisible(true)}} onClick={handleStart}><span >Start</span></button>
              <button className="startMenuBox box3bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(2); setErrorMessage("Visit The Arena"); setErrorMessageVisible(true)}} onClick={handleArena}><span >Arena</span></button>
              <button className="startMenuBox box4bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3); setErrorMessage("Mint a Battledog"); setErrorMessageVisible(true)}} onClick={handleMint}><span >Quick Mint</span></button> 
              <button className="startMenuBox box7bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(14); setErrorMessage("Find other Battledogs"); setErrorMessageVisible(true)}} onClick={handleScoreboard}><span >Score board</span></button>
              <button className="startMenuBox box5bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4); setErrorMessage("Manage Your Wins"); setErrorMessageVisible(true)}} onClick={handleWinnings}><span >Winnings</span></button>
              <button className="startMenuBox box6bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5); setErrorMessage("Harvest, Mine or Customize"); setErrorMessageVisible(true)}} onClick={handleTools}><span >Tools</span></button>
              <button className="startMenuBox box8bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(18); setErrorMessage("Track Another Battledog"); setErrorMessageVisible(true)}} onClick={handleActivityTracker}><span >Activity Tracker</span></button>
              <button className="startMenuBox box9bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(21); setErrorMessage("See Battling Klans"); setErrorMessageVisible(true)}} onClick={handleHouseboard}><span >Klans</span></button>
            </div>
            
            <div className="expressMenu" align="center">
              <button className="expressMenuBox box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0); setErrorMessage("View Battledogs"); setErrorMessageVisible(true)}} onClick={handleArsenal}><span className="mkAtom twhite">Arsenal</span></button>
              <button className="expressMenuBox mainBackground4" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7); setErrorMessage("Activate a Battledog"); setErrorMessageVisible(true)}} onClick={handleActivateHelp}><span className="mkAtom twhite">Activate Up</span></button>
              <button className="expressMenuBox mainBackground9" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11); setErrorMessage("Regenerate a Battledog"); setErrorMessageVisible(true)}} onClick={handleRegenerateHelp}><span className="mkAtom twhite">Regenerate</span></button>
              <button className="expressMenuBox mainBackground8" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(10); setErrorMessage("Weaponize a Battledog"); setErrorMessageVisible(true)}} onClick={handleWeaponizeHelp}><span className="mkAtom twhite">Weaponize</span></button>
              <button className="expressMenuBox mainBackground5" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3); setErrorMessage("Assault a Battledog"); setErrorMessageVisible(true)}} onClick={handleAssaultHelp}><span className="mkAtom twhite">Assault</span></button>
              <button className="expressMenuBox mainBackground6" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(8); setErrorMessage("Debilitate a Battledog"); setErrorMessageVisible(true)}} onClick={handleDebilitateHelp}><span className="mkAtom twhite">Debilitate</span></button>
              <button className="expressMenuBox mainBackground1" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(15); setErrorMessage(`Harvest ${blockchain.tokenA}`); setErrorMessageVisible(true)}} onClick={handleHarvester}><span className="mkAtom twhite">Harvest</span></button>
              <button className="expressMenuBox mainBackground15" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(16); setErrorMessage("Mine GAME"); setErrorMessageVisible(true)}} onClick={handleMining}><span className="mkAtom twhite">Mining</span></button>
              <button className="expressMenuBox mainBackground12" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(6); setErrorMessage("Level Up a Battledog"); setErrorMessageVisible(true)}} onClick={handleLevelUpHelp}><span className="mkAtom twhite">Level Up</span></button>
              <button className="expressMenuBox mainBackground10" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(12); setErrorMessage("Claim An Assault Win"); setErrorMessageVisible(true)}} onClick={handleClaimAssaultHelp}><span className="mkAtom twhite">Claim Assault</span></button>
              <button className="expressMenuBox mainBackground11" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(13); setErrorMessage("Claim A Debilitate Win"); setErrorMessageVisible(true)}} onClick={handleClaimDebilitateHelp}><span className="mkAtom twhite">Claim Debilitate</span></button>
              <button className="expressMenuBox mainBackground7" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4); setErrorMessage(`Cashout ${blockchain.tokenA}`); setErrorMessageVisible(true)}} onClick={handlePayoutHelp}><span className="mkAtom twhite">Cashout</span></button>
              <button className="expressMenuBox mainBackground13" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11); setErrorMessage("Customize a Battledog"); setErrorMessageVisible(true)}} onClick={handleCustomizeHelp}><span className="mkAtom twhite">Customize</span></button>
              <button className="expressMenuBox mainBackground16" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5); setErrorMessage("Reset Lock Timer"); setErrorMessageVisible(true)}} onClick={handleResetTimerHelp}><span className="mkAtom twhite">Reset Timer</span></button>
            </div>
        </div>
            <div align='center' style={{position: 'absolute', bottom: '0.1vh', width: '100vw', margin: '2vh 0vw 0vh 0vw'}}>
            {errorMessageVisible && 
                    <div className="errordisplay mkMid" style={{color: `${blockchain.theme}`}}>{errorMessage}</div> 
            }
            </div>
      </Desktop>
      <Mobile>
       <div align='center'>
            <div className={`startMenuMobile smbkg${startMenuBkg}`} align="center" style={{backgroundColor: `whitesmoke`}}>
              <button className="startMenuBoxMobile box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0); setErrorMessage("View Your Battledogs"); setErrorMessageVisible(true)}} onClick={handleArsenal}><span className="mkNano twhite">Arsenal</span></button>
              <button className="startMenuBoxMobile box2bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7); setErrorMessage("Get Started"); setErrorMessageVisible(true)}} onClick={handleStart}><span className="mkNano twhite">Start</span></button>
              <button className="startMenuBoxMobile box3bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(2); setErrorMessage("Visit The Arena"); setErrorMessageVisible(true)}} onClick={handleArena}><span className="mkNano twhite">Arena</span></button>
              <button className="startMenuBoxMobile box4bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3); setErrorMessage("Mint a Battledog"); setErrorMessageVisible(true)}} onClick={handleMint}><span className="mkNano twhite">Quick Mint</span></button> 
              <button className="startMenuBoxMobile box7bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(14); setErrorMessage("Find other Battledogs"); setErrorMessageVisible(true)}} onClick={handleScoreboard}><span className="mkNano twhite">Score board</span></button>
              <button className="startMenuBoxMobile box5bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4); setErrorMessage("Manage Your Wins"); setErrorMessageVisible(true)}} onClick={handleWinnings}><span className="mkNano twhite">Winnings</span></button>
              <button className="startMenuBoxMobile box6bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5); setErrorMessage("Harvest, Mine or Customize"); setErrorMessageVisible(true)}} onClick={handleTools}><span className="mkNano twhite">Tools</span></button>
              <button className="startMenuBoxMobile box8bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(18); setErrorMessage("Track Another Battledog"); setErrorMessageVisible(true)}} onClick={handleActivityTracker}><span className="mkNano twhite">Activity Tracker</span></button>
              <button className="startMenuBoxMobile box9bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(21); setErrorMessage("See Battling Klans"); setErrorMessageVisible(true)}} onClick={handleHouseboard}><span className="mkNano twhite">Klans</span></button>
            </div>
            <div className="mkMicro tblack" align="center">Quick Menu</div>
            <div className="expressMenuMobile" align="center">
              <button className="expressMenuBoxMobile box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0)}} onClick={handleArsenal}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Arsenal</span></button>
              <button className="expressMenuBoxMobile mainBackground4" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7)}} onClick={handleActivateHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Activate Up</span></button>
              <button className="expressMenuBoxMobile mainBackground9" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11)}} onClick={handleDebilitateHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Regenerate</span></button>
              <button className="expressMenuBoxMobile mainBackground8" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(10)}} onClick={handleWeaponizeHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Weaponize</span></button>
              <button className="expressMenuBoxMobile mainBackground5" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3)}} onClick={handleAssaultHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Assault</span></button>
              <button className="expressMenuBoxMobile mainBackground6" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(8)}} onClick={handleDebilitateHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Debilitate</span></button>
              <button className="expressMenuBoxMobile mainBackground1" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(15)}} onClick={handleHarvester}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Harvest</span></button>
              <button className="expressMenuBoxMobile mainBackground15" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(16)}} onClick={handleMining}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Mining</span></button>
              <button className="expressMenuBoxMobile mainBackground12" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(6)}} onClick={handleLevelUpHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Level Up</span></button>
              <button className="expressMenuBoxMobile mainBackground10" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(12)}} onClick={handleClaimAssaultHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Claim Assault</span></button>
              <button className="expressMenuBoxMobile mainBackground11" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(13)}} onClick={handleClaimDebilitateHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Claim Debilitate</span></button>
              <button className="expressMenuBoxMobile mainBackground7" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4)}} onClick={handlePayoutHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Cashout</span></button>
              <button className="expressMenuBoxMobile mainBackground13" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11)}} onClick={handleCustomizeHelp}><span className="mkMolecule tblack" style={{backgroundColor: `${blockchain.theme}`}}>Customize</span></button>
              <button className="expressMenuBoxMobile mainBackground16" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5)}} onClick={handleResetTimerHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Reset Timer</span></button>
            </div>
       </div>
      </Mobile>
    </>   
  )
} 

  const LoginMenuComponent = () => {
    return (
      <>
        <Desktop>        
          <div className="centerAlign" align='center'>
            {submitText !== null ? (
              <>
                <div className="mkSmall">PLAY ON< br />
                    <img src={require(`./assets/images/left_arrow.png`)} className={`arrowSelectorLeft leftOff${counter}`} onClick={dropNetwork} style={{height: "10vh"}}/>                  
                      <span className="mkLarge hub-title" style={{
                          background: `linear-gradient(40deg, black 0%, black 45%, ${blockchain.theme} 50%, black 55%, black 100%)`,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        }}>  {blockchain.ecosystem} </span>          
                    <img src={require(`./assets/images/right_arrow.png`)} className={`arrowSelectorRight rightOff${counter}`} onClick={plusNetwork} style={{height: "10vh"}}/>
                    {blockchain.testnet && (<div className="mkNano">Is Currently on Testnet</div>)}
                </div>
                {blockchain.testnet && (
                  <div style={{position: "fixed", bottom: "2vh"}}><img src={require(`./assets/images/robot.gif`)} style={{height: "25vh"}}/></div>
                )}
              </>
            ) : (                  
              <div className="mkLarge hub-title" style={{
                  background: `linear-gradient(40deg, black 0%, black 45%, whitesmoke 50%, black 55%, black 100%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}>  Loading... </div> 
            )}
          </div>
        </Desktop>
        <Mobile>                          
          <div className="centerAlign" align='center'>
            {submitText !== null ? (
              <>
              <div className="mkSmall" style={{alignItems: "center", justifyContent: "center"}}>PLAY ON< br />           
                <span className="mkMedium hub-title" 
                  style={{
                    background: `linear-gradient(40deg, black 0%, black 45%, ${blockchain.theme} 50%, black 55%, black 100%)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}> {blockchain.ecosystem}</span><br />  
                {blockchain.testnet && (<div className="mkNano" style={{margin: "0vh 0vw 1vh 0vw" }}>Is Currently on Testnet</div>)}
                <img src={require(`./assets/images/left_arrow.png`)} className={`arrowSelectorLeft leftOff${counter}`} onClick={dropNetwork} style={{height: "10vw", margin: "0vw 1vh 0vw 1vh", backgroundColor: "goldenrod"}}/>
                <img src={require(`./assets/images/right_arrow.png`)} className={`arrowSelectorRight rightOff${counter}`} onClick={plusNetwork} style={{height: "10vw", margin: "0vw 1vh 0vw 1vh", backgroundColor: "goldenrod"}}/>
              </div>            
              {blockchain.testnet && (
                    <div style={{position: "fixed", bottom: "4vh"}}><img src={require(`./assets/images/robot.gif`)} style={{height: "15vh"}}/></div>
              )}
              </>
            ):(
              <>
                <span className="mkMedium hub-title" 
                style={{
                  background: `linear-gradient(40deg, black 0%, black 45%, whitesmoke 50%, black 55%, black 100%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}> Loading... </span>
              </>
            )}
          </div>
        </Mobile>
      </>   
    );
  };

  const ActivateComponent = () => {
      useEffect(()=> {
        if (loading && transactionResult) {
          activateAfterCall();         
        } else if (loading && txError) {
          if (isActivate) return;
            isActivate = true;
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
            isActivate = false;
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(3)}} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(1)}} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} className="icons" /></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
            <div className={"translation"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }} align="center" >
              
              {!attackerId && (
                <img src={require("./assets/images/activate.jpg")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )} 
              
              <div>
                  <div style={{ padding: "2px" }}>
                    <div onClick={handleFocus}>
                      <input autoFocus={focusInput}
                      className={"inputform twhite"}
                      style={{ border: "3px solid rgba(240, 240, 192, 0.7)" }}
                      placeholder="Battledog ID"
                      type="text"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Battledog ID"
                      />
                    </div>

                    {errorMessageVisible && 
                    <div className="errordisplay thatch" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                    </div> } <br />

                    <button
                      className={"buttongold textstyle"}
                      onClick={(e) => {setFocusInput(false); handleActivateUpdateClick(e)}}
                      disabled={loading ? 1 : 0}
                      style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                    >
                      {loading ? (<>Activating...</>) : (<>Activate</>)}  
                      
                    </button>
                </div>
              </div>

              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/icons/startreturn.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} style={{height: "50px"}} /></div><br />                
                <div onMouseEnter={handleMouseEnter} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} style={{height: "50px"}} /></div><br />
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                  {!attackerId && (
                    <img src={require("./assets/images/activate.jpg")} className="portraitHTHMobile"/>
                  )} 
                  {attackerId && (
                    <img src={`${TargetA}`} className="portraitHTHMobile"/>
                  )} 
                  <div onClick={handleFocus}>
                    <input autoFocus={focusInput}
                      className={"inputformMb twhite"}
                      style={{ border: "3px solid rgba(240, 240, 192, 0.7)" }}
                      placeholder="Battledog ID"
                      type="number"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Battledog ID"
                      />
                    </div>
                    
                    {errorMessageVisible && 
                    <div className="errordisplay thatch" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                    </div> } 

                    <button
                      className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {setFocusInput(false); handleActivateUpdateClick(e)}}
                      style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                    >
                    {loading ? (<>Activating...</>) : (<>Activate</>)}                        
                    </button>
                    
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  const ArsenalComponent = () => {    
    return (
      <>
        <Desktop> 
          <div className={`menu2 smbkg${startMenuBkg}`} >
            <div onMouseEnter={handleMouseEnter} title="Return to Home" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div><br />
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(2)}} title="Go To Arena" onClick={handleArena}><img src={require("./assets/images/icons/gameplay.png")} className="icons" /></div><br />
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title="Manage Winnings" onClick={handleWinnings}><img src={require("./assets/images/icons/manage.png")} className="icons" /></div><br />
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Toolspace" onClick={handleTools}><img src={require("./assets/images/icons/tools.png")} className="icons" /></div><br /> 
            <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
          </div>              
                          
            {allOwnerOfPlayers.length === 0 ? (
              <div className='arsenalDiv' style={{marginTop: "15%", alignItems: "center", justifyContent: "center"}}>
                <div style={{ color: "gold", fontSize: "20px" }} align={"center"}>
                  <img src={require(`./assets/images/progressbar.gif`)} style={{ height: "25px" }} />
                  <br/>
                  <img src={require(`./assets/images/nobdgs-${blockchain.theme}.gif`)} style={{ height: "55px" }} />
                  <br />
                  <p className={"linkbutton twhite"} onClick={handleMint}>Mint Your Battledog</p>                  
                    
                  {errorMessageVisible && 
                    <div className="errordisplay" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "25px" }} />
                    </div> 
                  } <br />

                </div>
              </div>
            ):(
              <div>
                <div className={"nftloader"}> 
                  {allOwnerOfPlayers.map((player, index) => {
                    return (
                      <div key={index} className={"texttheme"} style={{color: `${blockchain.theme}`}} onClick={() => {setAttackerId((player.id).toString())}}>
                        <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"} 
                          onClick={() => handleSelectedPlayerClick(player.id)} />
                        <br />
                        BID: {(player.id).toString()}
                      </div>
                    );}
                 )}
                </div>
              </div>
            )}
            {selectedPlayer && allOwnerOfPlayers && (
                <div className={"detailLoader"}> 
                    <div className={"texttheme black"}>
                      <img src={(`https://battledogs.fun/engine/${selectedPlayer}.png`)} className={"largeNFT"} />
                      <div className="namenotification namegtext">{allOwnerOfPlayers.find(p => p.id === selectedPlayer).name}</div>

                      <div className="featurespanel">
                        <div className="topfeature tpgtext">
                          {playerData.map((data, index) => (
                            <div key={index} >{currentIndex === index && data.trait_type}</div>
                          ))}
                        </div>
                        <div className={`bottomfeature bp${currentIndex}gtext`}>
                          {playerData.map((data, index) => (
                            <div key={index}>{currentIndex === index && data.value}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                </div>
            )}          
            {selectedPlayer && reservePlayerData && (
                <div className='onchainData' align="center">                
                  <div onClick={handleMint} title="Click to Mint a New Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Battledog ID</span><br/>
                    <span className='divgtext'>{selectedPlayer.toString()}</span>
                  </div>
                  <div onClick={handleLevelUpHelp} title="Click to Claim A New Level" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Current Level</span><br/>
                    <span className='divgtext'>{reservePlayerData[0]}</span>
                  </div>
                  <div onClick={handleAssaultHelp} title="Click to Assault a Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Aggression</span><br/>
                  <span className='divgtext'>{reservePlayerData[1]}</span>
                  </div>
                  <div onClick={handleDebilitateHelp} title="Click to Debilitate a Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Stamina</span><br/>
                  <span className='divgtext'>{reservePlayerData[2]}</span>
                  </div>
                  <div title="Number of Unrewarded Battles" className='fitiem'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Current Fights</span><br/>
                  <span className='divgtext'>{reservePlayerData[3]}</span> 
                  </div>
                  <div title="Number of Claimed Wins" className='fitiem'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Total Wins</span><br/>
                  <span className='divgtext'>{reservePlayerData[4]}</span>
                  </div>
                  <div onClick={handlePayoutHelp} title={`You have ${((reservePlayerData[5])/reducer).toFixed(blockchain.tofixed)}${blockchain.excess} ${blockchain.tokenA} awaiting payout`} className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Pending Payout</span><br/>
                  <span className='divgtext'>{((reservePlayerData[5])/reducer).toFixed(blockchain.tofixed)}</span>
                  </div>
                  <div onClick={handleActivateHelp} title="Click to Activate a Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Activation Tier</span><br/> 
                  <span className='divgtext'>{reservePlayerData[6]}</span>
                  </div>
                  <div title="Total Number of Fights" className='fitiem'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Fight History</span><br/>
                  <span className='divgtext'>{reservePlayerData[7]}</span>
                  </div>
                </div>
                )} 
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>            
                  <div title="Return to Home" onClick={handleStartMenu}><img src={require("./assets/images/icons/home.png")} style={{height: "50px"}}/></div><br />
                  <div title="Go To Arena" onClick={handleArena}><img src={require("./assets/images/icons/gameplay.png")} style={{height: "50px"}} /></div><br />
                  <div title="Manage Winnings" onClick={handleWinnings}><img src={require("./assets/images/icons/manage.png")} style={{height: "50px"}} /></div><br />
                  <div title="Toolspace" onClick={handleTools}><img src={require("./assets/images/icons/tools.png")} style={{height: "50px"}} /></div><br />                     
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"><img src={require("./assets/images/icons/guidebook.gif")} height="50px" /></a> </div>
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>    
                <div> 
                      {allOwnerOfPlayers.length === 0 ? (
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
                          <div style={{ color: "gold", fontSize: "20px" }} align="center">
                            <img src={require(`./assets/images/progressbar.gif`)} style={{ height: "30px" }} />
                            <br/>
                            <img src={require(`./assets/images/nobdgs-${blockchain.theme}.gif`)} style={{ height: "50px" }} />
                            <br />
                            <div onClick={handleMint} className={"linkbutton twhite"}>Mint Your Battledog</div>            
                    
                            {errorMessageVisible && 
                              <div className="errordisplay" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                              <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                              </div> 
                            } <br />

                          </div>
                        </div>
                      ) : (
                        <div>
                          <div />
                          <div className={"nftfloater"}>
                            {allOwnerOfPlayers.map((player, index) => {
                              return (
                                <div key={index} className={"texttheme tgold"} style={{color: `${blockchain.theme}`}} onClick={() => {setAttackerId((player.id).toString())}}>
                                  <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImageMB"} 
                                  onClick={() => handleSelectedPlayerClick(player.id)} style={{border: `0.5vw solid ${blockchain.theme}`}}/>
                                  <br />
                                  BID: {(player.id).toString()}
                                </div>
                              );
                            })}
                          </div>
                          <div className='nftcarriage'>
                            {selectedPlayer && allOwnerOfPlayers &&
                              <div className={"texttheme black"}>
                                <img src={(`https://battledogs.fun/engine/${selectedPlayer}.png`)} 
                                className={"largeNFTmb"}/>
                                <div className="nftnamer namegtext">{allOwnerOfPlayers.find(p => p.id === selectedPlayer).name}</div>

                              </div>
                            }
                          </div>
                        </div>
                      )}  
                      </div>            
                      {selectedPlayer && reservePlayerData && (
                        <div className="detaildivmobile" align="center">          
                          <div className='mblb'>
                            <span className='mkNano twhite' onClick={handleMint} style={{color: `${blockchain.theme}`}}>BID</span><br/>
                            <span className='divgtext'>{selectedPlayer.toString()}</span>
                          </div>
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Fights</span><br/>
                            <span className='divgtext'>{reservePlayerData[3]}</span>
                          </div>
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Wins</span><br/>
                            <span className='divgtext'>{reservePlayerData[4]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' onClick={handleLevelUpHelp} style={{color: `${blockchain.theme}`}}>Level</span><br/>
                            <span className='divgtext'>{reservePlayerData[0]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' onClick={handleAssaultHelp} style={{color: `${blockchain.theme}`}}>Aggression</span><br/>
                            <span className='divgtext'>{reservePlayerData[1]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' onClick={handlePayoutHelp} style={{color: `${blockchain.theme}`}}>Payout</span><br/>
                            <span className='divgtext'>{(reservePlayerData[5]/reducer).toFixed(blockchain.tofixed)}${blockchain.excess}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' onClick={handleActivateHelp} style={{color: `${blockchain.theme}`}}>Activation</span><br/>
                            <span className='divgtext'>{reservePlayerData[6]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' onClick={handleDebilitateHelp} style={{color: `${blockchain.theme}`}}>Stamina</span><br/>
                            <span className='divgtext'>{reservePlayerData[2]}</span>
                          </div>
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>History</span><br/>
                            <span className='divgtext'>{reservePlayerData[7]}</span>
                          </div>
                        </div>
                      )}
                      {selectedPlayer && (
                        <div className="mobilelayer">
                          <div className="featurespanelMB">
                          <div className="topfeature tpgtext">
                            {playerData.map((data, index) => (
                              <div key={index} >{currentIndex === index && data.trait_type}</div>
                            ))}
                          </div>
                          <div className={`bottomfeature bp${currentIndex}gtext`}>
                              {playerData.map((data, index) => (
                                <div key={index}>{currentIndex === index && data.value}</div>
                              ))}
                          </div>
                          </div> 
                        </div>
                      )}
              </div> 
        </Mobile>
      </>   
    )
  } 
    
  const AssaultComponent = () => {
      useEffect(()=> {
        if (loading && transactionResult) {
            assaultAfterCall();         
        } else if (loading && txError) {
          if (isAssault) return; 
            isAssault = true;   
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
            isAssault = false;
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
              <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">                
              {showImage && (
                  <img src={require("./assets/images/assaulter.gif")} className="portraitHTH" />
                )}   
                {!showImage && !defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box", border: "3px solid darkred"}}>
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box", border: "3px solid darkred"}}>
                  <img src={require("./assets/images/assaultarget.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}     
                
              <div style={{padding: "2px"}}>
                <div onClick={() => {handleFocus(); setFocusInput2(false)}}>
                  <input autoFocus={focusInput} className={"inputform twhite"}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                </div>
              </div>
              
              <div onClick={() => {handleFocus2(); setFocusInput(false)}}>
                  <input autoFocus={focusInput2} className={"inputform twhite"} style={{marginTop: "0px", border: "3px solid darkred"}}
                    placeholder=" Enemy ID"
                    type="text"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Insert Enemy ID"
                  />
              </div>           
                    
              {errorMessageVisible && 
                <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                </div> 
              }

              <button className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); setFocusInput2(false); handleAssaultUpdateClick(e)}}          
                style={{backgroundColor: 'red' }}
              >
              {loading ? (<>Assaulting...</>) : (<>Assault</>)} 
                
              </button>

              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent' align="center">
                {showImage && (
                  <img src={require("./assets/images/assaulter.gif")} className="portraitHTHMobile"/>
                )}  
                {!showImage && !defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/assaultarget.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                <div onClick={() => {handleFocus(); setFocusInput2(false)}}>
                  <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid darkred"}}
                    placeholder="Your Battledog ID"
                    type="number"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                </div>
                <div onClick={() => {handleFocus2(); setFocusInput(false)}}>
                  <input autoFocus={focusInput2} className={"inputformMb twhite"} style={{border: "3px solid darkred", marginTop: "0px"}}
                  placeholder=" Enemy ID"
                  type="number"
                  value={defenderId}
                  onChange={handleDefenderChange}
                  alt="Insert Enemy ID"
                  />  
                </div>      
                    
                  {errorMessageVisible && 
                    <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                    </div> 
                  }
    
                    <button className={"buttongold textstyle"}
                    disabled={loading ? 1 : 0}
                    onClick={(e) => {setFocusInput(false); setFocusInput2(false); handleAssaultUpdateClick(e)}}
                    style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                  >
                  {loading ? (<>Assaulting...</>) : (<>Assault</>)} 
                  </button>          
                 
                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const ClaimAssaultComponent = () => {    
      useEffect(()=> {
        if (loading && transactionResult) {
            claimAssaultAfterCall();
        } else if (loading && txError) {
          if (isClaimAssault) return;
            isClaimAssault = true;
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
            isClaimAssault = false;
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
            <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
                  {!defenderId && (
                    <img src={require("./assets/images/claimassault.png")} className="portraitHTH" />
                  )} 
                  {defenderId && (
                    <img src={`${TargetA}`} className="portraitHTH" />
                  )}           
                <div>
                  <div> 
                    <div onClick={handleFocus}>         
                      <input autoFocus={focusInput} className={"inputform twhite"} style={{border: "3px solid rgb(139, 102, 8)"}}
                        placeholder="Your Battledog ID"
                        type="text"
                        value={attackerId}
                        onChange={handleAttackerChange}
                        alt="Your Battledog ID"
                      />
                    </div> 
                  </div> 
                </div>        
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tgold" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }

                  <button className={"button1 textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={(e) => {setFocusInput(false); handleClaimAssaultUpdateClick(e)}}
                  style={{
                    border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Claiming...</>) : (<>Claim Assault</>)} 
                </button>
            </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                  {!defenderId && (
                    <img src={require("./assets/images/claimassault.png")} className="portraitHTH" />
                  )} 
                  {defenderId && (
                    <img src={`${TargetA}`} className="portraitHTHMobile"/>
                  )} 
                  <div>     
                    <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid rgb(139, 102, 8)"}}
                    placeholder="Your Battledog ID"
                    type="number"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Your Battledog ID"
                    />         
                  </div> 
                    {errorMessageVisible && 
                      <div className="errordisplay tgold" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }    
                    
                    <button className={"button1 textstyle"}
                    disabled={loading ? 1 : 0}
                    onClick={(e) => {setFocusInput(false); handleClaimAssaultUpdateClick(e)}}
                    style={{
                      border: `2px solid ${blockchain.theme}`}}>
                    {loading ? (<>Claiming...</>) : (<>Claim Assault</>)} 
                    </button>
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  const ClaimDebilitateComponent = () => { 
      useEffect(()=> {
        if (loading && transactionResult) {
          claimDebilitateAfterCall();
      } else if (loading && txError) {
        if (isClaimDebilitate) return;
          isClaimDebilitate = true;
        setErrorMessage("Request Cancelled.");
        setErrorMessageVisible(true);
        setLoading(false);
          isClaimDebilitate = false;
      }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
          <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!defenderId && (
                <img src={require("./assets/images/claimdb.png")} className="portraitHTH" />
              )} 
              {defenderId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}                
              <div>
                <div onClick={handleFocus}>
                  <input autoFocus={focusInput} className={"inputform twhite"} style={{border: "3px solid rgb(74, 99, 108)"}}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                </div>
              </div>    
                    
              {errorMessageVisible && 
               <div className="errordisplay talmond" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
               <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
               </div> 
              }

                <button className={"button1 textstyle"}
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); handleClaimDebilitateUpdateClick(e)}}
                style={{
                  border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Claiming...</>) : (<>Claim Debilitate</>)}
              </button>
          </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent'>
                    {!defenderId && (
                      <img src={require("./assets/images/claimdb.png")} className="portraitHTHMobile" />
                    )} 
                    {defenderId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile" />
                    )}  
                    <div onClick={handleFocus}>
                      <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid rgb(74, 99, 108)"}}
                        placeholder="Your Battledog ID"
                        type="number"
                        value={defenderId}
                        onChange={handleDefenderChange}
                        alt="Your Battledog ID"
                      /> 
                    </div>
                    
                    {errorMessageVisible && 
                     <div className="errordisplay talmond" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
      
                      <button className={"button1 textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {setFocusInput(false); handleClaimDebilitateUpdateClick(e)}}
                      style={{
                        border: `2px solid ${blockchain.theme}`}}>
                      {loading ? (<>Claiming...</>) : (<>Claim Debilitate</>)}
                    </button>        

                  </div> 
              </div> 
        </Mobile>
      </>   
    );
  } 
  
  const DebilitateComponent = () => {  
      useEffect(()=> {
        if (loading && transactionResult) {
            debilitateAfterCall();     
        } else if (loading && txError) {
          if (isDebilitate) return;
              isDebilitate = true;
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
            isDebilitate = false;
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
              <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
                {showImage && (
                  <img src={require("./assets/images/debilitate.gif")} className="portraitHTH" />
                )}  
                {!showImage && !defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`,backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/debilitatetarget.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}  
                
              <div style={{padding: "2px 0px 1px 0px"}} onClick={() => {handleFocus(); setFocusInput2(false)}}>
                  <input autoFocus={focusInput} className={"inputform twhite"}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
              </div>
              <div onClick={() => {handleFocus2(); setFocusInput(false)}}>                       
                  <input autoFocus={focusInput} className={"inputform twhite"} style={{border: '3px solid teal'}}
                    placeholder=" Enemy ID"
                    type="text"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Insert Enemy ID"
                  />
              </div>
                    
              {errorMessageVisible && 
               <div className="errordisplay tdebilitate" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
               <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
               </div> 
              }

                <button className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); handleDebilitateUpdateClick(e)}}
                style={{backgroundColor: `${blockchain.theme}`}}
              >
                {loading ? (<>Debilitating...</>) : (<>Debilitate</>)}                
              </button>

              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                  <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                  <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                  <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                <div className="mobilecontent" align="center">
                {showImage && (
                    <img className="portraitHTHMobile" src={require("./assets/images/debilitate.gif")}/>
                    )}  
                {!showImage && !defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}} >
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/debilitatetarget.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                <div onClick={() => {handleFocus(); setFocusInput2()}}>
                    <input autoFocus={focusInput} className={"inputformMb twhite"}
                    placeholder="Your Battledog ID"
                    type="number"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                    /> 
                </div> 
                <div onClick={() => {handleFocus2(); setFocusInput()}}>       
                    <input autoFocus={focusInput2} className={"inputformMb twhite"} 
                      placeholder=" Enemy ID"
                      type="number"
                      value={defenderId}
                      onChange={handleDefenderChange}
                      alt="Insert Enemy ID"
                    />
                </div>
                    {errorMessageVisible && 
                     <div className="errordisplay tdebilitate" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
            
                      <button className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {setFocusInput(false); setFocusInput2(false); handleDebilitateUpdateClick(e)}}
                      style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                    >
                    {loading ? (<>Debilitating...</>) : (<>Debilitate</>)}
                    </button>  
                  </div>

                </div>
                
        </Mobile>
      </>   
    );
  } 

  const HarvesterComponent = () => {     
    useEffect(()=> {
      if (isWaiting && transactionResult) { 
          setSubmitText('Amount Approved!');
          console.log("Approval Succeeded");  
          resetAllowance();
          approveGameAfterCall();
      } else if (isWaiting && txError) {
        if (isProcessing) return;
        isProcessing = true;
        setErrorMessage("Request Cancelled.");
        setErrorMessageVisible(true);
        setIsWaiting(false);
        isProcessing = false;
      }
    },[isWaiting]);

    useEffect(() => {
      if (isFarming && transactionResult) {
        resetAllowance();
        farmAfterCall();
      } else if (isFarming && txError) {
        if (isProcess) return;
        isProcess = true;
        setErrorMessage("Request Cancelled.");
        setErrorMessageVisible(true);
        setIsFarming(false);
        isProcess = false;
      }  
    },[isFarming]);

    useEffect(() => {
      if (isWithdrawing && transactionResult) {
        setIsWithdrawing(false);
      }  
    },[isWithdrawing]);

    useEffect(() => {
      if (isClaiming && transactionResult) {
        setIsClaiming(false);
      }  
    },[isClaiming]);
    return (
      <>  
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
          <>             
            <div className='switchfarm' align="center"> 
              <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/lpfarming.png")} className={`farmbuttons fb${stakeType}`} onClick={() => handleStakeTypeChange(true)}/>
              <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/gamefarming.png")} className={`farmbuttons fb${!stakeType}`} onClick={() => handleStakeTypeChange(false)} />
            </div>
            <div>
              <div className='info-container'>                
                <div className='display-container'>
                  {!stakeType && (
                    <div> 
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Total Farm</div>            
                    <div className='largetxt'>{formatNumber(Number(totalStaked).toFixed(2))}<span> GAME</span></div>
                    </div>
                    )}
                  {stakeType && (
                    <div>
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Total LP Staked</div> 
                    <div className='largetxt'>{formatNumber(Number(totalStaked))}<span> GAME LP</span></div>
                    </div>
                  )}
                </div>
                <div className='display-container'>
                  {!stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Current Farm</div>
                    <div className='largetxt'>{formatNumber(Number(currentStake).toFixed(2))} GAME</div>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Current LP Farm</div>
                    <div className='largetxt'>{formatNumber(Number(currentStake))} GAME LP</div>
                  </div>  
                  )}
                </div>
                <div className='display-container'> 
                  {!stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Harvested</div>
                    <div className='largetxt'>{formatNumber(rewardClaim)} {blockchain.tokenA}</div>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Harvested</div>
                    <div className='largetxt'>{formatNumber(rewardClaim)} GAME</div>
                  </div>  
                  )}
                </div>
                <div className='display-container'>  
                  {!stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Estimated Rewards</div>
                    <div className='largetxt'>{formatNumber(rewardOwed)} {blockchain.tokenA}  {onset && (<span style={{color: `${blockchain.theme}`}}><FaLock /></span>)}</div>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Estimated Rewards</div>
                    <div className='largetxt'>{formatNumber(rewardOwed)} GAME</div>
                  </div>  
                  )}
                </div>
              </div>
            </div>
            <div className="stake-container">
              <StakeColumn className={`column-1 ${loading ? 'wait-cursor' : null}`}>
                  <form  onSubmit={(e) => handleHarvestSubmit(e,handleFunction1)} className="stake-form">
                  {!stakeType && !isWaiting && !isFarming && (
                    <div align="center">
                    <p className='bar'>Pre-Approved: <br />{formatNumber(currentAllowance)} GAME</p>
                    <input name="input1" type="number" placeholder="Enter Amount" className={`inputbar blind-display${loading}`} style={{width: "80%"}}/>
                    <button type="submit" className={`greenbar blind-display${loading} `}>Farm Now</button>
                    </div>
                  )}
                  {!stakeType && (isWaiting || isFarming) && (
                    <p className='bar'>{submitText}</p>
                  )}
                  {stakeType && !isWaiting && !isFarming && (
                    <div align="center">
                    <p className='bar'>Pre-Approved: <br />{formatNumber(currentAllowance)} GAME LP</p>
                    <input name="input1" type="number" placeholder="Enter Amount" className={`inputbar blind-display${loading}`}/>
                    <button type="submit" className={`greenbar blind-display${loading} `}>Farm Now</button>
                    </div>
                  )}
                  {stakeType && (isWaiting || isFarming) && (
                    <p className='bar'>{submitText}</p>
                  )}
                  </form>
              </StakeColumn>
              <StakeColumn className="column-2">
                <p onClick={handleFunction2} className="greenbar stake-form stake-button">                  
                  {isWithdrawing ? (
                    <p className='bar'>{submitText}</p>
                    ):(
                    <>Withdraw Now</>
                    )}
                </p>
              </StakeColumn>
              <StakeColumn className="column-3">
                <p onClick={handleFunction3} className="greenbar stake-form stake-button">                
                {isClaiming ? (
                  <p className='bar'>{submitText}</p>
                ):(
                  <>Harvest Now</>
                )}
                </p>
              </StakeColumn>              

              <div className='notificationsbar'>
              {harvesterLogs && (
                  <div className="stakenotify texttheme" style={{color: `${blockchain.theme}`}}>
                    Reward claim successful! 
                    <span style={{color: "white"}}>Receipent:</span> 
                    {truncateAddress(harvesterLogs.sender)} 
                    <span style={{color: "white"}}>Rewards:</span> {harvesterLogs.rewards/(decimals).toFixed(blockchain.tofixed)}
                    {!stakeType ? (<span> {blockchain.tokenA}</span>):(<span> GAME</span>)}
                  </div>
                )}  
              </div>

            </div>
          </>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                <div align="center" style={{display: "flex", width: "10%", marginTop:"4px", marginBottom:"4px", marginLeft: "auto", marginRight: "auto"}}> 
                    <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/lpfarming.png")} className={`farmbuttons fb${stakeType}`} onClick={() => handleStakeTypeChange(true)} style={{width: "5vh"}}/>
                    <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/gamefarming.png")} className={`farmbuttons fb${!stakeType}`} onClick={() => handleStakeTypeChange(false)} style={{width: "5vh"}}/>
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                    <> 
                    <p className='smalltxt'>Total Farm</p>            
                    <p className='largetxt'>{formatNumber(Number(totalStaked).toFixed(2))}<span> GAME</span></p>
                    </>
                    )}  
                  {stakeType && (
                    <>
                    <p className='smalltxt'>Total LP Staked</p> 
                    <p className='largetxt'>{formatNumber(Number(totalStaked))}<span> GAME LP</span></p>
                    </>
                  )}
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                  <div>        
                    <p className='smalltxt'>Current Farm</p>
                    <p className='largetxt'>{formatNumber(Number(currentStake).toFixed(2))} GAME</p>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <p className='smalltxt'>Current LP Farm</p>
                    <p className='largetxt'>{formatNumber(Number(currentStake))} GAME LP</p>
                  </div>  
                  )}
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                  <div>        
                    <p className='smalltxt'>Harvested</p>
                    <p className='largetxt'>{formatNumber(rewardClaim)} {blockchain.tokenA}</p>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <p className='smalltxt'>Harvested</p>
                    <p className='largetxt'>{formatNumber(rewardClaim)} GAME</p>
                  </div>  
                  )}        
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                  <div>        
                    <p className='smalltxt'>Estimated Rewards</p>
                    <p className='largetxt'>{formatNumber(rewardOwed)} {blockchain.tokenA}  {onset &&(<span style={{color: `${blockchain.theme}`}}><FaLock /></span>)}</p>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <p className='smalltxt'>Estimated Rewards</p>
                    <p className='largetxt'>{formatNumber(rewardOwed)} GAME</p>
                  </div>  
                  )}
                </div>
                <div className='mobilecontent'>
                  <div className="mobile-container">
                        <MobileColumn className="column-4">
                          <form  onSubmit={(e) => handleHarvestSubmit(e, handleFunction1)} className="stake-form">
                            {!stakeType && !isWaiting && !isFarming && (
                              <div align="center">
                              <div className='barMb'>Pre-Approved: <br />{formatNumber(Number(currentAllowance))} GAME</div>
                              <input name="input1" type="number" placeholder="Enter Amount" className={`inputbar blind-display${loading}`} style={{width: "80%"}}/>
                              <button type="submit" className={`greenbarMb blind-display${loading}`}>Farm Now</button>
                              </div>
                            )}
                            {!stakeType && (isWaiting || isFarming) && (
                              <p className='barMb'>{submitText}</p>
                            )}
                            {stakeType && !isWaiting && !isFarming && (
                              <div align="center">
                              <div className='barMb'>Pre-Approved: <br />{formatNumber(Number(currentAllowance))} GAME LP</div>
                              <input name="input1" type="number" placeholder="Enter Amount" className={`blind-display${loading}`}/>
                              <button type="submit" className={`greenbarMb blind-display${loading}`}>Farm Now</button>
                              </div>
                            )}
                            {stakeType && (isWaiting || isFarming) && (
                              <p className='barMb'>{submitText}</p>
                            )}
                          </form>
                        </MobileColumn>
                        <MobileColumn className="column-5">
                          <p onClick={handleFunction2} className="greenbar stake-form stake-button">                                             
                          {isWithdrawing ? (
                            <p className='bar'>{submitText}</p>
                            ):(
                            <>Withdraw Now</>
                          )}
                          </p>
                        </MobileColumn>
                        <MobileColumn className="column-6" style={{backgroundPosition: "top center"}}>
                          <p onClick={handleFunction3} className="greenbar stake-form stake-button">                 
                          {isClaiming ? (
                            <p className='bar'>{submitText}</p>
                            ):(
                            <>Harvest Now</>
                          )}
                          </p>
                        </MobileColumn>
                  </div>
                </div>
              </div>  
        </Mobile>
      </>   
    );
  } 

  const ScoreboardComponent = () => {
    useEffect(() => {
      if (loading && transactionResult) {  
              if (isScoreboard) return   
          const scoreboardAfterCall = async() => {
            if (scoreAssaultMap) {
                  isScoreboard = true;  
                await handleState(attackerId);
                await allOwnerOfPlayersTx();
                await getStats(Number(defenderId));
                await sendHash(transactionResult.transactionHash);
                await handleState(defenderId);
                setErrorMessage("Success!");
                setErrorMessageVisible(true);
                setLoading(false);     
                  isScoreboard = false;
                  
            } else if (scoreDebilitateMap) {
                  isScoreboard = true;
                await handleState(attackerId);
                await allOwnerOfPlayersTx();
                await getStats(Number(defenderId));
                await sendHash(transactionResult.transactionHash);
                await handleState(defenderId);
                playLonghowl();
                setErrorMessage("Success!");
                setErrorMessageVisible(true);
                setLoading(false);
                  isScoreboard = false;
            }
          }
          scoreboardAfterCall();
      } else if (loading && txError) {  
              if (isScoreboard) return   
          const scoreboardAfterCall = async() => {
            if (scoreAssaultMap || scoreDebilitateMap ) {
                  isScoreboard = true; 
                setErrorMessage("Attack Cancelled.");
                setErrorMessageVisible(true);
                setLoading(false);
                  isScoreboard = false;
            }
        }
          scoreboardAfterCall();
      }
    },[loading]);
  return (
    <>
      <Desktop> 
            <div className={`menu2 smbkg${startMenuBkg}`}>
              <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
              <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
              <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
              <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
              <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
              <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
            </div>
      <div className={"activityboard"}>
        <div>
            <div className="actheader" onClick={handleActivityTracker} align={"center"}>
              <img className='scoreboardImg' src={require("./assets/images/scoreboard.png")} style={{backgroundColor: `${blockchain.theme}`}}/> 
            </div>
        <div style={{marginLeft: "5vw", height: "auto", width: "60vw"}}>
          <div className="lbtable1">
            {activePlayersList.length === 0 ? (
              <p className="leaderboard" style={{ padding: '2vh', margin: '2vh', width: 'auto', color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}`, justifyContent: 'center', cursor: 'none'}} align='center'>
                No players available on the leaderboard.
              </p>
            ) : (
              <>
                {activePlayersList && (
                  <>
                  {renderactivePlayersList()}
                  </>
                )}
              </>
            )}
          </div>
          <div className="pagemarker" align="right">
          {activePlayersList.length > 0 && 
          <>
          {Array.from({ length: totalDesktopBenchmarks }).map((_, i) => {
            const startIndex = i * desktopMax;
            const endIndex = Math.min((i + 1) * desktopMax - 1, activePlayersList.length - 1);
            // Get actual token IDs from the start and end of the range
            const startTokenId = activePlayersList[startIndex];
            const endTokenId = activePlayersList[endIndex];
            return (
              <div key={i} 
                onClick={() => setDesktopBenchmark(i)}
                style={{
                  background: desktopBenchmark === i 
                    ? `linear-gradient(to right, transparent, ${blockchain.theme})` 
                    : 'linear-gradient(to right, transparent, black)',
                  color: desktopBenchmark === i ? 'white' : blockchain.theme, 
                  borderRight: '2px double gray', 
                  borderBottom: '2px double gray', 
                  cursor: 'pointer', 
                  marginTop: '0.5vh', 
                  padding: '1vh'
                }}
              >
                {startTokenId} - {endTokenId}
              </div>
            );
          })}
          </>
          }
          </div>
        </div>
        </div>      
      </div>
        {selectedPlayerStats && (
        <div className="leader-container" style={{border: `1vh solid ${blockchain.theme}`, padding: '1vh', borderRadius: '20px 0px 0px 20px'}}>
            {selectedPlayerStats.map((player, index) => (
              <div key={index} className="player-info" style={{color: `${blockchain.theme}`}}>
                <div className="profile-pic">
                <div className="player-details-namebox bp5gtext">
                      <div className="lbSmall jlb">{player[0]}</div>
                    </div>
                  {showImage ? (
                  <img className="player-profile-pic" src={(`https://battledogs.fun/engine/${player[1].toString()}.png`)} 
                  onClick={handleView}
                  style={{border: `1px solid ${blockchain.theme}` }}
                  alt=""/>
                  ):(
                    <div className="blank-profile-pic lbMidtxt"
                    style={{color: `black`}}
                    onClick={handleView}
                    align="center">Click To <br/> View</div>
                  )}
                    <div className="player-details-idbox">
                      <div className="lbMid jlb">{player[1].toString()}</div>
                    </div>
                  <div className="player-details lbtable2">
                    <div className="player-details-box">
                      <div className="lbSmall jlb">Level</div>
                      <div className="lbMid jlb twhite">{player[2].toString()}</div>
                    </div>
                    <div className="player-details-box">
                      <div className="lbSmall jlb">Fights</div>
                      <div className="lbMid jlb twhite">{player[5].toString()}</div>
                    </div>
                    <div className="player-details-box">
                      <div className="lbSmall jlb">Aggression</div>
                      <div className="lbMid jlb twhite">{player[3].toString()}</div>
                    </div>
                    <div className="player-details-box" style={{backgroundColor: "black"}}>
                     <div className="lbSmall jlb" style={{opacity: "0.4"}}>Payout</div>
                      <div className="lbMid jlb bp5gtext" >?</div>
                    </div>
                    <div className="player-details-box">
                      <div className="lbSmall jlb">Activation</div>
                      <div className="lbMid jlb twhite">{player[8].toString()}</div>
                    </div>
                    <div className="player-details-box">
                      <div className="lbSmall jlb">Wins</div>
                      <div className="lbMid jlb twhite">{player[6].toString()}</div>
                    </div>
                    <div className="player-details-box">
                      <div className="lbSmall jlb">Stamina</div>
                      <div className="lbMid jlb twhite">{player[4].toString()}</div>
                    </div>
                    <div className="player-details-box">
                      <div className="lbSmall jlb">History</div>
                      <div className="lbMid jlb twhite">{player[9].toString()}</div>
                    </div>
                  </div>
                </div>  
                <div className="leader-return-text" align="center" onClick={hideView}><img src={require("./assets/images/circle-xmark.png")} style={{height: "6vh"}}/></div>
              <div className='quickAttack' align='center'>
                {activeHouses ? 
                <>
                {isSameHouse = activeHouses.some(house => house.ID === Number(player[1].toString()) && house.SIGIL === flagHouse) && (
                  <div style={{display: "flex", flexDirection: "column", color: `${allActiveHouses[flagHouse].COLOR}`, justifyContent: 'center', alignItems: 'center' }} align="center">                  
                  {blockchain.klanmode ? <img src={allActiveHouses[flagHouse].ICON} style={{height: "3.5vw", width: "2vw", boxShadow: "5px 5px 15px rgba(102, 101, 101, 0.5)"}}/> : <img src={require(`./assets/images/icons/${allActiveHouses[flagHouse].NAME}.gif`)} style={{width: "4vw"}}/>}
                  <span style={{textShadow: "2px 2px 8px whitesmoke"}}><PiSealWarningDuotone /> Friendly Fire <PiSealWarningDuotone /></span>
                  </div>
                )}
                </>
                : null}
                <button className={`scorebutton scoreassault${scoreAssaultMap}`} onClick={() => {setDefenderId(player[1].toString()); handleScoreAssault()}}>Assault This Battledog</button> 
                <PiInfinityLight style={{color: 'whitesmoke', padding: "0vh 1vh 0vh 1vh"}}/> 
                <button className={`scorebutton scoredebilitate${scoreDebilitateMap}`} onClick={() => {setDefenderId(player[1].toString()); handleScoreDebilitate()}}> Debilitate This Battledog</button>
                <div className='scoreAttackDiv' align='center' style={{width: '40vw'}}>
                  {scoreAssaultMap && 
                  <>{renderAssault()}</>}
                  {scoreDebilitateMap && 
                  <>{renderDebilitate()}</>}
                </div>
              </div>                
                  
              {errorMessageVisible && 
                <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}</div> 
              }
              </div>
            ))}
        </div>
      )}
            <div className="notificationsData">
              <Notifications />
            </div>
      </Desktop>
      <Mobile>
            <div className="arsenalDivMobile">               
              <div className='mobilenavigate'>
                <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
              </div>           
              <div className='mobilenotify' align="center">
                  <Notifications />
              </div>
                <div className='mobilecontent' align="center">
                  <div>
                    <div onClick={handleActivityTracker}> 
                      <img className='scoreboardImgMobile' src={require("./assets/images/scoreboard.png")} style={{backgroundColor: `${blockchain.theme}`}}/>
                    </div>
                    <div className="lbtable1Mb">
                      {activePlayersList.length === 0 ? (
                        <p className="leaderboardMB lbMobile" style={{ margin: '1vh 1vw 1vh 1vw', color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}`, width: '80vw', cursor: 'none'}}>
                          No players available on the leaderboard.
                        </p>
                      ) : (
                       <>
                       {activePlayersList && (
                        <>
                          {renderactivePlayersList()}
                        </>
                       )}
                       </>
                      )}
                          
                      {selectedPlayerStats && (
                        <div className="little-container" style={{border: `1vh solid ${blockchain.theme}`, padding: '1vh', borderRadius: '20px 20px 20px 20px'}}>
                          {selectedPlayerStats.map((player, index) => (
                            <div key={index} className="player-info-mb" style={{color: `${blockchain.theme}`}}>
                              <div className="profile-pic-mb" style={{color: `${blockchain.theme}`}}>

                                <div className="player-details-mb">
                                  <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">Level</div>
                                    <div className="lbLargeMb jlb twhite">{player[2].toString()}</div>
                                  </div> 
                                  <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">Attack</div>
                                    <div className="lbLargeMb jlb twhite">{player[3].toString()}</div>
                                  </div>                      
                                  <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">Defence</div>
                                    <div className="lbLargeMb jlb twhite">{player[4].toString()}</div>
                                  </div>
                                  <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">Wins</div>
                                    <div className="lbLargeMb jlb twhite">{player[6].toString()}</div>
                                  </div>                 
                                </div>

                                <div>                   
                                      <div className="player-details-namebox-mb bp5gtext">
                                          <div className="lbSmallMb jlb">{player[0].toString()}</div>
                                      </div>

                                      {showImage ? (
                                      <img className="player-profile-pic-mb" src={(`https://battledogs.fun/engine/${player[1].toString()}.png`)} 
                                      onClick={handleView}
                                      style={{border: `1px solid ${blockchain.theme}` }}
                                      alt=""/>
                                      ):(
                                        <div className="blank-profile-pic-mb lbMidtxt" 
                                        style={{color: `black`}}
                                        onClick={handleView}
                                        align="center">Click To <br/> View</div>
                                      )}

                                      <div className="player-details-idbox-mb">
                                        <div className="lbMid jlb">{player[1].toString()}</div>
                                      </div> 
                                </div>  

                                <div className="player-details-mb">                      
                                <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">Tier</div>
                                    <div className="lbLargeMb jlb twhite">{player[8].toString()}</div>
                                  </div>
                                  <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">Fights</div>
                                    <div className="lbLargeMb jlb twhite">{player[5].toString()}</div>
                                  </div>                      
                                  <div className="player-details-box-mb">
                                    <div className="lbSmallMb jlb">History</div>
                                    <div className="lbLargeMb jlb twhite">{player[9].toString()}</div>
                                  </div>                      
                                  <div className="player-details-box-mb" style={{backgroundColor: "black"}}>
                                  <div className="lbSmallMb jlb" style={{opacity: "0.4"}}>Payout</div>
                                    <div className="lbLargeMb jlb bp5gtext" >?</div>
                                  </div>
                                </div>

                                <div className="player-details-mb">
                                </div>
                              </div>
                              <div className="little-return-text" align="center" onClick={hideView}><img src={require("./assets/images/circle-xmark.png")} style={{height: "4vh"}}/> </div>
                            
                            <div className='quickAttack' align='center'>
                              {activeHouses.length > 0 ? 
                              <>
                              {isSameHouse = activeHouses.some(house => house.ID === Number(player[1].toString()) && house.SIGIL === flagHouse) && (
                                <div style={{color: `${allActiveHouses[flagHouse].COLOR}`}}>                  
                                  {blockchain.klanmode ? <img src={allActiveHouses[flagHouse].ICON} style={{height: "8.5vw" , width: "5vw", boxShadow: "5px 5px 15px rgba(102, 101, 101, 0.5)"}}/> : <img src={require(`./assets/images/icons/${allActiveHouses[flagHouse].NAME}.gif`)} style={{width: "10vw"}}/>}
                                  <br />
                                  <PiSealWarningDuotone /> Friendly Fire <PiSealWarningDuotone />
                                  <br />
                                </div>
                              )}                
                              </>
                              : null}
                              <button className={`scorebutton scoreassault${scoreAssaultMap}`} style={{color: `${blockchain.theme}`}} onClick={() => {setDefenderId(player[1].toString()); handleScoreAssault()}}>Assault This Battledog</button> <br />
                              <PiInfinityLight style={{color: 'whitesmoke', padding: "0vh 1vh 0vh 1vh"}}/> <br />
                              <button className={`scorebutton scoredebilitate${scoreDebilitateMap}`}  style={{color: `${blockchain.theme}`}} onClick={() => {setDefenderId(player[1].toString()); handleScoreDebilitate()}}> Debilitate This Battledog</button>
                              <div className='scoreAttackDiv' align='center'>
                                {scoreAssaultMap && 
                                <>{renderAssault()}</>}
                                {scoreDebilitateMap && 
                                <>{renderDebilitate()}</>}
                              </div>
                            </div>                
                                
                            {errorMessageVisible && 
                              <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}</div> 
                            }
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="pagemarkerMB" align="right">
                    {activePlayersList.length > 0 && 
                    <>
                    {Array.from({ length: totalMobileBenchmarks }).map((_, i) => {
                      const startIndex = i * mobileMax;
                      const endIndex = Math.min((i + 1) * mobileMax - 1, activePlayersList.length - 1);
                      // Get actual token IDs from the start and end of the range
                      const startTokenId = activePlayersList[startIndex];
                      const endTokenId = activePlayersList[endIndex];
                      return (
                        <div key={i} 
                          onClick={() => setMobileBenchmark(i)}
                          style={{
                            background: mobileBenchmark === i 
                              ? `linear-gradient(to bottom, transparent, ${blockchain.theme})` 
                              : 'linear-gradient(to bottom, transparent, black)',
                            color: mobileBenchmark === i ? 'white' : blockchain.theme, 
                            borderBottom: '2px double gray', 
                            borderLeft: '2px double gray', 
                            borderRight: '2px double gray', 
                            cursor: 'pointer', 
                            marginTop: '0.5vh', 
                            padding: '1vh'
                          }}
                        >
                          {startTokenId} - {endTokenId}
                        </div>
                      );
                    })}        
                    </>
                    }
                    </div>
                  </div>                
                </div>

            </div> 
      </Mobile>
    </>   
  );
} 

const LeaderboardComponent = () => {
return (
  <>
    <Desktop> 
          <div className={`menu2 smbkg${startMenuBkg}`}>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
            <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
          </div>
          <div className="fullleaderboard" style={{fontFamily: "NexaHeavy", fontSize: "1vh"}}>
          <h2 className={"leaderboardlayer twhitesmoke"}>
            <div>Avatar</div><div>Position</div><div>Owner</div><div>NFTs</div><div>XP</div>
          </h2>
            {leadingPlayers.map((player, index) => {
              return (
                <div key={player} className={"leaderboardlayer tblack"} style={{backgroundColor: `${blockchain.theme}`}}>
                  <img src={`https://battledogs.fun/engine/${player.avatar}.png`} className="nftImage"
                    style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/>
                  <div>{index + 1}</div>                  
                  <div>{player.address === account.address ? <span style={{textShadow: "2px 2px 8px black", fontSize: "large", color: "whitesmoke"}}>You</span> : truncateAddress(player.address)}</div>
                  <div>{player.ownership}</div>
                  <div>{(player.score).toFixed(1)}</div>
                </div>
              )}
            )}            
          </div>
          
    </Desktop>
    <Mobile>
          <div className="arsenalDivMobile">               
            <div className='mobilenavigate'>
              <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
              <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
              <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
              <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
              <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
              <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
            </div>           
            <div className='mobilenotify' align="center">
              <Notifications />
            </div>
            <div className='mobilecontent' align="center">
          <h2 className={"leaderboardlayer twhitesmoke"}>
            <div>Avatar</div><div>Position</div><div>Owner</div><div>NFTs</div><div>XP</div>
          </h2>
            {leadingPlayers.map((player, index) => {
              return (
                <div key={player} className={"leaderboardlayer tblack"} style={{backgroundColor: `${blockchain.theme}`}}>
                  <img src={`https://battledogs.fun/engine/${player.avatar}.png`} className="nftImage" 
                    style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/>
                  <div>{index + 1}</div>
                  <div>{player.address === account.address ? <span style={{textShadow: "2px 2px 8px black", fontSize: "large", color: "whitesmoke"}}>You</span> : truncateAddress(player.address)}</div>
                  <div>{player.ownership}</div>
                  <div>{(player.score).toFixed(1)}</div>
                </div>
              )}
            )}   
            </div>                
          </div>
    </Mobile>
  </>   
);
} 

const renderHouseByScore = () => {
  const mergedHouseData = allActiveHouses.map((house, index) => {
    const matchingHouseScore = houseScores.find(houseScore => houseScore.SIGIL === index);
    
    return {
      ...house,  // Spread the house object (name, color, etc.)
      sigil: index,  // Add the sigil (index) for reference
      score: matchingHouseScore ? matchingHouseScore.SCORE : 0  // Default score to 0 if not found
    };
  });

  // Step 2: Sort the merged array by score in descending order
  const sortedHouseData = mergedHouseData.sort((a, b) => b.score - a.score);

  // Step 3: Render the sorted list of houses with their scores
  return (
    <>
      {sortedHouseData.map((house, index) => (
        <li key={index}>
        <Desktop>
          <div className="housedisplay" onClick={() => { setApproval(house.sigil); handleView()}} style={{color: `${house.COLOR}`, border: `2px solid ${house.COLOR}`,
              background: `linear-gradient(135deg, black 80%, ${house.COLOR} 100%)`}}>
            <div>Position<br /><span className="lbLarge">{index + 1}</span></div>
            <div>{house.NAME} Klan<br /> 
              <span className="lbLarge mkMicro">{house.score ? (formatNumber(house.score)) : 0}</span> <span className="mkMicro" style={{color: "darkgray"}}>xp</span> 
            </div>
            <div>
              {blockchain.klanmode ? 
              <img src={house.ICON} style={{width: "9vh", height: "16vh", objectFit: "cover", objectPosition: "center"}} alt={`${house.NAME} Icon`}/> 
              : 
              <img src={require(`./assets/images/icons/${house.NAME}.gif`)} style={{height: "14vh"}} alt={`${house.NAME} Icon`}/>
              }
            </div>
          </div>          
        </Desktop>
        <Mobile>
          <div className="housedisplay" onClick={() => { setApproval(house.sigil); handleView()}} style={{color: `${house.COLOR}`, backgroundColor: `2px solid ${house.COLOR}`, 
                  background: `linear-gradient(135deg, black 80%, ${house.COLOR} 100%)`}}> 
            <div>Position<br /><span className="lbLargeMb">{index + 1}</span></div>
            <div>{house.NAME} Klan<br /> 
              <span className="lbLargeMb mkNano">{house.score ? (formatNumber(house.score)) : 0}</span><span className="mkNano" style={{color: "darkgray"}}>xp</span>
            </div> 
            <div>
            {blockchain.klanmode ? 
            <img src={house.ICON} style={{width: "4.5vh", height: "8vh", objectFit: "cover", objectPosition: "center"}} alt={`${house.NAME} Icon`}/> 
            : 
            <img src={require(`./assets/images/icons/${house.NAME}.gif`)} style={{height: "8vh"}}/>
            }
            </div>
          </div> 
        </Mobile>
        </li>
      ))}
    </>
  );
}

const getStyle = () => {
  if (flagHouse !== null ) {
    return {
      color: allActiveHouses[flagHouse]?.COLOR,
      background: `linear-gradient(135deg, black 80%, ${allActiveHouses[flagHouse]?.COLOR} 100%)`
    };
  } else if (approval !== "") {
    return {
      color: allActiveHouses[approval]?.COLOR,
      background: `linear-gradient(135deg, black 80%, ${allActiveHouses[approval]?.COLOR} 100%)`
    };
  } else {
    return {
      color: blockchain.theme,
      background: `linear-gradient(135deg, black 80%, ${blockchain.theme} 100%)`
    };
  }
};

const HouseboardComponent = () => {
return (
  <>
    <Desktop> 
          <div className={`menu2 smbkg${startMenuBkg}`}>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
            <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
          </div>
          <div className="textheader" onClick={handleScoreboard} align={"center"} style={{marginTop: "10vh"}} title="Click to see Scoreboard">
            Battle of the Klans
          </div>
        <div className="lbtitles"  style={{marginLeft: "21vw", width: "57.5vw"}}>
            {allActiveHouses.length === 0 ? (
              <>
              <p className="leaderboard" style={{ padding: '2vh', margin: '2vh', width: 'auto', color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}`, justifyContent: 'center', cursor: 'none'}} align='center'>
                No Klans Have Been Created.
              </p>              
              <CreateKlan /> 
              </>
            ) : (
              <>
              <CreateKlan />            
              {allActiveHouses.length > 0 && (
                <>
                {renderHouseByScore()}
                </>
              )}
              {showImage && (approval >= 0) && (
                <div className="leadhouse-info left-right translation2" align="center" style={getStyle()}>
                    <p>Are you sure you want to join <br/><span className="mkSmall">{allActiveHouses[approval].NAME}</span><span style={{fontWeight:"bold"}}> Klan? </span></p>
                      <div>
                        <button className="button2 texttheme" style={{marginRight: "0.5vw"}} onClick={() => {pickaKlan(Number(approval)); handleView(); handleReceipt()}}>Yes</button> 
                        <button className="button2 texttheme" style={{marginLeft: "0.5vw"}} onClick={handleView}>No</button>  
                     </div>      
                </div>
              )}
              {showReceipt && (
                <div className="leadhouse-info right-left translation2" align="center" style={getStyle()}>
                    <p>Prepare yourself! {flagHouse !== null ? <>You are <br/><span className="mkSmall">{allActiveHouses[flagHouse].NAME}</span><span style={{fontWeight:"bold"}}> Klan </span></> : null}</p>
                      <div>
                        <button className="button2 texttheme" style={{marginLeft: "0.5vw"}} onClick={handleReceipt}>OK</button>  
                     </div>      
                </div>
              )}
              </>
            )}
            {showKlan && (
              <div className="leadhouse-info right-left translation2" align="center" style={getStyle()}>
                <p className="texttheme">Klan Details</p>
                <div onClick={handleFocus}>                            
                  <input 
                    autoFocus={focusInput} 
                    className={"inputform twhite"} 
                    type="text"
                    placeholder="Your Klan Name"
                    value={name}
                    onChange={handleNameChange}
                    alt="Your Klan Name"
                  />                  
                </div>
                  
                  <div>
                    <input
                      className={"inputform twhite"}
                      style={{
                        border: "3px solid orange",
                        backgroundColor: color
                      }}
                      type="text"
                      placeholder="Click to pick color"
                      value={color}
                      onClick={() => setShowColorPicker(true)}
                      readOnly
                    />
                    
                    {showColorPicker && (
                      <div style={{
                        position: 'absolute',
                        zIndex: 2,
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginTop: '10px'
                      }}>
                        <div 
                          style={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                          }}
                          onClick={() => setShowColorPicker(false)}
                        />
                        <HexColorPicker 
                          color={color} 
                          onChange={(newColor) => {
                            setColor(newColor);
                            setShowColorPicker(false); // Close the picker after color selection
                          }}
                        />
                      </div>
                    )}
                  </div>
                
                <div>                            
                  <input
                    className={"inputform twhite"} 
                    type="text"
                    placeholder="Icon link (https://...) "
                    value={inputValue}
                    onChange={handleInputChange}
                    alt="Your Klan Icon"
                  />
                </div>
                <div>
                  <button 
                    className="button2 texttheme" 
                    style={{marginLeft: "0.5vw"}} 
                    onClick={() => {
                      createKlan();
                      // The color value is available in the `color` variable
                      // You can pass it to your createKlan function
                    }}
                  >
                    Create
                  </button>
                  <button 
                    className="button2 texttheme" 
                    style={{marginLeft: "0.5vw"}} 
                    onClick={hideView}
                  >
                    Cancel
                  </button>   
                </div>      
              </div>
            )}
              {showKlanReceipt && (
                <div className="leadhouse-info right-left translation2" align="center" style={getStyle()}>
                    <p>Rally your troops! {flagHouse !== null ? <>You are now <br/><span className="mkSmall">{allActiveHouses[flagHouse].NAME}</span><span style={{fontWeight:"bold"}}> Klan </span></> : null}</p>
                      <div>
                        <button className="button2 texttheme" style={{marginLeft: "0.5vw"}} onClick={handleKlanReceipt}>OK</button>  
                     </div>      
                </div>
              )}
        </div> 
        <div className="notificationsData">
            <Notifications />
        </div>
    </Desktop>
    <Mobile>
          <div className="arsenalDivMobile">               
            <div className='mobilenavigate'>
              <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
              <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
              <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
              <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
              <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
              <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
            </div>           
            <div className='mobilenotify' align="center">
                <Notifications />
            </div>
              <div className='mobilecontent' align="center">
                <div>
                  <div className="textheader " onClick={handleScoreboard} align={"center"}>
                    Battle of the Klans
                  </div>
                  <div className="lbtitles">                    
                {allActiveHouses.length === 0 ? (
                  <>
                  <p className="leaderboard" style={{ padding: '2vh', margin: '2vh', width: 'auto', color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}`, justifyContent: 'center', cursor: 'none'}} align='center'>
                    No Klans Have Been Created.
                  </p>
                  <CreateKlan />
                  </>
                ) : (
                  <>
                  <CreateKlan />         
                  {allActiveHouses.length > 0 && (
                  <>
                  {renderHouseByScore()}
                  </>
                  )}
                  {showImage && (approval >=0) && (
                    <div className="leadhouse-info-mb left-right translation2" align="center" style={{color: `${allActiveHouses[approval].COLOR}`, 
                    background: `linear-gradient(135deg, black 80%, ${allActiveHouses[approval].COLOR} 100%)`}}>
                          <p>Are you sure you want to join <br/><span className="mkMicro">{allActiveHouses[approval].NAME}</span><span style={{fontWeight:"bold"}}> Klan? </span></p>
                        <div>
                          <button className="button2 texttheme" style={{marginRight: "0.5vw" , padding: "0.5vw" }} onClick={() => {pickaKlan(Number(approval)); handleView(); handleReceipt()}}>Yes</button> 
                          <button className="button2 texttheme" style={{marginLeft: "0.5vw", padding: "0.5vw"}} onClick={handleView}>No</button>  
                         </div>      
                    </div>
                  )}
                  {showReceipt && (
                    <div className="leadhouse-info-mb right-left translation2" align="center" style={getStyle()}>
                          <p>Prepare yourself! {flagHouse !== null ? <>You are <br/><span className="mkMicro">{allActiveHouses[flagHouse].NAME}</span><span style={{fontWeight:"bold"}}> Klan </span></> : null}</p>
                        <div>
                          <button className="button2 texttheme" style={{marginLeft: "0.5vw", padding: "0.5vw"}} onClick={handleReceipt}>OK</button>  
                         </div>      
                    </div>
                  )}
                  </>
                )}
                {showKlan && (
                  <div className="leadhouse-info-mb right-left translation2" align="center" style={getStyle()}>
                    <p className="texttheme">Klan Details</p>
                    <div onClick={handleFocus}>                            
                      <input autoFocus={focusInput} 
                        className={"inputformMb twhite"}
                        type="text"
                        placeholder="Your Klan Name"
                        value={name}
                        onChange={handleNameChange}
                        alt="Your Klan Name"
                      />
                    </div> 
                      
                    <div>
                      <input
                        className={"inputformMb twhite"}
                        style={{
                          border: "3px solid orange",
                          backgroundColor: color,
                          marginTop: '10px'
                        }}
                        type="text"
                        placeholder="Click to pick color"
                        value={color}
                        onClick={() => setShowColorPicker(true)}
                        readOnly
                      />
                        
                      {showColorPicker && (
                        <div style={{
                          position: 'absolute',
                          zIndex: 2,
                          top: '100%',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          marginTop: '10px'
                        }}>
                          <div 
                            style={{
                              position: 'fixed',
                              top: 0,
                              right: 0,
                              bottom: 0,
                              left: 0
                            }}
                            onClick={() => setShowColorPicker(false)}
                          />
                          <HexColorPicker 
                            color={color} 
                            onChange={(newColor) => {
                              setColor(newColor);
                              setShowColorPicker(false); // Close the picker after color selection
                            }}
                          />
                        </div>
                      )}
                      </div>                   
                
                <div>                            
                  <input  
                    className={"inputform twhite"} 
                    type="text"
                    placeholder="Icon link (https://...) "
                    value={inputValue}
                    onChange={handleInputChange}
                    alt="Your Klan Icon"
                  />
                </div>
                    <div>
                      <button 
                        className="button2 texttheme" 
                        style={{marginLeft: "0.5vw", padding: "0.5vw"}} 
                        onClick={() => {
                          createKlan();
                          // The color value is available in the `color` variable
                        }}
                      >
                        Create
                      </button>  
                      <button 
                        className="button2 texttheme" 
                        style={{marginLeft: "0.5vw", padding: "0.5vw"}} 
                        onClick={hideView}
                      >
                        Cancel
                      </button>  
                    </div>      
                  </div>
                )}
                {showKlanReceipt && (
                  <div className="leadhouse-info-mb right-left translation2" align="center" style={getStyle()}>
                        <p>Rally your troops! {flagHouse !== null ? <>You are now <br/><span className="mkMicro">{allActiveHouses[flagHouse].NAME}</span><span style={{fontWeight:"bold"}}> Klan </span></> : null}</p>
                      <div>
                        <button className="button2 texttheme" style={{marginLeft: "0.5vw", padding: "0.5vw"}} onClick={handleKlanReceipt}>OK</button>  
                       </div>      
                  </div>
                )}
                  </div>
                </div>                
              </div>
          </div> 
    </Mobile>
  </>   
);
} 

  const LevelUpComponent = () => {  
      useEffect(()=> {
        if (loading && transactionResult) {
          levelUpAfterCall();
        } else if (loading && txError) {
          if (isLevelUp) return;
            isLevelUp = true;
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
            isLevelUp = false;
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
              <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
                {!attackerId && (
                  <img src={require("./assets/images/leveler.png")} className="portraitHTH" />
                )} 
                {attackerId && (
                  <img src={`${TargetA}`} className="portraitHTH" />
                )}       
                <div onClick={handleFocus}>       
                    <input autoFocus={focusInput} className={"inputform twhite"} style={{border: "3px solid orange"}}
                      type="text"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                </div> 
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                  <button className={"buttongold textstyle"} 
                    disabled={loading ? 1 : 0}
                    onClick={(e) => {setFocusInput(false); handleLevelUpUpdateClick(e)}}
                    style={{backgroundColor: `${blockchain.theme}`}}
                  >
                  {loading ? (<>Setting...</>) : (<>Level Up</>)}
                  </button>  
              </div>                                
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                    {!attackerId && (
                      <img src={require("./assets/images/leveler.png")} className="portraitHTHMobile"/>
                    )} 
                    {attackerId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile"/>
                    )}
                    <div onClick={handleFocus}>              
                      <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid orange"}}
                      type="number"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                   </div> 
                    
                   {errorMessageVisible && 
                    <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                    </div> 
                   }
                    
                    <button className={"buttongold textstyle"}
                    disabled={loading ? 1 : 0}
                    onClick={(e) => {setFocusInput(false); handleLevelUpUpdateClick(e)}}
                    style={{backgroundColor: `${blockchain.theme}`}}
                    >
                    {loading ? (<>Setting...</>) : (<>Level Up</>)}
                    </button>  
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  const MiningComponent = () => {
    useEffect(() => {
      if (isClaiming && transactionResult) {
      miningAfterCall();
      } else if (isClaiming && txError) {
        if (isProcess) return;
        isProcess = true;
        handleMining();
        setIsClaiming(false);
        isProcess = false;
      }
    },[isClaiming]);
    return (
      <>
        <Desktop>
           <>
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
              <div className="xbo-container">
                    <div className="xbo-display-column">
                      {ownedBattledogs && (
                        <>
                        {ownedBattledogs.map((player, index) => {
                          return (
                            <div key={index} className={"texttheme tgold"} style={{color: `${blockchain.theme}`}}>
                              <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"} onClick={() => handleMiningClick(player.id)} 
                              style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/> 
                              <br />
                              {timelock && (
                                <div style ={{color: `${blockchain.theme}`, backgroundColor: "black", 
                                margin: "0.5vh", padding: "0.5vh", borderRadius: "25px"}}>
                                  {' '}
                                  {Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) < 0
                                    ? `${Math.abs(Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400))} days left`
                                    : Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) > 1000
                                      ? 'Claim Now'
                                      : `${Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400)} days. Claim Now`
                                  }
                                </div>
                              )}
                            </div>
                          );
                        })}    
                        </>
                      )} 
                    </div>
                    <XboColumn backgroundImage={
                      selectedPlayer ? (`https://battledogs.fun/engine/${selectedPlayer}.png`) : (require(`./assets/images/batdog.jpg`))}>
                        {unlocker ? (
                        <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText}</div>
                        ) : (
                        <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction1)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>Claim {selectedPlayer}</button>        
                        )}
                        
                    </XboColumn>
                    <XboColumn backgroundImage={require(`./assets/images/superdog.jpg`)}>
                        {unlocker2 ? (
                        <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText2}</div>
                        ) : (
                      <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction2)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>
                        Claim All ({activeBattledogs && (<>{activeBattledogs.length}</>)})
                      </button>                      
                        )}
                    </XboColumn>
              </div>              
            
              <div className='notificationsbar'>
                    {minerLogs && (
                        <div className="stakenotify texttheme" style={{color: `${blockchain.theme}`}}>
                          Reward claim successful!  
                          <span style={{color: "white"}}>Receipent:</span> 
                          {truncateAddress(minerLogs.sender)} 
                          <span style={{color: "white"}}>Rewards:</span> {minerLogs.rewards/(10**18).toFixed(2)} GAME
                        </div>
                      )}
              </div>
            </> 
        </Desktop>
        <Mobile>
           <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div> 
                <div className='mobilenotify' align="center">
                  {minerLogs && (
                      <div style={{display: "flex", flexDirection: "column", color: `${blockchain.theme}`}}>
                        Reward claim successful!  
                        <span style={{color: "white"}}>Receipent:</span> 
                        {truncateAddress(minerLogs.sender)} 
                        <span style={{color: "white"}}>Rewards(recent):</span> {minerLogs.rewards/(10**18).toFixed(2)} GAME
                      </div>
                    )}                    
                </div>
                <div className='mobilecontent'>
                    <div className="mobile-container">
                        <div className="xbo-display-column-mb">                          
                      {ownedBattledogs && (
                        <>
                        {ownedBattledogs.map((player, index) => {
                          return (
                            <div key={index} className={"texttheme"} style={{color: `${blockchain.theme}`, justifyContent: "center", alignItems: "center"}}>
                              <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftMineMB"} onClick={() => handleMiningClick(player.id)}  
                                style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/> 
                              <br />
                              {timelock && (
                                <div style ={{color: `${blockchain.theme}`, backgroundColor: "black", 
                                margin: "0.5vh", padding: "0.5vh", borderRadius: "25px"}}>
                                  {' '}
                                  {Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) < 0
                                    ? `${Math.abs(Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400))} days left`
                                    : Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) > 1000
                                      ? 'Claim Now'
                                      : `${Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400)} days due.`
                                  }
                                </div>
                              )}
                            </div>
                          );
                        })}
                        </>
                      )}
                        </div>
                        <MobileXboColumn backgroundImage={selectedPlayer ? (`https://battledogs.fun/engine/${selectedPlayer}.png`) : (require(`./assets/images/batdog.jpg`))}>                          
                            {unlocker ? (
                            <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText}</div>
                            ) : (
                              <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction1)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>Claim {selectedPlayer}</button>        
                              )}
                        </MobileXboColumn>
                        <MobileXboColumn backgroundImage={require(`./assets/images/superdog.jpg`)}>
                          {unlocker2 ? (
                          <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText2}</div>
                          ) : (
                            <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction2)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>
                              Claim All ({activeBattledogs && (<>{activeBattledogs.length}</>)})
                            </button>  
                          )}
                        </MobileXboColumn>
                    </div>                    
                </div> 
            </div>
        </Mobile>
      </>   
    );
  } 

  const MintComponent = () => { 
      useEffect(() => {
        if (loading && transactionResult) {
          mintAfterCall();
        }               
        else if (loading && txError) {
          if (isMint) return
            isMint = true;
          setErrorMessageVisible(true);
          setErrorMessage("Request cancelled.")
          setLoading(false);
            isMint = false;
        }
      },[loading]);
    return (
      <>
      <MintParameter />
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(3)}} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(1)}} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} className="icons" /></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>              
              <div className="translation" style={{ display: "flex", flexDirection: "column", alignItems: "center" }} align="center">
                {showImage ? (
                  <img src={require("./assets/images/morphs.gif")} className="portraitHTH" />
                ) : (
                  <div className="portraitHTH" style={{backgroundImage: `${newMint}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/availabletomint.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}
                  <div style={{ padding: "2px" }} >
                    <div onClick={handleFocus}>
                      <input autoFocus={focusInput} 
                        className={"inputform twhite slimBorder"}
                        type="text"
                        placeholder="Name Your Battledog"
                        value={name}
                        onChange={handleNameChange}
                        alt="Name Your Battledog"
                      />
                    </div>
                    
                    {errorMessageVisible && 
                     <div className="errordisplay twhitesmoke" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                    <br />
                    <button
                      className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {handleMintClick(e); setFocusInput(false)}} 
                      style={{backgroundColor: `${blockchain.theme}`}}
                    >
                    {loading ? (<>Spawning...</>) : (<>Spawn</>)}
                    </button>
                    <br />
                    {/* <button
                      className={"buttongold textstyle"}
                      onClick={putHouse} 
                      style={{backgroundColor: `${blockchain.theme}`}}
                    >
                    Put House
                    </button> */}
                    {/* <button
                      className={"buttongold textstyle"}
                      onClick={getActiveHouses} 
                      style={{backgroundColor: `${blockchain.theme}`}}
                    >
                    Fetch Houses
                    </button> */}
                  </div>
              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/icons/startreturn.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} style={{height: "50px"}} /></div><br />
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                <div className='mobilecontent' align="center">
                {showImage ? (
                  <img src={require("./assets/images/morphs.gif")} className="portraitHTHMobile"/>
                ) : (
                  <div className="portraitHTHMobile" style={{backgroundImage: `${newMint}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/availabletomint.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                  <div onClick={handleFocus}>
                        <input autoFocus={focusInput}  
                        className={"inputformMb twhite"}
                        type="text"
                        placeholder="Name Your Battledog"
                        value={name}
                        onChange={handleNameChange}
                        alt="Name Your Battledog"
                        />
                  </div>
                    
                        {errorMessageVisible && 
                         <div className="errordisplay twhitesmoke" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                         <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                         </div> 
                        }
          
                        <button
                          className={"buttongold textstyle"}
                          disabled={loading ? 1 : 0}
                          onClick={(e) => {handleMintClick(e); setFocusInput(false)}} 
                          style={{backgroundColor: `${blockchain.theme}`}}
                        >
                        {loading ? (<>Spawning...</>) : (<>Spawn</>)}
                        </button>                                                
                      </div> 
              </div> 
        </Mobile>
      </>   
    );
  } 

  const PayoutComponent = () => {
      useEffect(() => {
        if (loading && transactionResult) {
          payoutAfterCall();
        } else if (loading && txError) {
          if (isPayout) return;
          isPayout = true;
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
          isPayout = false;
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
          <div className={"translation"} 
              style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!attackerId && (
                <img src={require("./assets/images/cashingout.png")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )} 
                  <div onClick={handleFocus}>            
                    <input autoFocus={focusInput} className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
                      placeholder="Your Battledog ID"
                      type="text"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tblue" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
                </div>         

                    <button id="spawn-button" className={"button1 textstyle"} 
                    disabled={loading ? 1 : 0}
                    onClick={(e) => {setFocusInput(false); handlePayoutUpdateClick(e)}}
                    style={{marginTop: "8px", border: "2px solid goldenrod"}} alt="Cashout button">
                    {loading ? (<>Requesting...</>) : (<>Cash out</>)}
                    </button>               
                  </div>
              <div className="notificationsData">
                <Notifications />
              </div>
          {blockchain.wrap && (
            <>  
              <Unwrapper />
            </>
          )}    
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                    {!attackerId && (
                      <img src={require("./assets/images/cashingout.png")} className="portraitHTHMobile" />
                    )} 
                    {attackerId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile" />
                    )} 
                  <div onClick={handleFocus}>
                    <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid goldenrod"}}
                      placeholder="Your Battledog ID"
                      type="number"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                  </div>

                    {errorMessageVisible && 
                     <div className="errordisplay tblue" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
                    
                      <button id="spawn-button" className={"button1 textstyle"} 
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {setFocusInput(false); handlePayoutUpdateClick(e)}}
                      style={{marginTop: "8px", border: "2px solid goldenrod"}} alt="Cashout button">
                      {loading ? (<>Requesting...</>) : (<>Cash out</>)}
                      </button>
                  </div>                  
                  {blockchain.wrap && (
                      <>  
                      <Unwrapper />
                      </>
                  )} 
              </div> 
        </Mobile>
      </>   
    );
  } 

  const RegenerateComponent = () => {
    useEffect(() => {
        if (loading && transactionResult) {
            regenerateAfterCall();  
        } else if (loading && txError) {
          if (isRegenerate) return;
              isRegenerate = true;
            setErrorMessage("Request Cancelled.");
            setErrorMessageVisible(true);
            setLoading(false);
              isRegenerate = false;
        }
    },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
          <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!attackerId && (
                <img src={require("./assets/images/regen.png")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}     

              <div onClick={handleFocus}>
                  <input autoFocus={focusInput} className={"inputform twhite"} style={{border: "3px solid rgb(122, 61, 17)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                    
                  {errorMessageVisible && 
                   <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                   </div> 
                  }
              </div>

                <button className="button1 textstyle"
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); handleRegenerateUpdateClick(e)}}
                style={{border: "2px solid rgb(122, 61, 17)"}}>
                {loading ? (<>Regenerating...</>) : (<>Regenerate</>)}                  
              </button>
          </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                  <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                  <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                  <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>                
                  <div className='mobilecontent'>
              {!attackerId && (
                <img src={require("./assets/images/regen.png")} className="portraitHTHMobile"/>
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTHMobile"/>
              )}      
                  <div onClick={handleFocus}>            
                    <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid rgb(122, 61, 17)"}}
                      type="number"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                  </div>  
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                    <button className={"button1 textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {setFocusInput(false); handleRegenerateUpdateClick(e)}}
                      style={{
                        border: "2px solid rgb(122, 61, 17)"}}>
                      {loading ? (<>Regenerating...</>) : (<>Regenerate</>)} 
                    </button>       
                    
                  </div> 
              </div> 
        </Mobile>
      </>   
    );
  } 

  const ResetTimerComponent = () => {
    useEffect(() => {
        if (loading && transactionResult) {
          if (isResetTimer) return;
            isResetTimer = true;
          setErrorMessage("Reset successful!");
          setErrorMessageVisible(true);
            isResetTimer = false;

          setLoading(false); 
        }  
    },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
          <div className={"translation"} 
              style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              {!attackerId && (
                <img src={require("./assets/images/timer.gif")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}              
                  <div align="center" onClick={handleFocus}>
                  <input autoFocus={focusInput} className={"inputform twhite"} style={{border: "3px solid rgb(113, 50, 2)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />

                  {errorMessageVisible && 
                  <div className="errordisplay tdebilitate" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                  <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                  </div> }
                  
              </div>
                <button className={"button1 textstyle"}
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); handleResetTimerUpdateClick(e)}}
                style={{
                  border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Resetting...</>) : (<>Reset</>)}                
              </button> 
              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent'>
                    {!attackerId && (
                      <img src={require("./assets/images/timer.gif")} className="portraitHTHMobile"/>
                    )} 
                    {attackerId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile"/>
                    )}  
                  <div onClick={handleFocus}>                     
                    <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid rgb(113, 50, 2)"}}
                    type="number"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                    />
                  </div>     

                  {errorMessageVisible && 
                  <div className="errordisplay tdebilitate" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                  <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                  </div> }
                  <button className={"button1 textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={() => {setFocusInput(false); handleResetTimerUpdateClick()}}
                  style={{
                    border: `2px solid ${blockchain.theme}`}}>
                  {loading ? (<>Resetting...</>) : (<>Reset</>)} 
                  </button> 
                    
                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const CustomizeComponent = () => {
      useEffect(()=> {
        if (loading && transactionResult) { 
          customizeAfterCall();
        } else if (loading && txError) {
          if (isCustomize) return;
          isCustomize = true;
          setErrorMessage("Request Cancelled.");
          setErrorMessageVisible(true);
          setLoading(false);
          isCustomize = false;
        }
      },[loading])
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
      <div className="translation" style={{ display: "flex", flexDirection: "column", alignItems: "center" }} align="center">
      <div className={`updatebox updatebox${type}`} align="center" style={{margin: '2vh 0vw 0vh 0vw'}}>
        <p className={`namebox namebox${type}`} onClick={toggleType}>SET NAME</p>
        <p className={`imagebox imagebox${type}`} onClick={toggleType}>SET AVATAR</p>
      </div>
        {!type && (   
          <div id="updatename">          
          {!attackerId && !type && (
            <img src={require("./assets/images/customize.gif")} className="portraitHTH opacity8" />
          )} 
          {attackerId && !type &&(
            <img src={`${TargetA}`} className="portraitHTH" />
          )}             

            <div style={{ padding: "2px" }} onClick={() => {handleFocus(); setFocusInput2(false)}}>
              <input autoFocus={focusInput}
                className={"inputform twhite"}
                style={{ border: "3px solid cyan"}}
                type="text"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div onClick={() => {handleFocus2(); setFocusInput(false)}}>
                <input autoFocus={focusInput2}
                  className={"inputform twhite"}
                  style={{ border: "3px solid cyan"}}
                  type="text"
                  placeholder=" New Battledog Name"
                  value={name}
                  onChange={handleNameChange}
                  alt="New Battledog Name"
                />
              </div>
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); setFocusInput2(false); handleCustomizeUpdateClick(e)}}
                style={{backgroundColor: `${blockchain.theme}` }}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
        )}        
        {type && (  
          <div id="updateimage">          
          {!attackerId && type && (                   
                  <div style={{backgroundColor: `${blockchain.theme}`}} className="nameback portraitHTH">
                  <img src={require("./assets/images/rename-custom.png")}  style={{height: '35vh', width: '35vh'}}/>
                  </div>
          )} 
          {attackerId && type && (                  
                  <div style={{backgroundImage: `${targetA}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}} className="portraitHTH">
                  <img src={require("./assets/images/update-avatar-image.png")} style={{height: '31vh', width: '31vh', margin: '4vh 0vw 0vh 0vw'}} />
                  </div>
          )}     
                   
          <div>
              <div className="upnfont" style={{color: 'white'}}> Acceptable: </div>
              <div className="upnfont" style={{color: `${blockchain.theme}`}}>512 x 512 px , 768 x 768 px , 1024 x 1024 px </div>
              <div className="upnfont" style={{color: 'white'}}> Size Limit: 1 MB </div>
          </div> 
          
          <div style={{ padding: "2px" }} onClick={() => {handleFocus(); setFocusInput2(false)}}>
              <input autoFocus={focusInput}
                className={"inputform twhite"}
                style={{border: "3px solid cyan" }}
                type="text"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div className="updateinput" onClick={() => {handleFocus2(); setFocusInput(false)}}>
              <input autoFocus={focusInput2}
                type="file"
                name={'avatar'}
                ref={fileInput}
              />
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={() => {setFocusInput(false); setFocusInput2(false); handleImageUpload()}}
                style={{backgroundColor: `${blockchain.theme}`}}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
                    
                {errorMessageVisible && 
                  <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                  <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                  </div> 
                }

            </div>
        )}

        </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent' align="center">
      <div className={`updatebox updatebox${type}`} align="center">
        <p className={`namebox namebox${type}`} onClick={toggleType}>SET NAME</p>
        <p className={`imagebox imagebox${type}`} onClick={toggleType}>SET AVATAR</p>
      </div>
        {!type && (   
          <div id="updatename">          
          {!attackerId && !type && (
            <img src={require("./assets/images/customize.gif")} className="portraitHTHMobile opacity8" />
          )} 
          {attackerId && !type &&(
            <img src={`${TargetA}`} className="portraitHTHMobile" />
          )}             

            <div style={{ padding: "2px" }} onClick={() => {handleFocus(); setFocusInput2(false)}}>
              <input autoFocus={focusInput}
                className={"inputformMb twhite"}
                style={{border: "3px solid cyan"}}
                type="number"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div onClick={() => {handleFocus(); setFocusInput(false)}}>
                <input autoFocus={focusInput}
                  className={"inputformMb twhite"}
                  style={{border: "3px solid cyan", marginTop: "0px" }}
                  type="text"
                  placeholder=" New Battledog Name"
                  value={name}
                  onChange={handleNameChange}
                  alt="New Battledog Name"
                />
              </div>
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={(e) => {setFocusInput(false); setFocusInput2(false); handleCustomizeUpdateClick(e)}}
                style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
        )}
        
        {type && (  
          <div id="updateimage">          
          {!attackerId && type && (                   
                  <div style={{backgroundColor: `${blockchain.theme}`}} className="nameback portraitHTHMobile">
                  <img src={require("./assets/images/rename-custom.png")} style={{height: '35vh', width: '35vh'}}/>
                  </div>
          )} 
          {attackerId && type && (                  
                  <div style={{backgroundImage: `${targetA}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}} className="portraitHTHMobile">
                  <img src={require("./assets/images/update-avatar-image.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
          )}     
                   
          <div>
              <div className="upnfont" style={{color: 'white'}}> Acceptable: </div>
              <div className="upnfont" style={{color: `${blockchain.theme}`}}>512 x 512 px , 768 x 768 px <br /> 1024 x 1024 px </div>
              <div className="upnfont" style={{color: 'white'}}> Size Limit: 1 MB </div>
          </div> 
          
          <div style={{ padding: "2px" }} onClick={() => {handleFocus(); setFocusInput2(false)}}>
              <input autoFocus={focusInput}
                className={"inputformMb"}
                style={{ color: "white", border: "3px solid cyan", marginBottom: "0px" }}
                type="number"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div className="updateinput" onClick={() => {handleFocus(); setFocusInput(false)}}>
              <input autoFocus={focusInput}
                type="file"
                name={'avatar'}
                ref={fileInput}
              />
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={() => {setFocusInput(false); setFocusInput2(false); handleImageUpload()}}
                style={{backgroundColor: `${blockchain.theme}` }}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }

            </div>
        )}

                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const WeaponizeComponent = () => {
    useEffect(() => {
        if (loading && transactionResult) {
          weaponizeAfterCall();         
        } else if (loading && txError) {
          if (isWeaponize) return;
              isWeaponize = true;
            setErrorMessage("Request Cancelled.");
            setErrorMessageVisible(true);
            setLoading(false);
              isWeaponize = false;
      }  
    },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
            <div className={"translation"} 
              style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!attackerId && (
                <img src={require("./assets/images/ninja.png")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}              
                <div onClick={handleFocus}>
                    <input autoFocus={focusInput} className={"inputform twhite"} style={{border: "3px solid rgb(240, 240, 192)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }

                </div>
                  <button className={"buttongold textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={(e) => {setFocusInput(false); handleWeaponizeUpdateClick(e)}}
                  style={{
                    border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Weaponizing...</>) : (<>Weaponize</>)}  
                </button>
              </div>     
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                  <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                  <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                  <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                
                <div className='mobilecontent' align="center">
                  {!attackerId && (
                    <img src={require("./assets/images/ninja.png")} className="portraitHTHMobile"/>
                  )} 
                  {attackerId && (
                    <img src={`${TargetA}`} className="portraitHTHMobile"/>
                  )}  
                  <div onClick={handleFocus}>
                    <input autoFocus={focusInput} className={"inputformMb twhite"} style={{border: "3px solid rgb(240, 240, 192)"}}
                      type="number"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                  </div>
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                      </div> 
                    }

                    <button className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={(e) => {setFocusInput(false);handleWeaponizeUpdateClick(e)}} 
                      style={{
                        border: `2px solid ${blockchain.theme}`}}>
                          {loading ? (<>Weaponizing...</>) : (<>Weaponize</>)}                      
                    </button>                           
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  function LockComponent () {
    if (!query)
    return(
      <>
        <Desktop>
              <div className="menu1" title={divisionalTitle} onClick={handleQuery}>
              {allowanceImage && <img src={require(`./assets/images/icons/${allowanceImage}.png`)} className="icons" style={{width: "7.5vw"}} />}
              </div>
        </Desktop>
        <Mobile>
          <>
            <div title={divisionalTitle} align="center" onClick={handleQuery}>          
            {allowanceImage && (<p>{divisionalTitle} <br/> <img src={require(`./assets/images/icons/${allowanceImage}.png`)} style={{height: "40px"}}/>
            </p>)}
            </div>
          </>
        </Mobile>
      </>
    )

    if (query) 
      return (
      <>
      <Desktop>        
      <div onClick={handleQuery}>
              <div className="aplist" title={`Found ${activePlayers.length} Battledogs in the Arena`} onClick={handleScoreboard}>{formatNumber(activePlayers.length)}</div>
              <div className="menu1 wolfslash" title={`Currently ${allPlayers} Battledog NFTs exist`} >
              </div>
              <div className="allPlayersText" title={`Currently ${allPlayers} Battledog NFTs exist`} style={{color: `${blockchain.theme}`}} >
                {formatNumber(allPlayers)}
              </div>
      </div>
      </Desktop>
      <Mobile>
      <div align="center" onClick={handleQuery}>
        <div className="wolfslashmb" title={`Currently ${allPlayers} Battledog NFTs exist`} style={{color: `${blockchain.theme}`}} >Currently {formatNumber(allPlayers)} Battledog NFTs exist<br/> <img src={require('./assets/images/wolfslash.png')} style={{height: "40px"}} /></div>
        <div className="aplistmb" title={`Found ${activePlayers.length} Battledogs in the Arena`} onClick={handleScoreboard}>Found {formatNumber(activePlayers.length)} Battledogs in the Arena</div>
      </div>
      </Mobile>
    </>
    );
  }

  function ScoreComponent () {       
    return (
      <>
      <Desktop>      
      {yours && (
        <div className="minileaderboard" align="center" onClick={handleLeaderboard}>
          <span className="miniText" style={{color: `${blockchain.theme}`}}>XP</span>
          <div style={{border: `1px solid ${blockchain.theme}`, borderRadius: "20vh", width: "5vw", opacity: "0.2"}}></div>
          <div className="mkSmall" style={{color: "gray", opacity: "0.7"}}>{(yours.score).toFixed(1)}</div>
        </div>
      )}
      </Desktop>
      <Mobile>         
      {yours && (
      <div className="minileaderboardMB" onClick={handleLeaderboard} style={{alignItems: "flex-end"}}>
        <div className="mkMicro" style={{color: "gray", opacity: "0.7"}}>Score Points: <br/> 
          <span className="mkSmall" style={{color: `${blockchain.theme}`}}> {(yours.score).toFixed(1)}</span><span className="mkMicro"> XP</span>
        </div>
      </div>    
      )}
      </Mobile>
      </>
    );
  }

  const LayoutComponent = () => {
    return (
      <>
      <Desktop>
        <div className='layoutDiv mkMicro' style={{color: `${blockchain.theme}`}} align='center'>
            <NativeBalance /> 
          <PiInfinityLight style={{color: 'whitesmoke', margin: "0vh 1vh 0vh 1vh"}}/>
            <GameBalance />
          {claims && (
            <div onClick={handleMining}>
              <PiInfinityLight style={{color: 'whitesmoke', margin: "0vh 1vh 0vh 1vh"}}/>
              <span className="layoutBox mkMicro" style={{color: 'black', backgroundColor: `${blockchain.theme}`, cursor: 'pointer'}}>{claims} Unclaimed</span>
                {remainingTime > 0 ? (
                <span title={`Locked Claim: Claimable only after ${new Date(remainingTime).toLocaleString()}`}><BsShieldLock /></span>
                ) : (
                <span title={"No locked claims"}><BsShieldLock /></span>
                )} 
            </div>
          )}
        </div>
      </Desktop>
      <Mobile>
        <div className='layoutDivMobile mkNano' style={{color: `${blockchain.theme}`}} align='center'>
            <NativeBalance /> 
          <PiInfinityLight style={{margin: "0vh 1vh 0vh 1vh"}}/>
            <GameBalance />
          {claims && (
            <div onClick={handleMining}>
              <PiInfinityLight style={{color: 'whitesmoke', margin: "0vh 1vh 0vh 1vh"}}/>
              <span className="layoutBoxMobile mkNano" style={{color: 'black', backgroundColor: `${blockchain.theme}`, cursor: 'pointer'}}>{claims} Unclaimed</span>
            </div>
          )}
        </div>
        <div align='center' style={{textShadow: '2px 2px 8px black', margin: '0px 0px 1vh 0px'}}>
          {remainingTime > 0 ? (
            <div className='mkNano' style={{color: `${blockchain.theme}`}}>Locked Claim: Claimable only after {new Date(remainingTime).toLocaleString()} <BsShieldLock /></div>
          ) : (
            <div className='mkNano' style={{color: `${blockchain.theme}`}}><BsShieldLock /> No locked claims <BsShieldLock /></div>
          )} 
        </div>
      </Mobile>
      </>
    );
  }


  const Notifications = () => {  
    return (
    <>
    <Desktop>
      <>
        <div>
          <div align='center'><button className={"tblack nbmisc"} onClick={handleHideNotifications}
          style={buttonStyle}> {hideNotifications ? "Hide Notifications" : "View Notifications"}</button></div>
          <div className={"textstyle twhitesmoke"} style={{fontWeight: "bold"}}>Monitor Feeds: Yours, Allies or Enemies </div>
          <div className={"notifications"} style={{display: hideNotifications ? 'flex' : 'none'}}>
          {allLogs.slice().reverse().map((log, index) => {
            switch(log.type) {
              case 'mint':
                return (
                  <div key={index} className="notify notifyText" style={{background: "linear-gradient(180deg, violet, white)"}} onClick={handleMint}>              
                    <GiWarPick/>
                    <img className="notifyImg brPink" src={(`https://battledogs.fun/engine/${log.tokenId}.png`)} alt="Minter" onClick={handleMint}/>
                    <span style={{color:'maroon', fontFamily: 'mkMyth'}}> {log.name} Arrives </span>!
                  </div>
                );
              case 'assault':
                return (
                  <div key={index} className="notify notifyText twhite" style={{background: "linear-gradient(180deg, rgb(82, 58, 50), black)"}}>
                      <GiZeusSword/>
                      <img className="notifyImg brwhite" src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" onClick={(e) => {handleListenerClick(e, log.attackerId, handleAssault)}} />
                      <span style={{color: `${blockchain.theme}`, fontSize: '2.75vh'}}> {log.attackerId} </span> 
                      <span className="mkMolecule">VS</span> <span style={{color: `${blockchain.theme}`, fontSize: '2.75vh'}}> {log.defenderId} </span>
                      <img className="notifyImg brwhite" src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" onClick={(e) => {handleListenerClick(e, log.defenderId, handleAssault)}}/>
                      <span className="mkMolecule">for </span><span style={{color: `${blockchain.theme}`, fontSize: '2.75vh'}}> {log.stolenPoints}</span><span className="mkMolecule twhite"> points!</span>
                  </div> 
                );
              case 'debilitate':
                return (
                  <div key={index} className="notify notifyText twhite" style={{background: "linear-gradient(180deg, teal, blue)"}}>
                    <Gi3dHammer/>
                      <img className="notifyImg brBlue" src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" onClick={(e) => {handleListenerClick(e, log.attackerId, handleDebilitate)}} />
                      <span style={{color: `${blockchain.theme}`, fontSize: '2.75vh'}}> {log.attackerId} </span> 
                      <span className="mkMolecule" >VS</span> <span style={{color: `${blockchain.theme}`, fontSize: '2.75vh'}}> {log.defenderId} </span>
                      <img className="notifyImg brBlue" src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" onClick={(e) => {handleListenerClick(e, log.defenderId, handleDebilitate)}} />
                      <span className="mkMolecule">for </span><span style={{color: `${blockchain.theme}`, fontSize: '2.75vh'}}> {log.stolenPoints} </span><span className="mkMolecule"> points!</span>
                  </div>
                );
                
              case 'assaultwin':        
                return (
                  <div key={index} className={"notify notifyText"} style={{background: "linear-gradient(180deg, gold, black)"}} onClick={handleClaimAssault}>
                      <GiTrophyCup/>
                      <img className={"notifyImg brGold"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="AssaultPayout" />
                      <span> {getPlayerName(log.playerId)} just claimed </span><span style={{color: `${blockchain.theme}`}}>{log.payreward / reducer} ${blockchain.tokenA}</span>!
                  </div>
                );

              case 'debilitatewin':
                return (
                  <div key={index} className={"notify notifyText twhite"} style={{background: "linear-gradient(180deg, indigo, black)"}} onClick={handleClaimDebilitate}>
                      <GiLaurelsTrophy/>
                      <img className={"notifyImg brwhite"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="DebilitatePayout"/>
                      <span> {getPlayerName(log.playerId)} just claimed </span><span style={{color: "white"}}>{log.payreward / reducer} ${blockchain.tokenA}</span>!
                  </div>
                );
              
              case 'levelup':
                return (
                  <div key={index} className={"notify notifyText"} style={{background: "linear-gradient(180deg, orange, black)"}} onClick={handleLevelUp}>
                  <GiStarMedal/>
                    <img className={"notifyImg brOrange"} src={(`https://battledogs.fun/engine/${log.playerId}.png`)} alt="LevelUp" />
                    <span> {getPlayerName(log.playerId)} levels up to </span><span style={{color: `${blockchain.theme}`}}>{log.level}</span>!
                  </div>
                );
              case 'payout': 
              return (
                <div key={index} className={"notify notifyText"} style={{background: "linear-gradient(180deg, goldenrod, black)"}} onClick={handlePayout}>
                    <GiWallet/> 
                    <img className={"notifyImg brMagenta"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="CashOut" />
                    <span> {getPlayerName(log.playerId)} just cashed out </span><span style={{color: "gold"}}>{log.amount / decimals} ${blockchain.tokenA}</span>!
                </div>
              );
              default:
                return null;
            }
          })}
          </div>        
        </div> 
      </>
    </Desktop>
    <Mobile>
      <>
          {allLogs.slice().reverse().map((log, index) => {
            switch(log.type) {
              case 'mint':
                return (
                  <div key={index} className="notifyMb notifyText" style={{background: "linear-gradient(180deg, violet, white)", justifyContent: 'center'}} onClick={handleMint}>           
                  <GiWarPick/>
                  <img className="notifyImg brPink" src={(`https://battledogs.fun/engine/${log.tokenId}.png`)} alt="Minter" onClick={handleMint} />
                  <span style={{color:'maroon', fontFamily: 'mkMyth'}}> {log.name} Arrives </span>!          
                  </div>                
                );
              case 'assault':
                return (
                  <div key={index} className="notifyMb notifyText twhite" style={{background: "linear-gradient(180deg, rgb(82, 58, 50), black)"}}>
                      <GiZeusSword/>
                      <img className="notifyImg brwhite" src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" onClick={(e) => handleListenerClick(e, log.attackerId, handleAssault)}/>
                      <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.attackerId} </span> 
                      VS
                      <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.defenderId} </span>
                      <img className="notifyImg brwhite" src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" onClick={(e) => handleListenerClick(e, log.attackerId, handleAssault)}/>
                      for <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.stolenPoints} </span> points!
                  </div> 
                );
              case 'debilitate':
                return (
                  <div key={index} className="notifyMb notifyText twhite" style={{background: "linear-gradient(180deg, teal, blue)"}}>
                      <img className="notifyImg brBlue" src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" onClick={(e) => handleListenerClick(e, log.attackerId, handleDebilitate)}/>
                      <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.attackerId} </span> 
                      VS 
                      <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.defenderId} </span>
                      <img className="notifyImg brBlue" src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" onClick={(e) => handleListenerClick(e, log.defenderId, handleDebilitate)}/>
                      for <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.stolenPoints} </span> points!
                  </div>
                );
                
              case 'assaultwin':        
                return (
                  <div key={index} className="notifyMb notifyText" style={{background: "linear-gradient(180deg, gold, black)"}} onClick={handleClaimAssault}>
                      <GiTrophyCup/>
                      <img className="notifyImg brGold" src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="AssaultPayout" />
                      <span> {getPlayerName(log.playerId)} just claimed </span><span style={{color: `${blockchain.theme}`}}>{log.payreward / reducer} ${blockchain.tokenA}</span>!
                    </div>
                );

              case 'debilitatewin':
                return (
                  <div key={index} className="notifyMb notifyText twhite" style={{background: "linear-gradient(180deg, indigo, black)"}} onClick={handleClaimDebilitate}>
                    <GiLaurelsTrophy/>
                      <img className="notifyImg brwhite" src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="DebilitatePayout" />
                      <span> {getPlayerName(log.playerId)} just claimed </span><span style={{color: `${blockchain.theme}`}}>{log.payreward / reducer} ${blockchain.tokenA}</span>!
                    </div>
                );
              
              case 'levelup':
                return (
                  <div key={index} className="notifyMb notifyText" style={{background: "linear-gradient(180deg, orange, black)"}} onClick={handleLevelUp}>
                  <GiStarMedal/>
                  <img className="notifyImg brOrange" src={(`https://battledogs.fun/engine/${log.playerId}.png`)} alt="LevelUp" />
                    <span> {getPlayerName(log.playerId)} levels up to </span><span style={{color: `${blockchain.theme}`}}>{log.level}</span>!
                  </div>
                );
              case 'payout': 
              return (
                <div key={index} className="notifyMb notifyText" style={{background: "linear-gradient(180deg, goldenrod, black)"}} onClick={handlePayout}>
                  <GiWallet/>
                    <img className="notifyImg brMagenta" src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="CashOut" />
                    <span> {getPlayerName(log.playerId)} just cashed out </span><span style={{color: "gold"}}>{log.amount / decimals} ${blockchain.tokenA}</span>!
                  </div>
              );
              // Other cases for each log type
              default:
                return null;
            }
          })}   
        </>
        {minerLogs && (
          <div className="notify texttheme" style={{color: `${blockchain.theme}`}}>
          Reward claim successful!  
          <span style={{color: "white"}}>Receipent:</span> 
          {truncateAddress(minerLogs.sender)} 
          <span style={{color: "white"}}>Rewards:</span> {minerLogs.rewards/(10**18).toFixed(2)} GAME
          </div>
        )}
        {harvesterLogs && (
          <div className="notify texttheme" style={{color: `${blockchain.theme}`}}>
            Reward claim successful! 
            <span style={{color: "white"}}>Receipent:</span> 
            {truncateAddress(harvesterLogs.sender)} 
            <span style={{color: "white"}}>Rewards:</span> {harvesterLogs.rewards/(decimals).toFixed(blockchain.tofixed)}
            {!stakeType ? (<span> {blockchain.tokenA}</span>):(<span> GAME</span>)}
            </div>
        )}  
    </Mobile>
    </>
    );
  }
  
  const ActivityTrackerComponent = () => {
    return (
      <>
      <Desktop>
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
        <div className={"activitytracker"} align={"center"}>
            <div>
            <div className="actheader" onClick={handleScoreboard}>
              <img className='scoreboardImg' src={require("./assets/images/activitytracker.png")}
              style={{backgroundColor: `${blockchain.theme}`, height: "6vh"}}/></div>
              <div>
              <button className={"buttongold tred"} onClick={getAssaulters} disabled={loading ? 1 : 0} style={{backgroundColor: 'pink'}}>Track Assaulters</button>
              <button className={"buttongold tblue"} onClick={getDebilitators} disabled={loading ? 1 : 0} style={{backgroundColor: 'blue'}}>Track Debilitators</button>
              </div>             
          {mappedAssaulters}
          {mappedDebilitators}
          </div>
        
        </div>

        <div className={"notifyInput"} align={"center"}>
        <div className={"texttheme twhite"} style={{fontWeight: "bold"}}>Track Battledog Activity</div>
        <form  onSubmit={(e) => {handleSubmitter(e, handleActivityTrackerSubmit)}}> 
          <input name="input1" 
          className={"inputform"} style={{color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
                type="text" 
                placeholder="Enter ID(s) separated only by commas"
          />
          <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Monitor</button>
          <button className="button1 txt" onClick={viewAll} style={{border: `2px solid ${blockchain.theme}`}}>View All</button>
         </form>

        <div className={"texttheme twhite"}style={{fontWeight: "bold"}}>Ignore Battledog</div>
        <form  onSubmit={(e) => {handleSubmitter(e, handleRemove)}}> 
            <input name="input1" 
            className={"inputform"} style={{color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
            type="text"
            placeholder="Enter ID(s) separated only by commas"
            />  
            <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Remove</button>
            <button className="button1 txt" onClick={handleWipe} style={{
  border: `2px solid ${blockchain.theme}`}}>Wipe All</button>
        </form>
            
          

      </div>  
     <div className="hunted" style={{color: `${blockchain.theme}`}}>
     <span style={{color: "white", fontSize: "30px"}}><img src={require("./assets/images/eye.gif")} style={{height: '10vh'}} /></span>
     {handleTrackerView()}
     </div>
      </Desktop>
      <Mobile>             
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`}  target = "_blank" rel="noreferrer"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div> 
                <div align="center">              
                  <img className="activityTrackerImgMobile" src={require("./assets/images/activitytracker.png")} style={{backgroundColor: `${blockchain.theme}`, padding: "1vw"}} onClick={handleScoreboard}/>
                </div>        
              <div className="mobileye" align="center" style={{alignItems: "center", justifyContent: "center"}}>
              <span style={{color: "white", fontSize: "30px"}}><img src={require("./assets/images/eye.gif")} style={{height: '8vh'}} /></span>
              {handleTrackerView()}
              </div>
              <div className='mobilecontent' align="center">
                <div>
                <div onClick={handleScoreboard}></div>
                  {mappedAssaulters}
                  {mappedDebilitators}
                    <div>
                    <button className={"buttongold brPink tred"} onClick={getAssaulters} disabled={loading ? 1 : 0} style={{backgroundColor: 'pink'}}>Track Assaulters</button>
                    <button className={"buttongold brBlue tblue"} onClick={getDebilitators} disabled={loading ? 1 : 0} style={{backgroundColor: 'blue'}}>Track Debilitators</button>
                  </div> 
                </div>                    
              </div>
              <div className="mobilecontent" align="center">
                <p className={"texttheme twhite"} style={{fontFamily: "NexaHeavy", fontWeight: "bold", marginTop: "4px"}}>Track Battledog Activity</p>
                <form  onSubmit={(e) => {handleSubmitter(e, handleActivityTrackerSubmit)}}> 
                <input name="input1" 
                  className={"inputformMb"} style={{color: "white", border: "3px solid goldenrod"}}
                        type="text" 
                        placeholder="Enter ID(s) separated by commas"
                        />
                </form>
                <div style={{marginBottom: "4px"}}>
                  <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Monitor</button>
                  <button className="button1 txt" onClick={viewAll} style={{border: `2px solid ${blockchain.theme}`}}>View All</button>
                </div>
                <p className={"texttheme twhite"} style={{fontFamily: "NexaHeavy", fontWeight: "bold", marginTop: "4px"}}>Ignore Battledog</p>
                <form  onSubmit={(e) => {handleSubmitter(e, handleRemove)}}> 
                    <input name="input1" 
                    className={"inputformMb"} style={{color: "white", border: "3px solid goldenrod"}}
                    type="text"
                    placeholder="Enter ID(s) separated by commas"
                    />  
                </form>
                <div style={{marginBottom: "4px"}}>
                  <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Remove</button>
                  <button className="button1 txt" onClick={handleWipe} style={{border: `2px solid ${blockchain.theme}`}}>Wipe All</button>
                </div>
              </div>        
      </Mobile>
      </>
    );
  }

  // Help components

  const StartHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleMint}>
              <div className="helpCol"  >
                <p className="mkSmall"><span className="tmint">1.</span> Mint a Battledog NFT </p>
                <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
              </div>                
                <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">2.</span> Approve enough {blockchain.tokenA} for gameplay  </p>
                <img src={require('./assets/images/approve-vis.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
              </div>                
              </div>
              <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <div className="mkSmall"><span className="tmint">3.</span> Activate your Battledog NFT </div> 
                <div className="mkNano tmint">Hint: You can mine more {blockchain.tokenB} than other players by forfeiting wins</div>
                <img src={require('./assets/images/activate.jpg')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleMint}>
                <p className="mkNano"><span className="tmint">1.</span> Mint a Battledog NFT </p>
                <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Approve enough {blockchain.tokenA} for gameplay  </p>
                <img src={require('./assets/images/approve-vis.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> Activate your Battledog NFT.</p>
                <div className="mkNano tmint">Hint: You can mine more {blockchain.tokenB} <br />than others by forfeiting wins</div>
                <img src={require('./assets/images/activate.jpg')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 

    // Help components

    const ActivateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Activate </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleActivate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> There are two main tiers of Activation </p>
                  <div className="mkNano tmint">Hint: <span className='torange'>First tier</span> is the very first time you activate a Battledog. The <span className='torange'>Other Tiers</span> are subsequent times you chose to Activate Up That Battledog</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Pay {activatingAmount} {blockchain.tokenA} For 1st Time Activations </p>
                  <div className="mkNano tmint">Hint: This is a <span className='torange'>One-time-fee</span> required to enter your Battledog NFT into the Arena</div>
                  <img src={require('./assets/images/one.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/guidance.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span><span className='torange'> Burn </span> at least {charge} {blockchain.tokenB} to Activate Up </p> 
                  <div className="mkNano tmint">Hint: Whenever you choose, you can <span className='torange'>burn</span> multiple fees in {blockchain.tokenB} forever to boost your Battledogs</div>
                  <img src={require('./assets/images/four.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center">How to Activate</div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleActivate}>
                  <p className="mkNano"><span className="tmint">1.</span> There are two types of Activation </p>
                  <div className="mkNano tmint">Hint: <span className='torange'>First tier</span> is the very first time you activate a Battledog. The <span className='torange'>Other Tiers</span> are subsequent times you chose to Activate Up That Battledog</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Pay {activatingAmount} {blockchain.tokenA} For 1st Time Activations </p>
                  <div className="mkNano tmint">Hint: This is a <span className='torange'>One-time-fee</span> required to enter your Battledog NFT into the Arena</div>
                  <img src={require('./assets/images/one.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/swap.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span><span className='torange'> Burn </span> at least {charge} {blockchain.tokenB} to Activate Up</p>
                  <div className="mkNano tmint">Hint: Whenever you choose, you can <span className='torange'>burn</span> multiple fees in {blockchain.tokenB} forever to boost your Battledogs</div>
                  <img src={require('./assets/images/four.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }
  
    const AssaultHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Attack </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain. Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/archery.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p> 
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/assault.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center">How to Attack</div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
                  <p className="mkNano"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain.  Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/archery.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p>
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/assault.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }
  
    const DebilitateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Attack </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleDebilitate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain. Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/slow-cooker.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p> 
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/snail.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center">How to Attack</div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleDebilitate}>
                  <p className="mkNano"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain.  Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/slow-cooker.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p>
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/snail.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    } 
  
    const WeaponizeHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> Quick Tutorial </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleWeaponize}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> You can Weaponize Any Battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Aggression</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each weaponization, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/swords_no.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> No Limits or Rewards to weaponization </p> 
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can weaponize a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/swords.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> Quick Tutorial </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleWeaponize}>
                  <p className="mkNano"><span className="tmint">1.</span> You can weaponize any battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Aggression</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each weaponization, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/swords_no.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> No Limits or Rewards to weaponization </p>
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can weaponize a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/swords.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }  
  
    const RegenerateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Regenerate </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleRegenerate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> You can Regenerate Any Battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Stamina</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each regeneration, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/coca.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> No Limits or Rewards to Regeneration </p> 
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can regenerate a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/pottery.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Regenerate </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleRegenerate}>
                  <p className="mkNano"><span className="tmint">1.</span> You can regenerate any battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Stamina</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {requiredAmount} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each regeneration, you pay <span style={{color: `${blockchain.theme}`}}>{requiredAmount} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/coca.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> No Limits or Rewards to Regeneration </p>
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can regenerate a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/pottery.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }   
  
    const ClaimAssaultHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Claim A Win </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimAssault }>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it an <span style={{color: `${blockchain.theme}`}}>Assault or Debilitate</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimAssault.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p> 
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Claim A Win </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimAssault}>
                  <p className="mkNano"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it an <span style={{color: `${blockchain.theme}`}}>Assault or Debilitate</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimAssault.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p>
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }    
  
    const ClaimDebilitateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Claim A Win </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimDebilitate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it a <span style={{color: `${blockchain.theme}`}}>Debilitate or Assault</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimDebilitate.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p> 
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Claim A Win </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimDebilitate}>
                  <p className="mkNano"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it a <span style={{color: `${blockchain.theme}`}}>Debilitate or Assault</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimDebilitate.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p>
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const LevelUpHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Level Up </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleLevelUp}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Leveled Up Battledogs are hard to defeat </p>
                  <div className="mkNano tmint">Hint: The onchain game theory behind this is because battledogs of <span style={{color: `${blockchain.theme}`}}>higher levels earn 20 points</span> for attacks on Battledogs of lower levels, while the reverse earns the attacker only 10 points </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> You need to <span className='tOrange'>burn</span> {blockchain.tokenB} to Level Up </p>
                  <div className="mkNano tmint">Hint: Burning {blockchain.tokenB} to level up isn't a fixed amount but a fixed ratio calculated as the direct multiple of your <span style={{color: `${blockchain.theme}`}}>current Activation Level</span> against {charge} {blockchain.tokenB} </div>
                  <img src={require('./assets/images/progress.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Ensure you have sufficient {blockchain.tokenB} approved </p> 
                  <div className="mkNano tmint">Hint: All {blockchain.tokenB} spent while leveling up your Battledog is burnt forever, completely and transparently onchain. <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Level Up </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleLevelUp}>
                  <p className="mkNano"><span className="tmint">1.</span> Leveled Up Battledogs are hard to defeat </p>
                  <div className="mkNano tmint">Hint: The onchain game theory behind this is because battledogs of <span style={{color: `${blockchain.theme}`}}>higher levels earn 20 points</span> for attacks on Battledogs of lower levels, while the reverse earns the attacker only 10 points </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> You need to <span className='tOrange'>burn</span> {blockchain.tokenB} to Level Up </p>
                  <div className="mkNano tmint">Hint: Burning {blockchain.tokenB} to level up isn't a fixed amount but a fixed ratio calculated as the direct multiple of your <span style={{color: `${blockchain.theme}`}}>current Activation Level</span> against {charge} {blockchain.tokenB} </div>
                  <img src={require('./assets/images/progress.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Ensure you have sufficient {blockchain.tokenB} approved </p>
                  <div className="mkNano tmint">Hint: All {blockchain.tokenB} spent while leveling up your Battledog is burnt forever, completely and transparently onchain. <span style={{color: `${blockchain.theme}`}}>100 additional points</span> means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const PayoutHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Cash Out </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Cashing out {blockchain.tokenA} to your wallet is easy. </p>
                  <div className="mkNano tmint">Hint: As long as all conditions for winning are met, you can choose to cash out all <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} won</span> while playing Battledogs Arena </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Level Up Your Battledog <span className='tOrange'>At Least Once</span> </p>
                  <div className="mkNano tmint">Hint: To Ensure Players Are Actual Players, We Require the Battledog withdrawing its earnings in {blockchain.tokenA} to level up at least once in the game</div>
                  <img src={require('./assets/images/icons/wallet_no.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Have At Least 5 Wins under its belt </p> 
                  <div className="mkNano tmint">Hint: The Minimum Threshold for making a withdrawal in {blockchain.tokenA} is <span style={{color: `${blockchain.theme}`}}>5 wins</span>  This is spent for each withdrawal.</div>
                  <img src={require('./assets/images/icons/wallet.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Cash Out </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
                  <p className="mkNano"><span className="tmint">1.</span> Cashing out {blockchain.tokenA} to your wallet is easy. </p>
                  <div className="mkNano tmint">Hint: As long as all conditions for winning are met, you can choose to cash out all <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} won</span> while playing Battledogs Arena </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Level Up Your Battledog <span className='tOrange'>At Least Once</span> </p>
                  <div className="mkNano tmint">Hint: Burning {blockchain.tokenB} to level up isn't a fixed amount but a fixed ratio calculated as the direct multiple of your <span style={{color: `${blockchain.theme}`}}>current Activation Level</span> against {charge} {blockchain.tokenB} </div>
                  <img src={require('./assets/images/icons/wallet_no.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Have At Least 5 Wins under its belt </p>
                  <div className="mkNano tmint">Hint: The Minimum Threshold for making a withdrawal in {blockchain.tokenA} is <span style={{color: `${blockchain.theme}`}}>5 wins</span> This is spent for each withdrawal.</div>
                  <img src={require('./assets/images/icons/wallet.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const ResetTimerHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Reset </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleResetTimer}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Our Reset is an anti-bot Feature</p>
                  <div className="mkNano tmint">Hint: Once a Battledog's transactions exceed close to <span style={{color: `${blockchain.theme}`}}>1000 transactions during gameplay</span> that Battledog needs to reset itself to play </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> A Reset Can Be Done At Any Time </p>
                  <div className="mkNano tmint">Hint: Although a reset feature is present, to Avoid Abuse There is also a <span style={{color: `${blockchain.theme}`}}>blacklist feature</span> in Battledogs Arena </div>
                  <img src={require('./assets/images/icons/resetTimer.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Anti-Bot Protection & Blacklisting </p> 
                  <div className="mkNano tmint">Hint: If we notice an <span style={{color: `${blockchain.theme}`}}>abuse of gameplay</span> or our reset feature, our blacklist mechanism intercedes </div>
                  <img src={require('./assets/images/robot.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Reset </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleResetTimer}>
                  <p className="mkNano"><span className="tmint">1.</span> Our Reset is an anti-bot feature</p>
                  <div className="mkNano tmint">Hint: Once a Battledog's transactions exceed close to <span style={{color: `${blockchain.theme}`}}>1000 transactions during gameplay</span> that Battledog needs to reset itself to play </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> A Reset Can Be Done At Any Time </p>
                  <div className="mkNano tmint">Hint: Although a reset feature is present, to Avoid Abuse There is also a <span style={{color: `${blockchain.theme}`}}>blacklist feature</span> in Battledogs Arena </div>
                  <img src={require('./assets/images/icons/resetTimer.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Anti-Bot Protection & Blacklisting </p>
                  <div className="mkNano tmint">Hint: If we notice an <span style={{color: `${blockchain.theme}`}}>abuse of gameplay</span> or our reset feature, our blacklist mechanism intercedes </div>
                  <img src={require('./assets/images/robot.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const CustomizeHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> NFT Customization </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleCustomize}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Our Gaming NFTs are fully customizable </p>
                  <div className="mkNano tmint">Hint: A more self-tailored experience for players despite being on an immutable blockchain </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Rename Any time, All the time, for free </p>
                  <div className="mkNano tmint">Hint: Scare off attackers with a diehard name. Your Name Stays Onchain </div>
                  <img src={require('./assets/images/notebook.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Customizable Battledog Avatar Soon </p> 
                  <div className="mkNano tmint">Hint: A coming feature is to allow you customize & upload your own Battledog art. Artwork you feel best expresses or represents you </div>
                  <img src={require('./assets/images/customize_no.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> NFT Customization </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleCustomize}>
                  <p className="mkNano"><span className="tmint">1.</span> Our Gaming NFTs are fully customizable </p>
                  <div className="mkNano tmint">Hint: A more self-tailored experience for players despite being on an immutable blockchain </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Rename Any time, All the time, for free </p>
                  <div className="mkNano tmint">Hint: Scare off attackers with a diehard name. Your Name Stays Onchain </div>
                  <img src={require('./assets/images/notebook.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Customizable Battledog Avatar Soon </p>
                  <div className="mkNano tmint">Hint: A coming feature is to allow you customize & upload your own Battledog art. Artwork you feel best expresses or represents you </div>
                  <img src={require('./assets/images/customize_no.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }

  const ArenaHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
              <div className="helpCol mkNano">
                <p className="mkSmall"><span className="tmint">1.</span> Assault another NFT</p>
                <img src={require('./assets/images/img-2a.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div> to steal points from its aggression </div>
              </div>                
                <img src={require('./assets/images/guidance.gif')} style={{height: "10vh"}} />
              <div>
              <div className="helpCol mkNano">
                <p className="mkSmall"><span className="tmint">2.</span> Debilitate another NFT</p>
                <img src={require('./assets/images/img-1a.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div> to steal points from its Stamina </div>
              </div>                
              </div>
              <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
              <div>
              <div className="helpCol mkNano">
                <p className="mkSmall"><span className="tmint">3.</span> For every 100 points </p>
                <img src={require('./assets/images/startfighters.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div>you can claim a win</div>
                <div className=' tmint'>either an Assault Win or Debilitate win </div>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
                <p className="mkNano"><span className="tmint">1.</span> Assault another NFT</p>
                <img src={require('./assets/images/img-2a.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className='mkNano'> to steal points from its aggression </div>
                <img src={require('./assets/images/swap.gif')} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Debilitate another NFT </p>
                <img src={require('./assets/images/img-1a.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className='mkNano'> to steal points from its Stamina </div>
                <img src={require('./assets/images/down-arrow.gif')} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> For every 100 points </p>
                <img src={require('./assets/images/startfighters.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano">you can claim a win</div>
                <div className='mkNano tmint'>either an Assault Win or Debilitate win </div>
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 
  
  const WinningsHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
              <div className="helpCol"  >
                <p className="mkSmall"><span className="tmint">1.</span> Levelups win more game points</p>
                <img src={require('./assets/images/leveler.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins & burn {blockchain.tokenB} to level up. </div>
              </div>                
                <img src={require(`./assets/images/one-way.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">2.</span> Winners get rewarded with {blockchain.tokenA}</p>
                <img src={require(`./assets/images/coins.jpg`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins using Payout to cashout {blockchain.tokenA}.  </div>
              </div>                
              </div>
              <img src={require(`./assets/images/peso.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">3.</span> Expend 5 wins to cash out or upgrade </p>
                <img src={require(`./assets/images/leader.jpg`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Hint: for every Activation Tier up, you mine more {blockchain.tokenB} than others. </div>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
                <p className="mkNano"><span className="tmint">1.</span> Levelups win more game points</p>
                <img src={require(`./assets/images/leveler.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins & burn {blockchain.tokenB} to level up. </div>
                <img src={require(`./assets/images/one-way.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Winners get rewarded with {blockchain.tokenA}</p>
                <img src={require(`./assets/images/coins.jpg`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins using Payout to cashout {blockchain.tokenA}.</div>
                <img src={require(`./assets/images/peso.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> Expend 5 wins to cash out or upgrade </p>
                <img src={require(`./assets/images/leader.jpg`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Hint: for every Activation Tier up, you mine more {blockchain.tokenB} than others. </div>
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 
  
  const ToolsHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleHarvester}>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">1.</span> Farm {blockchain.tokenA} or mine {blockchain.tokenB} </p>
                <img src={require(`./assets/images/farmer.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
              </div>                
                <img src={require(`./assets/images/tools.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">2.</span> Farm {blockchain.tokenA} by staking {blockchain.tokenB}</p>
                <img src={require(`./assets/images/networks/network${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
              </div>                
              </div>
              <img src={require(`./assets/images/tools.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">3.</span> Mine {blockchain.tokenB} weekly </p>
                <img src={require(`./assets/images/miner.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className='mkNano tmint'>By playing more frequently <br />with each Battledog NFT</div>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleHarvester}>
                <p className="mkNano"><span className="tmint">1.</span> Farm {blockchain.tokenA} or mine {blockchain.tokenB} </p>
                <img src={require(`./assets/images/farmer.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Farm {blockchain.tokenA} by staking your {blockchain.tokenB} tokens </p>
                <img src={require(`./assets/images/networks/network${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> Mine {blockchain.tokenB} weekly</p>
                <img src={require(`./assets/images/miner.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className='mkNano tmint'>By playing more frequently <br/>with each Battledog NFT</div>
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 

  const CreateKlan = () => {
  return (
    <>
    {blockchain.klanmode && (
      <div align="center">
        {/* <button className="klanButton" style={{backgroundColor: "gray"}} onClick={handleDestroyKlan}><img src={require("./assets/images/icons/key.gif")} style={{height: "4vh"}} /> Destroy Klan </button> */}
        <button className="klanButton" style={{backgroundColor: `${blockchain.theme}`}} onClick={handleCreateKlan}>Create Klan </button>
      </div>
    )}
    </>
  )}

  //sound States & Effects **************************************************************************************************
  const [soundtrack, setSoundtrack] = useState();
  const [audioPlaying, setAudioPlaying] = useState(true);
  const [volumeLevel, setVolumeLevel] = useState(0.5);
  const [volIcon, setVolIcon] = useState('vol-mute');
  const [isHovered, setIsHovered] = useState(false);
  const [soundEffectUrl, setSoundEffectUrl] = useState(null);
  const [isEffectPlaying, setIsEffectPlaying] = useState(false);

  const playSoundEffect = (effect) => {
    setSoundEffectUrl(effect);
    setIsEffectPlaying(true);
  };

  const playThunder = () => {
    playSoundEffect(soundeffects.Thunder);
  }


  const playEnergy = () => {
    playSoundEffect(soundeffects.Energy);
  }

  const playFastwhoosh = () => {
    playSoundEffect(soundeffects.Fastwhoosh);
  }

  const playRumble = () => {
    playSoundEffect(soundeffects.Rumble);
  }

  const playStake = () => {
    playSoundEffect(soundeffects.Stake);
  }

  const playWithdraw = () => {
    playSoundEffect(soundeffects.Withdraw);
  }

  const playClaim = () => {
    playSoundEffect(soundeffects.Claim);
  }

  const playLurkinggrowl = () => {
    playSoundEffect(soundeffects.Lurkinggrowl);
  }

  const playLonghowl = () => {
    playSoundEffect(soundeffects.Longhowl);
  };

  const playTrailinggrowl = () => {
    playSoundEffect(soundeffects.Trailinggrowl);
  }

  const playWelcome = () => {
    playSoundEffect(soundeffects.Welcome);
  };

  const playAlpenhorn = () => {
    playSoundEffect(soundeffects.Alpenhorn);
  };

  const playLogin = () => {
    playSoundEffect(soundeffects.Login);
  };

  const playLogoff = () => {
    playSoundEffect(soundeffects.Logoff);
  };
  
  const playPop = () => {
    playSoundEffect(soundeffects.Pop);
  };

  const playWhoosh = () => {
    playSoundEffect(soundeffects.Whoosh);
  }
  
  const playBrick = () => {
    playSoundEffect(soundeffects.Brick);
  }
  
  const playButton = () => {
    playSoundEffect(soundeffects.Button);
  }
  
  const playConcrete = () => {
    playSoundEffect(soundeffects.Concrete);
  }
  
  const playActivate = () => {
    playSoundEffect(soundeffects.Activate);
  }

  const stopSoundEffect = () => {
    setIsEffectPlaying(false);
  };
  
  const playAudio = () => {
    setAudioPlaying(true);
  };

  const stopAudio = () => {
    setAudioPlaying(false);
  };

  const increaseVolume = () => {
    const newVolumeLevel = volumeLevel + 0.1;
    if (newVolumeLevel <= 1) {
      setVolumeLevel(newVolumeLevel);
    }
  };

  const decreaseVolume = () => {
    const newVolumeLevel = volumeLevel - 0.1;
    if (newVolumeLevel >= 0) {
      setVolumeLevel(newVolumeLevel);
    }
  }; 
  
  const cycleVolume = () => {
    if (volumeLevel < 0.1) {
      setVolumeLevel(0.3);
      playAudio();
    } else if (volumeLevel >= 0.1 && volumeLevel <= 0.3) {
      setVolumeLevel(0.6);
    } else if (volumeLevel > 0.3 && volumeLevel <= 0.6) {
      setVolumeLevel(1);
    } else {
      setVolumeLevel(0);
      stopAudio();
    }
  }; 

  const handleMouseEnter = () => {
    setIsHovered(true);
    playPop();
  };

  useEffect(() => {
     if (isVolIcon) return;
      isVolIcon = true;
    if (volumeLevel < 0.1) {
      setVolIcon('vol-mute');
    } else if (volumeLevel >= 0.1 && volumeLevel <= 0.3) {
      setVolIcon('vol-low');
    } else if (volumeLevel > 0.3 && volumeLevel <= 0.6) {
      setVolIcon('vol-mid');
    } else {
      setVolIcon('vol-max');
    }
      isVolIcon = false;
  }, [volumeLevel]);

  useEffect(() => {  
    if (arsenal || activate || scoreboard) { 
      if (isAAS) return;
        isAAS = true;
        if (!allPlayers) {
          allDataCall();
        }    
        getActivePlayers();
        isAAS = false;
      }
  }, [activate, scoreboard, arsenal]);

useEffect(() => {
  if (loading) {
    if (isProcessed) return;
      isProcessed = true;
    setErrorMessageVisible(false);
      isProcessed = false;
  }
},[loading])
  
  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]); 

  
//Events & Notifications **************************************************************************************************
const [allLogs, setAllLogs] = useState([]);
const [mintLogs, setMintLogs] = useState([]);
const [levelLogs, setLevelLogs] = useState([]);  
const [payoutsLog, setPayoutsLog] = useState([]); 
const [assaultLogs, setAssaultLogs] = useState([]);   
const [debilitateLogs, setDebilitateLogs] = useState([]); 
const [assaultWins, setAssaultWins] = useState([]);
const [debilitateWins, setDebilitateWins] = useState([]);
const [hideNotifications, setHideNotifications] = useState(true);
const [claims, setClaims] = useState("");
const [remainingTime, setRemainingTime] = useState(null);

  // Fetch ownedBattledogs based on playerId
  const getPlayerName = (playerId) => {
    const matchedDog = ownedBattledogs.find(dog => dog.id.toString() === playerId);
    return matchedDog ? matchedDog.name : playerId; // Default to playerId if no match found
  };


const unclaimed = async () => { 
  if (!ownedBattledogs) return;
    // Step 1: Get the timelock variable value
    if (!historybonus) {
      const tm = await readContract({ 
      contract: contractPop, 
      method: "function timeLock() view returns (uint256)", 
      params: [] 
    });
    timeLock = Number(tm?.toString());

    const mlt = await readContract({ 
      contract: contractPop, 
      method: "function multiplier() view returns (uint256)", 
      params: [] 
    });

    multiplier = Number(mlt?.toString());

    const ab = await readContract({ 
      contract: contractPop, 
      method: "function activatebonus() view returns (uint256)", 
      params: [] 
    });
    activatebonus = Number(ab?.toString());

    const lb = await readContract({ 
      contract: contractPop, 
      method: "function levelbonus() view returns (uint256)", 
      params: [] 
    });
    levelbonus = Number(lb?.toString());

    const wb = await readContract({ 
      contract: contractPop, 
      method: "function winsbonus() view returns (uint256)", 
      params: [] 
    });
    
    winsbonus = Number(wb?.toString());

    const fb = await readContract({ 
      contract: contractPop, 
      method: "function fightsbonus() view returns (uint256)", 
      params: [] 
    });
    fightsbonus = Number(fb?.toString());
     
    const hb = await readContract({ 
      contract: contractPop, 
      method: "function historybonus() view returns (uint256)", 
      params: [] 
    });
    
    historybonus = Number(hb?.toString());
  }
    
    // Step 2: Loop through each ID and calculate the unclaimed amount
    
    let unclaimedAmount = 0;
    let timeclock = 0;
    const activebattledogs = ownedBattledogs.map(x => x.id);
    for (const activeBattledogId of activebattledogs) {
      // Find the corresponding ownedBattledog using the activeBattledogId
      const player = ownedBattledogs.find(battledog => battledog.id === activeBattledogId);
    
      if (!player) {
        continue; // Skip if not found, handle this case as needed
      }
    
      const activate = Number(player.activate.toString());
      const level = Number(player.level.toString());
      const wins = Number(player.wins.toString());
      const fights = Number(player.fights.toString());
      const history = Number(player.history.toString());

      let pid = Number(player.id.toString())

      const playerData = await readContract({ 
        contract: contractPop, 
        method: "function Collectors(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
        params: [pid] 
      })
      
      const minerClaims = await readContract({ 
        contract: contractPop, 
        method: "function MinerClaims(uint256) view returns (uint256)", 
        params: [pid] 
      })
      
      const claimDate = Number(minerClaims?.toString());

      
      if (Number(minerClaims) > 0 ) {
        timeclock = (claimDate + Number(timeLock)) * 1000;
      }
      const currentTime = Date.now();

      const activatefactor = activatebonus * (activate - 1);
          
        if (claimDate === 0) {
          // If the user has never claimed before
          const claimable = ((Number(player.activate.toString()) - 1) * multiplier) + 
            (((level - Number(playerData[2].toString())) * levelbonus) + activatefactor) + 
            (((wins - Number(playerData[6].toString())) * winsbonus) + activatefactor) + 
            (((fights - Number(playerData[5].toString())) * fightsbonus) + activatefactor) + 
            (((history - Number(playerData[9].toString())) * historybonus) + activatefactor);
          unclaimedAmount += claimable;
          setRemainingTime(0);
          
        } else if ( currentTime >= timeclock ) {
          // If the user has claimed before and the timeLock has expired
          const claimable = ((Number(player.activate.toString()) - 1) * multiplier) + 
          (((level - Number(playerData[2].toString())) * levelbonus) + activatefactor) + 
          (((wins - Number(playerData[6].toString())) * winsbonus) + activatefactor) + 
          (((fights - Number(playerData[5].toString())) * fightsbonus) + activatefactor) + 
          (((history - Number(playerData[9].toString())) * historybonus) + activatefactor);
          unclaimedAmount += claimable;
          setRemainingTime(timeclock);
        } else {
          // If the user has claimed before but the timeLock has not expired
          setRemainingTime(timeclock);
          const claimable = ((Number(player.activate.toString()) - 1) * multiplier) + 
          (((level - Number(playerData[2].toString())) * levelbonus) + activatefactor) + 
          (((wins - Number(playerData[6].toString())) * winsbonus) + activatefactor) + 
          (((fights - Number(playerData[5].toString())) * fightsbonus) + activatefactor) + 
          (((history - Number(playerData[9].toString())) * historybonus) + activatefactor);
          unclaimedAmount += claimable;
      }
    }    
    // Step 3: Set the state of unclaimedEntry to the total unclaimed amount
    setClaims(unclaimedAmount.toString());
};

const handleHideNotifications = () => {
  setHideNotifications(!hideNotifications);
  setListener(!listener);
};

const buttonStyle = {
backgroundColor: hideNotifications ? "gray" : `${blockchain.theme}`
};

// Listeners ************************************************************************************************
const allListeners = () => {  
  mintListener();
  setTimeout(() => {  
    assaultListener();    
  }, 2400); 
  setTimeout(() => {  
    debilitateListener();    
  }, 3400); 
  setTimeout(() => {
      assaultWinsListener();
  }, 4400); 
  setTimeout(() => {
      debilitateWinsListener();
  }, 4400); 
  setTimeout(() => {
      levelUpListener();
  }, 4400); 
  setTimeout(() => {
      payoutListener();  
  }, 4400); 
  setTimeout(() => {
    readMiningData();
  }, 7400); 
  setTimeout(() => {
      readHarvesterData();
  }, 7400); 
   // setTimeout(() => {
      // readLPHarvesterData();
   // }, 7400); 
}

const mintListener = () => {
  let mintlisten;
  contractBattledog.on("TokenMinted", (name, tokenId, event) => {
    let mintdata = {
      type: 'mint',
      name: name.toString(), tokenId: tokenId.toString(), 
      timestamp: Date.now(), 
      event
    };
    if (mintlisten) return;
    mintlisten = true;
    setAllLogs((oldLogs) => {
      // Check if data already exists in the log
      const isDuplicate = oldLogs.some((log) => log.type === 'mint' && log.name === mintdata.name 
      && log.tokenId === mintdata.tokenId);

      if (isDuplicate) {
        return oldLogs;
      }
      
      return [...oldLogs, mintdata];
    });    
    mintlisten = false;
  });
}

const assaultListener = () => {
  let assaultlisten;
  contractBattledog.on("AssaultEvent", (attackerId, defenderId, stolenPoints, timestamp, event) => {
    let assaultdata = {
      type: 'assault',
      attackerId: attackerId.toString(), defenderId: defenderId.toString(), 
      stolenPoints: stolenPoints.toString(), timestamp: timestamp.toString(),  
      event
    };
    if (assaultlisten) return;
    assaultlisten = true;
    setAllLogs((oldLogs) => {
      // Check if data already exists in the log
      const isDuplicate = oldLogs.some((log) => log.type === 'assault' && log.attackerId === assaultdata.attackerId 
      && log.defenderId === assaultdata.defenderId && log.stolenPoints === assaultdata.stolenPoints);

      if (isDuplicate) {
        return oldLogs;
      }
      
      return [...oldLogs, assaultdata];
    });    
    assaultlisten = false;
  });
}

const debilitateListener = () => {
  let debilitatelisten;
    contractBattledog.on("DebilitateEvent", (attackerId, defenderId, stolenPoints, timestamp, event) => {
      let debilitatedata = {
        type: 'debilitate',
        attackerId: attackerId.toString(), defenderId: defenderId.toString(), 
        stolenPoints: stolenPoints.toString(), timestamp: timestamp.toString(),   
        timestamp: Date.now(), 
        event
      };
      if (debilitatelisten) return;
      debilitatelisten = true;
      setAllLogs((oldLogs) => {
        // Check if data already exists in the log
        const isDuplicate = oldLogs.some((log) => log.type === 'debilitate' && log.attackerId === debilitatedata.attackerId 
        && log.defenderId === debilitatedata.defenderId && log.stolenPoints === debilitatedata.stolenPoints);
  
        if (isDuplicate) {
          return oldLogs;
        }
        
        return [...oldLogs, debilitatedata];
      });    
      debilitatelisten = false;
    });
}

const assaultWinsListener = () => {
  let assaultwinslisten;
  contractBattledog.on("AssaultPayoutClaimed", (playerId, payreward, event) => {
    let assaultpayoutdata = {
      type: 'assaultwin',
      playerId: playerId.toString(), payreward: payreward.toString(),   
      timestamp: Date.now(), 
      event
    };
    if (assaultwinslisten) return;
    assaultwinslisten = true;
    setAllLogs((oldLogs) => {
      // Check if data already exists in the log
      const isDuplicate = oldLogs.some((log) => log.type === 'assaultwin' && log.playerId === assaultpayoutdata.playerId 
      && log.payreward === assaultpayoutdata.payreward);

      if (isDuplicate) {
        return oldLogs;
      }
      
      return [...oldLogs, assaultpayoutdata];
    });    
    assaultwinslisten = false;
  });
}

const debilitateWinsListener = () => {
  let debilitatewinslisten;
  contractBattledog.on("DebilitatePayoutClaimed", (playerId, payreward, event) => {
    let debilitatepayoutdata = {
      type: 'debilitatewin',
      playerId: playerId.toString(), payreward: payreward.toString(),   
      timestamp: Date.now(), 
      event
    };
    if (debilitatewinslisten) return;
    debilitatewinslisten = true;
    setAllLogs((oldLogs) => {
      // Check if data already exists in the log
      const isDuplicate = oldLogs.some((log) => log.type === 'debilitatewin' && log.playerId === debilitatepayoutdata.playerId 
      && log.payreward === debilitatepayoutdata.payreward);

      if (isDuplicate) {
        return oldLogs;
      }
      
      return [...oldLogs, debilitatepayoutdata];
    });    
    debilitatewinslisten = false;
  });
}

const levelUpListener = () => {
  let leveluplisten;
  contractBattledog.on("LevelUpEvent", (playerId, level, event) => {
    let leveldata = {
      type: 'levelup',
      playerId: playerId.toString(), level: level.toString(),   
      timestamp: Date.now(), 
      event
    };
    if (leveluplisten) return;
    leveluplisten = true;
    setAllLogs((oldLogs) => {
      // Check if data already exists in the log
      const isDuplicate = oldLogs.some((log) => log.type === 'levelup' && log.playerId === leveldata.playerId 
      && log.level === leveldata.level);

      if (isDuplicate) {
        return oldLogs;
      }
      
      return [...oldLogs, leveldata];
    });    
    leveluplisten = false;
  });
}

const payoutListener = () => {
  let payoutlisten;
  contractBattledog.on("PayoutsClaimed", (playerId, amount, event) => {
    let payoutsdata = {
      type: 'payout',
      playerId: playerId.toString(), amount: amount.toString(), 
      timestamp: Date.now(), 
      event
    };
    if (payoutlisten) return;
    payoutlisten = true;
    setAllLogs((oldLogs) => [...oldLogs, ]);
    setAllLogs((oldLogs) => {
      // Check if data already exists in the log
      const isDuplicate = oldLogs.some((log) => log.type === 'payout' && log.playerId === payoutsdata.playerId 
      && log.amount === payoutsdata.amount);

      if (isDuplicate) {
        return oldLogs;
      }
      
      return [...oldLogs, payoutsdata];
    });    
    payoutlisten = false;
  });
}
const readMiningData = async () => {  
  let mininglisten;
  const contractME = new ethers.Contract(blockchain.popaddress, contractData.pop.abi, provider);
  contractME.on("RewardClaimedByMiner", (sender, rewards, event) => {
    let data0 = {
      sender: sender.toString(),
      rewards: parseFloat(rewards.toString()), 
      event,
    };
    if (mininglisten) return;
    mininglisten = true;
    let rec = sender.toString().toLowerCase();
    if (rec === account.address.toLowerCase()) {
      dataArray.push(data0);
    }
    
    if (dataArray.length > 1) {
      const totalClaimedToday = dataArray.reduce((total, entry) => total + entry.rewards, 0);
      setMinerLogs({
        sender: sender.toString(),
        rewards: totalClaimedToday.toString(), 
        event});
    } else {
      setMinerLogs(data0);
    }

    mininglisten = false;
  });  
}
  
const readHarvesterData = async () => {
  let harvesterlisten;
  const contractHE = new ethers.Contract(blockchain.harvesteraddress, contractData.harvester.abi, provider);
  contractHE.on("RewardClaimedByUser", (sender, rewards, event) => {
    let data1 = {
      sender: sender.toString(),
      rewards: rewards.toString(), 
      event,
    };
    if (harvesterlisten) return;
    harvesterlisten = true;
    let rec = sender.toString().toLowerCase();
    if (rec === account.address.toLowerCase()) {
      setHarvesterLogs(data1);
    }
    harvesterlisten = false;
  }); 
}
  
const readLPHarvesterData = async () => {
  if (!blockchain.lpharvesteraddress) return;
  let lpharvesterlisten;
  const contractPE = new ethers.Contract(blockchain.lpharvesteraddress, contractData.harvester.abi, provider);
  contractPE.on("RewardClaimedByUser", (sender, rewards, event) => {
    let data2 = {
      sender: sender.toString(),
      rewards: rewards.toString(), 
      event,
    };
    if (lpharvesterlisten) return;
    lpharvesterlisten = true;
    let rec = sender.toString().toLowerCase();
    if (rec === account.address.toLowerCase()) {
      setHarvesterLogs(data2);
    }
    lpharvesterlisten = false;
  }); 
}


// event AfterCalls

async function approveNativeAfterCall () {  
  if (isApproveNative) return;
  isApproveNative = true;
  await allDataCall();
  await sendHash(transactionResult.transactionHash);
  setErrorMessage(`Approved ${blockchain.tokenA}!`);
  setErrorMessageVisible(true);
  isApproveNative = false;
  setIsWaiting(false);
}

async function activateAfterCall () {
  if (isActivate) return;
    isActivate = true;
    await allOwnerOfPlayersTx();
    await sendHash(transactionResult.transactionHash);
    getActivePlayers();
    await allDataCall();
    await pushActivePlayer(attackerId);
    await handleState(attackerId);
    if (flagHouse !== null) {
      await pickaKlan(flagHouse);
    } 
    setErrorMessage("Activated!");
    setErrorMessageVisible(true);
    playActivate();
    setLoading(false);

    isActivate = false;
  }
  
  async function claimAssaultAfterCall () {
    if (isClaimAssault) return;
        isClaimAssault = true;
        await allDataCall();
        await sendHash(transactionResult.transactionHash);
      setErrorMessage("Claim Success!");
      setErrorMessageVisible(true);
      await handleState(attackerId);
      isClaimAssault = true;

      setLoading(false);
    }

  async function assaultAfterCall () {
      if (isAssault) return; 
        isAssault = true;  
        await allDataCall();
        await handleState(attackerId);
        await sendHash(transactionResult.transactionHash);
        await handleState(defenderId);
        setErrorMessage("Success!");
        setErrorMessageVisible(true);
        setLoading(false);  
        isAssault = false;  
      }

async function approveGameAfterCall () {
  if (isProcessing) return;
  setLoading(true);
  isProcessing = true;
  await sendHash(transactionResult.transactionHash);
  setTimeout(() => {
    setLoading(false);
    addGAME(approval);
    isProcessing = false;
  }, 7400); 
  
  setIsWaiting(false); 
}  

async function debilitateAfterCall () {
  if (isDebilitate) return;
      isDebilitate = true;
      await allDataCall();
    await handleState(attackerId);
    await sendHash(transactionResult.transactionHash);
    await handleState(defenderId);
    playLonghowl();
    setErrorMessage("Success!");
    setErrorMessageVisible(true);
    setLoading(false);

      isDebilitate = false;

}

async function claimDebilitateAfterCall () {
  if (isClaimDebilitate) return;
      isClaimDebilitate = true;
      await allDataCall();
      await sendHash(transactionResult.transactionHash);
    setErrorMessage("Claim success!");
    setErrorMessageVisible(true);  
    await handleState(attackerId);
    setLoading(false);  

      isClaimDebilitate = false;  
}

async function levelUpAfterCall () {
  if (isLevelUp) return;
      isLevelUp = true;
      await allDataCall();
      await sendHash(transactionResult.transactionHash);
    setErrorMessage("Leveled Up!");
    setErrorMessageVisible(true);  
    await handleState(attackerId);
    setLoading(false);
      isLevelUp = false;  
  }

async function mintAfterCall() {
  if (isMint) return
    isMint = true;
  console.log('Mint Succeeded');
  setErrorMessage("Fresh Mint!");
  await sendHash(transactionResult.transactionHash);
  await allDataCall();
  setErrorMessageVisible(true);
  playWelcome();
  setLoading(false);
    isMint = false;
} 

async function payoutAfterCall() {  
  if (isPayout) return;
  isPayout = true;
  await allDataCall();
  playClaim();
  await sendHash(transactionResult.transactionHash);
  setErrorMessage("Paid!");
  setErrorMessageVisible(true);
  setLoading(false); 

  isPayout = false;   

}  

async function regenerateAfterCall () {
  if (isRegenerate) return;
      isRegenerate = true;
      await allDataCall();
      await sendHash(transactionResult.transactionHash);
      setErrorMessage("Regenerated!");
      setErrorMessageVisible(true);
      await handleState(attackerId);
      setLoading(false);

      isRegenerate = false;
}

async function customizeAfterCall () {  
  if (isCustomize) return;
  isCustomize = true;
  playWelcome();
  await sendHash(transactionResult.transactionHash);
  setErrorMessage("Updated!");
  setErrorMessageVisible(true); 
setLoading(false); 
  isCustomize = false;           
}

async function weaponizeAfterCall () {
  if (isWeaponize) return;
      isWeaponize = true;
      await allDataCall();
      await sendHash(transactionResult.transactionHash);
    setErrorMessage("Weaponized!");
    setErrorMessageVisible(true);
    await handleState(attackerId);  
    setLoading(false); 
      isWeaponize = false;

}        

async function miningAfterCall () {
  if (isProcess) return;
  isProcess = true;
  await allDataCall();
  playClaim();
  statusLock();
  setUnlocker(false);
  setUnlocker2(false);
  await sendHash(transactionResult.transactionHash);
  console.log("GAME Mined");
  setSubmitText("Claim Succeeded"); 
  setIsClaiming(false);  
  isProcess = false; 
}

async function farmAfterCall () {
  if (isProcess) return;
  isProcess = true;
  await allDataCall();
  await sendHash(transactionResult.transactionHash);
  fetchFarmData();  
  playStake();
  console.log('Farming Succeeded');
  setSubmitText("Farming Succeeded"); 
  setIsFarming(false);  
  isProcess = false; 

}

async function wrapAfterCall () {
  if (isWrap) return;
  isWrap = true;
  await sendHash(transactionResult.transactionHash);
  await allDataCall();
  setErrorMessage(`Wrap success!`);
  isWrap = false; 
}

useEffect(() => {
  if (transactionResult) {
    refreshAllowanceData();
  }
},[transactionResult])

  return (
    <>
      <ReactPlayer className="soundwidth"
          url={soundtrack}
          playing={audioPlaying}
          volume={volumeLevel}
          loop={true}
          controls={false}
        />     

      <ReactPlayer
        url={soundEffectUrl}
        playing={isEffectPlaying}
        volume={0.8}
        onEnded={stopSoundEffect} 
        style={{ display: 'none' }}
        controls={false}
      />
      
      <>       
        <Desktop>
          <div className={`background mainBackground${backgroundImage}`}>
            <div className="topLogin">
              <Connector />
            </div>
              <div className="topLogo">
                <img src={require(`./assets/images/logo.png`)} style={{height: "10vh"}}/>
              </div>

            {!account ? (
              <LoginMenuComponent />
            ) : ( 
              <>
                {isPauseState ? (
                  <div style={{width: "100vw", height: "100vh"}}>
                    <p className="translation mkLarge" style={{width: "100vw", height: "auto", fontFamily: "EvilEmpire", 
                      backgroundColor: "black", color: `${blockchain.theme}`, opacity: "0.8"}} align="center">
                      Gaming on {blockchain.ecosystem} is currently paused.
                    </p>
                  </div>
                  ) : (
                  <>
                  <LayoutComponent />
                  <div className="swdesktop">
                    {(activate || arsenal || mint || assault || debilitate || claimAssault || claimDebilitate || levelUp || scoreboard 
                      || weaponize || regenerate || customize || resetTimer) && (
                      <> 
                        {(flagHouse === null || allActiveHouses.length === 0) ? 
                        <img src={require('./assets/images/icons/building.png')} onClick={handleHouseboard} style={{width: "4vw", cursor: "pointer"}} title="Join Battling Klans"/>
                        : !blockchain.klanmode ?
                        <div align="center">
                          <img src={require(`./assets/images/icons/${allActiveHouses[flagHouse].NAME}.gif`)} onClick={handleHouseboard} style={{width: "4vw", cursor: "pointer"}} title="See The Leading Klans"/>
                        </div>
                        : 
                        <div align="center">
                          <img src={allActiveHouses[flagHouse].ICON} onClick={handleHouseboard} style={{height: "7.1vw", width: "4vw", cursor: "pointer", border: `2px double ${allActiveHouses[flagHouse].COLOR}`, borderRadius: "10vw", objectFit: "cover", objectPosition: "center"}} title="See The Leading Klans"/>
                        </div>
                        }
                        <ScoreComponent /> 
                        {blockchain.referral && (
                          <div className='soundicon theight tutorial' onClick={handleReferral} style={{backgroundColor: `${blockchain.theme}`}}> Your Ref Link: <span style={{color: `white`}}>{textRef}</span></div>            
                        )}
                        <div className='soundicon theight tutorial' onClick={handleTutorial} style={{backgroundColor: `white`}}> Tutorial Mode: <span style={{color: `${blockchain.theme}`}}>{textTutor}</span> </div>
                        <img src={require("./assets/images/icons/vol+.png")} className='soundicon sheight' onClick={increaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                        <img src={require(`./assets/images/icons/${volIcon}.png`)} className='soundicon theight' onClick={cycleVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                        <img src={require("./assets/images/icons/vol-.png")} className='soundicon sheight' onClick={decreaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                      </>
                    )}
                  </div> 
                  {account && startMenu && (
                    <>
                    <StartMenuComponent />
                    </>
                  )}       
                  {account && activityTracker && (
                    <>
                      <ActivityTrackerComponent />
                    </>
                  )}           
                  {account && activate && (
                    <>
                      <ActivateComponent />  
                      <ApproveNative />   
                    </>
                  )}           
                  {account && activateHelp && (
                    <>
                      <ActivateHelpComponent />  
                    </>
                  )}        
                  {account && arena && (
                    <>
                    <ArenaHelpComponent />
                    </>
                  )}     
                  {account && arsenal && (
                    <>
                      <ArsenalComponent />  
                      <ApproveNative />     
                    </>
                  )}         
                  {account && assault && (
                    <>
                      <AssaultComponent />  
                      <ApproveNative />
                    </>
                  )}          
                  {account && assaultHelp && (
                    <>
                      <AssaultHelpComponent />       
                    </>
                  )}        
                  {account && claimAssault && (
                    <>
                      <ClaimAssaultComponent /> 
                    </>
                  )}         
                  {account && claimAssaultHelp && (
                    <>
                      <ClaimAssaultHelpComponent />  
                    </>
                  )}         
                  {account && claimDebilitate && (
                    <>
                      <ClaimDebilitateComponent />
                    </>
                  )}            
                  {account && claimDebilitateHelp && (
                    <>
                      <ClaimDebilitateHelpComponent />    
                    </>
                  )}         
                  {account && debilitate && (
                    <>
                      <DebilitateComponent />  
                      <ApproveNative />   
                    </>
                  )}         
                  {account && debilitateHelp && (
                    <>
                      <DebilitateHelpComponent /> 
                    </>
                  )}           
                  {account && harvester && (
                    <>
                      <HarvesterComponent /> 
                    </>
                  )}         
                  {account && scoreboard && (
                    <>
                      <ScoreboardComponent /> 
                    </>
                  )}              
                  {account && leaderboard && (
                    <>
                      <LeaderboardComponent /> 
                    </>
                  )}                  
                  {account && houseboard && (
                    <>
                      <HouseboardComponent /> 
                    </>
                  )}    
                  {account && levelUp && (
                    <>
                      <LevelUpComponent />  
                      <ApproveGame />   
                    </>
                  )}         
                  {account && levelUpHelp && (
                    <>
                      <LevelUpHelpComponent /> 
                    </>
                  )}
                  {account && mining && (
                    <>
                    <MiningComponent />
                    </>
                  )}
                  {account && mint && (
                    <>
                    <MintComponent />
                    <ApproveNative />
                    </>
                  )}          
                  {account && payout && (
                    <>
                    <PayoutComponent />
                    </>
                  )}          
                  {account && payoutHelp && (
                    <>
                    <PayoutHelpComponent />
                    </>
                  )}
                  {account && regenerate && (
                    <>
                    <RegenerateComponent />
                    </>
                  )}
                  {account && regenerateHelp && (
                    <>
                    <RegenerateHelpComponent />
                    </>
                  )}
                  {account && resetTimer && (
                    <>
                    <ResetTimerComponent />
                    </>
                  )}
                  {account && resetTimerHelp && (
                    <>
                    <ResetTimerHelpComponent />
                    </>
                  )}
                  {account && start && (
                    <div>
                    <StartHelpComponent />
                    </div>
                  )}
                  {account && tools && (
                    <div>
                    <ToolsHelpComponent />
                    </div>
                  )}
                  {account && customize && (
                    <>
                    <CustomizeComponent />
                    </>
                  )}
                  {account && customizeHelp && (
                    <>
                    <CustomizeHelpComponent />
                    </>
                  )}
                  {account && winnings && (
                    <>
                    <WinningsHelpComponent />
                    </>
                  )}
                  {account && weaponize && (
                    <>
                    <WeaponizeComponent />
                    </>
                  )}
                  {account && weaponizeHelp && (
                    <>
                    <WeaponizeHelpComponent />
                    </>
                  )}
                  {(activate || arsenal || mint || assault || debilitate || claimAssault || claimDebilitate || levelUp || scoreboard 
                  || weaponize || regenerate || customize || resetTimer) && (
                    <>
                      <LockComponent />
                    </>
                  )}
                  </>
                )}
              </>
            )}   
            </div>
        </Desktop>
        <Mobile>
          <div className={`backgroundMobile mainBackground${backgroundImage}`}>
            <div className="topLogoMobile" align="center">            
              <img src={require(`./assets/images/logo.png`)} style={{height: "10vh"}}/>
            </div>
            <div className="topLoginMobile" align="center">
              <Connector />
            </div>
            {!account ? (
              <LoginMenuComponent />
            ):(
              <>
                {isPauseState ? (
                  <div style={{width: "100vw", height: "100vh"}}>
                  <p className="translation mkMid" style={{width: "100vw", height: "auto", fontFamily: "EvilEmpire", 
                    backgroundColor: "black", color: `${blockchain.theme}`, opacity: "0.8"}} align="center">
                      Gaming on {blockchain.ecosystem} is currently paused.
                    </p>
                  </div>
                  ) : (
                  <>
                    <LayoutComponent />
                    {account && startMenu && (
                      <>
                      <StartMenuComponent />
                      </>
                    )}    
                    {account && activityTracker && (
                      <>
                        <ActivityTrackerComponent />  
                      </>
                    )}          
                    {account && activate && (
                      <>
                        <ActivateComponent />  
                        <ApproveNative /> 
                      </>
                    )}            
                    {account && activateHelp && (
                      <>
                        <ActivateHelpComponent />  
                      </>
                    )}         
                    {account && arena && (
                      <>
                      <ArenaHelpComponent />
                      </>
                    )} 
                    {account && arsenal && (
                      <>
                        <ArsenalComponent />  
                        <ApproveNative /> 
                      </>
                    )}         
                    {account && assault && (
                      <>
                        <AssaultComponent />  
                        <ApproveNative />    
                      </>
                    )}           
                    {account && assaultHelp && (
                      <>
                        <AssaultHelpComponent />       
                      </>
                    )}         
                    {account && claimAssault && (
                      <>
                        <ClaimAssaultComponent />  
                      </>
                    )}         
                    {account && claimAssaultHelp && (
                      <>
                        <ClaimAssaultHelpComponent />  
                      </>
                    )}            
                    {account && claimDebilitate && (
                      <>
                        <ClaimDebilitateComponent />  
                      </>
                    )}            
                    {account && claimDebilitateHelp && (
                      <>
                        <ClaimDebilitateHelpComponent />    
                      </>
                    )}         
                    {account && debilitate && (
                      <>
                        <DebilitateComponent />  
                        <ApproveNative />   
                      </>
                    )}         
                    {account && debilitateHelp && (
                      <>
                        <DebilitateHelpComponent /> 
                      </>
                    )}         
                    {account && harvester && (
                      <>
                        <HarvesterComponent /> 
                      </>
                    )}         
                    {account && scoreboard && (
                      <>
                        <ScoreboardComponent /> 
                      </>
                    )}               
                    {account && leaderboard && (
                      <>
                        <LeaderboardComponent /> 
                      </>
                    )}                
                    {account && houseboard && (
                      <>
                        <HouseboardComponent /> 
                      </>
                    )}         
                    {account && levelUp && (
                      <>
                        <LevelUpComponent />  
                        <ApproveGame />     
                      </>
                    )} 
                    {account && levelUpHelp && (
                      <>
                        <LevelUpHelpComponent /> 
                      </>
                    )}
                    {account && mining && (
                      <>
                      <MiningComponent />
                      </>
                    )}
                    {account && mint && (
                      <>
                      <MintComponent />
                      <ApproveNative />
                      </>
                    )}          
                    {account && payout && (
                      <>
                      <PayoutComponent />
                      </>
                    )}          
                    {account && payoutHelp && (
                      <>
                      <PayoutHelpComponent />
                      </>
                    )}
                    {account && regenerate && (
                      <>
                      <RegenerateComponent />
                      </>
                    )}
                    {account && regenerateHelp && (
                      <>
                      <RegenerateHelpComponent />
                      </>
                    )}
                    {account && resetTimer && (
                      <>
                      <ResetTimerComponent />
                      </>
                    )}
                    {account && resetTimerHelp && (
                      <>
                      <ResetTimerHelpComponent />
                      </>
                    )}
                    {account && start && (
                      <div>
                      <StartHelpComponent />
                      </div>
                    )}
                    {account && tools && (
                      <div>
                      <ToolsHelpComponent />
                      </div>
                    )}
                    {account && customize && (
                      <>
                      <CustomizeComponent />
                      </>
                    )}
                    {account && customizeHelp && (
                      <>
                      <CustomizeHelpComponent />
                      </>
                    )}
                    {account && winnings && (
                      <>
                      <WinningsHelpComponent />
                      </>
                    )}
                    {account && weaponize && (
                      <>
                      <WeaponizeComponent />
                      </>
                    )}
                    {account && weaponizeHelp && (
                      <>
                      <WeaponizeHelpComponent />
                      </>
                    )}                
                    <div>
                    {(activate || arsenal || mint || assault || debilitate || claimAssault || claimDebilitate || levelUp || scoreboard 
                    || weaponize || regenerate || customize || resetTimer) && (
                      <div className="mobile-approval-limit" align="center">
                        <LockComponent />
                        <div className="minileaderboardMB" >
                        <span className="mkNano tgold" style={{margin: "2px"}}  onClick={handleHouseboard}>The Klans </span>  
                        {(flagHouse === null || allActiveHouses.length === 0) ?
                        <img src={require('./assets/images/icons/building.png')} onClick={handleHouseboard} style={{width: "10vw", cursor: "pointer"}}/>
                        : !blockchain.klanmode ?
                        <div align="center">
                          <img src={require(`./assets/images/icons/${allActiveHouses[flagHouse].NAME}.gif`)} onClick={handleHouseboard} style={{width: "10vw", cursor: "pointer"}} />
                        </div>
                        :
                        <div align="center">
                          <img src={allActiveHouses[flagHouse].ICON} onClick={handleHouseboard} style={{height: "17.7vw", width: "10vw", cursor: "pointer", border: `2px double ${allActiveHouses[flagHouse].COLOR}`, borderRadius: "5vw", objectFit: "cover", objectPosition: "center"}} />
                        </div>
                        }
                        
                          <ScoreComponent />     
                        </div>
                      </div>
                    )}
                    </div>
                    <div className="swmobile">                
                      {blockchain.referral && (
                        <div className='soundicon theightmb mkNano' onClick={handleReferral} style={{color: `${blockchain.theme}`}}> Your Ref Link: <span style={{color: `white`, margin: '0vh 0.4vw 0vh 0.4vw', 
                          padding: '2px', border: `1px solid ${blockchain.theme}`, borderRadius: '2px'}}>{textRef}</span> </div>                 
                      )}
                        <img src={require("./assets/images/icons/vol-.png")} className='soundicon sheightmb' onClick={decreaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                        <img src={require(`./assets/images/icons/${volIcon}.png`)} className='soundicon theightmb' onClick={cycleVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                        <img src={require("./assets/images/icons/vol+.png")} className='soundicon sheightmb' onClick={increaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                        <div className='soundicon theightmb mkNano' onClick={handleTutorial} style={{color: "white"}}> Tutorial Mode: <span style={{color: `${blockchain.theme}`, margin: '0vh 0.4vw 0vh 0.4vw', textShadow: '2px 2px 8px white'}}>{textTutor}</span></div>
                    </div>
                  </>
                )}                        
              </>
            )}   
          </div>
        </Mobile>
    </>
  </>
  );
}

export default App;