import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThirdwebProvider } from "thirdweb/react";
import {BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import { networkDataArray } from './tools/networkData';

const ecosystemArray = networkDataArray.map(network => network.ecosystem.toLowerCase());

function Navigator () {
  const pathname = window.location.pathname.toLowerCase();
  console.log("pathname: ", pathname);
  for (let i = 0; i < ecosystemArray.length; i++) {
    if (pathname.includes(ecosystemArray[i])) {
      console.log("Found.");
      return (`/${ecosystemArray[i]}`);
    }
  }
  return "/"; // Default route if no ecosystem is matched
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThirdwebProvider>
        <Router>
          <Routes>
            <Route path="/" element={<App />} />
            {ecosystemArray.map((ecosystem) => (
              <Route 
                key={ecosystem} 
                path={`/${ecosystem}`} 
                element={<App />} 
              />
            ))}
            <Route path="*" element={<App />} /> {/* Catch-all route */}
          </Routes>
        </Router>
      </ThirdwebProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
