import {
  FETCH_NETWORK_REQUEST,
  FETCH_NETWORK_SUCCESS,
  FETCH_NETWORK_FAILURE,
  UPDATE_NETWORK_INDEX
} from '../actions/networkActions';
import { networkDataArray } from '../../tools/networkData';
import { base, sepolia, polygon, arbitrumNova, bsc, defineChain } from "thirdweb/chains";


const initialState = {
  loading: false,
  networkIndex: 0,
  blockchain: networkDataArray[0], // Initial blockchain data
  error: null
};

const networkReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NETWORK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_NETWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        blockchain: action.payload,
        error: null
      };

    case FETCH_NETWORK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_NETWORK_INDEX:
      return {
        ...state,
        networkIndex: action.payload
      };

    default:
      return state;
  }
};

// Selectors
export const selectNetworkState = (state) => state.network;
export const selectBlockchain = (state) => state.network.blockchain;
export const selectNetworkIndex = (state) => state.network.networkIndex;
export const selectNetworkLoading = (state) => state.network.loading;
export const selectNetworkError = (state) => state.network.error;

const assetchain = defineChain({ id: networkDataArray[0].chainId, rpc: networkDataArray[0].rpc,});
const manta = defineChain({id: networkDataArray[1].chainId, rpc: networkDataArray[1].rpc,});
const scroll = defineChain({ id: networkDataArray[2].chainId, rpc: networkDataArray[2].rpc,});
const swanchain = defineChain({ id: networkDataArray[3].chainId,  rpc: networkDataArray[3].rpc,});
const neon = defineChain({ id: networkDataArray[4].chainId, rpc: networkDataArray[4].rpc,});

const mantle = defineChain({ id: networkDataArray[5].chainId,  rpc: networkDataArray[5].rpc,});
const sonic = defineChain({ id: networkDataArray[6].chainId, rpc: networkDataArray[6].rpc,});
const core = defineChain({ id: networkDataArray[8].chainId,  rpc: networkDataArray[8].rpc,});
const okx = defineChain({ id: networkDataArray[11].chainId, rpc: networkDataArray[11].rpc,});
const unichain = defineChain({ id: networkDataArray[12].chainId, rpc: networkDataArray[12].rpc,});

const pulsechain = defineChain({ id: networkDataArray[13].chainId, rpc: networkDataArray[13].rpc,});
const linea = defineChain({ id: networkDataArray[14].chainId, rpc: networkDataArray[14].rpc,});
const soneium = defineChain({ id: networkDataArray[15].chainId, rpc: networkDataArray[15].rpc,});
const blast = defineChain({ id: networkDataArray[16].chainId, rpc: networkDataArray[16].rpc,});
const opbnb = defineChain({ id: networkDataArray[17].chainId, rpc: networkDataArray[17].rpc,});
const applayer = defineChain({ id: networkDataArray[19].chainId, rpc: networkDataArray[19].rpc,});
const ink = defineChain({ id: networkDataArray[20].chainId, rpc: networkDataArray[20].rpc,});
const immutable = defineChain({ id: networkDataArray[21].chainId, rpc: networkDataArray[21].rpc,});
const over = defineChain({ id: networkDataArray[22].chainId, rpc: networkDataArray[22].rpc,});

export const chains = [assetchain, manta, scroll, swanchain, neon, mantle, sonic, base, core, arbitrumNova,
  bsc, okx, unichain, pulsechain, linea, soneium, blast, opbnb, polygon, applayer, ink, immutable, over
];

export const MAX = 23; // Range upper bound (exclusive)

function getRandomCounter() {
  const numbers = Array.from({ length: MAX }, (_, i) => i); // [0, 1, 2, ..., 20]

  // Fisher-Yates shuffle
  for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index
      [numbers[i], numbers[j]] = [numbers[j], numbers[i]]; // Swap
  }

  return numbers[0]; // Return the first shuffled number
}

export const _counter = getRandomCounter();

export default networkReducer;
